import style from './toggle-switch.module.css';

export type ToggleSwitchOption<T extends string = string> = {
  title: string;
  value: T;
  qaLabel?: string;
};
type Props<T extends string> = {
  value: T;
  onChangeValue: (value: T) => void;
  options: ToggleSwitchOption<T>[];
};

export function ToggleSwitch<T extends string>({
  options,
  value,
  onChangeValue,
}: Props<T>) {
  return (
    <div className={style.container}>
      {options.map((item) => (
        <div
          className={style.option_container}
          data-qa-type={item.qaLabel}
          key={item.value}
        >
          <input
            hidden
            type="radio"
            role="option"
            id={item.value}
            aria-selected={value === item.value}
            checked={value === item.value}
            onChange={() => {
              onChangeValue(item.value);
            }}
          />
          <label className={style.option} htmlFor={item.value}>
            {item.title}
          </label>
        </div>
      ))}
    </div>
  );
}
