export type RequestParams = {
  httpMethod?: 'GET' | 'POST';
  url?: string;
  version?: string;
  domain?: string;
  service?: string;
  method?: string;
  host?: string;
  headers?: Record<string, any>;
  payload?: unknown;
  withCredentials?: boolean;
  withLeadingDomain?: boolean;
  timeout?: number;
  type?: string;
  cache?: boolean;
  query?: Record<string, string>;
};

export const getUrlFromParams = (params: RequestParams): string => {
  const host = params.host ?? '';
  const baseSegments = ['api', params.version, params.service, params.method];

  const indexToInsertDomain = params.withLeadingDomain ? 0 : 2;

  baseSegments.splice(indexToInsertDomain, 0, params.domain);

  return baseSegments.reduce(
    (url: string, segment) => (segment ? `${url}/${segment}` : url),
    host
  );
};
