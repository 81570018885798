import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import type { LoanCalculatorFormValues } from '~widgets/loan-calculator';

import {
  type ApplicationDetails,
  isSelfAppMobileOnboarding,
} from '~entities/application';
import type { ProductType } from '~entities/product';

export type ClientInitiationNavigationState = {
  loanCalculator?: LoanCalculatorFormValues;
  productType: ProductType;
  forcedOfferAppliedId?: string;
  calculatorSessionId: string;
  promoterCode?: string;
  clientInitiation?: boolean;
  clientPhonePrefilled?: string;
  isOfferCancelled?: boolean;
};

export const useHandleApplicationFlow = () => {
  const navigate = useNavigate();

  const handleFlow = useCallback(
    (
      application: ApplicationDetails,
      state: ClientInitiationNavigationState
    ) => {
      if (application.selfAppPreApproved) {
        return navigate(`/self-application-agent/${application.id}`, {
          replace: true,
          state: { ...state, clientInitiation: true },
        });
      }
      if (
        !application.selfApp ||
        application.selfAppFlowType === 'AGENT_PORTAL'
      ) {
        return navigate(`/loan-application/${application.id}`, {
          replace: true,
          state,
        });
      }
      // If a customer has an offer, we switch to AGENT_PORTAL flow
      if (
        state.loanCalculator?.offer?.id &&
        isSelfAppMobileOnboarding(application)
      ) {
        return navigate(`/loan-application/${application.id}`, {
          replace: true,
          state,
        });
      }
      if (isSelfAppMobileOnboarding(application)) {
        return navigate(`/mobile-self-app-redirect/${application.id}`, {
          replace: true,
          state,
        });
      }
    },
    [navigate]
  );
  return { handleFlow };
};
