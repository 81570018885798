import { Loader } from '~shared/ui/loader';

import { Box, Col } from '@breeze-platform-ui/layout';

interface Props {
  phoneNumber: string;
}

export const OtpSending: React.FC<Props> = ({ phoneNumber: _phoneNumber }) => (
  <Col gaps={16} alignCross="stretch">
    <Box padding="xxl">
      <Loader fadeAnimation="true" />
    </Box>
  </Col>
);
