import { type BlocksContent } from '@strapi/blocks-react-renderer';

import { type Importance, type Media } from './api';

type TextBlock = {
  type: 'text';
  content: BlocksContent;
};

type MediaBlock = {
  type: 'media';
  content: Media[];
};

type CheckboxGroupQuestionConfig = {
  type: 'checkbox-group';
  question: string;
  options: string[];
};

type RadioGroupQuestionConfig = {
  type: 'radio-group';
  question: string;
  options: string[];
};

type TextAreaQuestionConfig = {
  type: 'text-area';
  question: string;
  placeholder?: string;
};

export type AgentNewsInfo = {
  id: string;
  publishedAt: string;
  isRead: boolean;
  title: string;
  importance: Importance;
  surveyDueDate: string | null;
};

export type SurveyQuestionConfig =
  | CheckboxGroupQuestionConfig
  | RadioGroupQuestionConfig
  | TextAreaQuestionConfig;

export type AgentNewsDetails = AgentNewsInfo & {
  article: (TextBlock | MediaBlock)[];
  surveyQuestions: SurveyQuestionConfig[];
};

export type AgentSurveyData = {
  newsId: string;
  status?: 'IN_PROGRESS' | 'FINISHED';
  data?: {
    [question: string]: unknown;
  };
};

export type AgentNewsData = {
  details: AgentNewsDetails;
  surveyData?: AgentSurveyData;
};

export const isCheckboxSurveyQuestion = (
  question: SurveyQuestionConfig
): question is CheckboxGroupQuestionConfig => {
  return question.type === 'checkbox-group';
};
export const isRadioSurveyQuestion = (
  question: SurveyQuestionConfig
): question is RadioGroupQuestionConfig => {
  return question.type === 'radio-group';
};
export const isTextAreaSurveyQuestion = (
  question: SurveyQuestionConfig
): question is TextAreaQuestionConfig => {
  return question.type === 'text-area';
};

export type { Media } from './api';
