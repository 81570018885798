import type { FieldErrors } from 'react-hook-form';
import { Controller, useForm, FormProvider } from 'react-hook-form';

import { AddressSuggestion } from '~entities/address';

import { useRevalidate } from '~shared/hooks';
import { combineValidators } from '~shared/lib/combine-validators';
import { getFieldsErrors } from '~shared/lib/get-fields-errors';
import { FormFooter } from '~shared/ui/form-footer';
import { FormHeader } from '~shared/ui/form-header';
import { Screen } from '~shared/ui/screen';
import { ScreenTitle } from '~shared/ui/screen-title';
import { validators } from '~shared/validators';

import { Input } from '@breeze/rhf-adapters';

import { STREET_HOUSE_FIELD_NAME, UNIT_FLOOR_FIELD_NAME } from '../constants';
import type { FormValues } from '../types';

const fieldNames = {
  country: 'country',
  region: 'region',
  postalCode: 'postalCode',
  province: 'province',
  cityOrMunicipality: 'cityOrMunicipality',
  barangay: 'barangay',
};

type Props = {
  name: string;
  initialValue?: Partial<FormValues>;
  onPrev: (
    values: Partial<FormValues>,
    errors: FieldErrors<FormValues>
  ) => void;
  onSubmit: (values: FormValues) => void;
  onFieldCompleted?: (
    values: Partial<FormValues>,
    errors: FieldErrors<FormValues>
  ) => void;
  onClose: () => void;
};

export const ResidentialAddress: React.FC<Props> = (props) => {
  const {
    name,
    initialValue = {},
    onSubmit,
    onFieldCompleted,
    onPrev,
    onClose,
  } = props;

  const methods = useForm<FormValues>({
    mode: 'all',
    defaultValues: initialValue,
  });

  const { getValues, handleSubmit, control } = methods;

  useRevalidate(methods);

  const handleBlur = () => {
    const actualErrors = getFieldsErrors<FormValues>(methods);

    onFieldCompleted?.(getValues(), actualErrors);
  };

  const handlePrevClick = () => {
    const actualErrors = getFieldsErrors<FormValues>(methods);

    onPrev(getValues(), actualErrors);
  };

  return (
    <form onBlur={handleBlur} onSubmit={handleSubmit(onSubmit)}>
      <Screen
        header={<FormHeader onClick={onClose} text={name} />}
        footer={<FormFooter onClickPrev={handlePrevClick} />}
      >
        <ScreenTitle title="Residential address" />
        <FormProvider {...methods}>
          <AddressSuggestion fieldNames={fieldNames} />
          <Controller
            name="streetAndHouse"
            control={control}
            rules={{
              required: 'Enter customer’s house number and street name',
              validate: combineValidators(
                validators.maxLength({ maxLength: 150 }),
                validators.filipinoAddress()
              ),
            }}
            render={(fieldProps) => (
              <Input label={STREET_HOUSE_FIELD_NAME} {...fieldProps} />
            )}
          />
          <Controller
            name="unitAndFloor"
            control={control}
            rules={{
              validate: combineValidators(
                validators.maxLength({ maxLength: 150 }),
                validators.filipinoAddress()
              ),
            }}
            render={(fieldProps) => (
              <Input label={UNIT_FLOOR_FIELD_NAME} {...fieldProps} />
            )}
          />
        </FormProvider>
      </Screen>
    </form>
  );
};
