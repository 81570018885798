import { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  type AgentNewsDetails,
  type AgentSurveyData,
  isItemImportant,
  useUpdateReadItemsMutation,
  markReadNewsInQueryCache,
} from '~entities/agent-news';

import { useQueryClient } from '@tanstack/react-query';

import { GeneralNewsItem } from './general-news-item';
import { SurveyNewsItem } from './survey-news-item';

type Props = {
  item: AgentNewsDetails;
  surveyData?: AgentSurveyData;
  onMarkRead?: () => void;
  isBlocking: boolean;
};

export const AgentNewsItemScreen = ({
  item,
  surveyData,
  onMarkRead,
  isBlocking,
}: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutate } = useUpdateReadItemsMutation();

  const handleNewsItemRead = useCallback(
    (id: string) => {
      markReadNewsInQueryCache(queryClient, [id]);
      mutate([id]);
    },
    [queryClient, mutate]
  );

  const handleGeneralNewsRead = useCallback(
    (id: string) => {
      handleNewsItemRead(id);
      onMarkRead?.();
    },
    [handleNewsItemRead, onMarkRead]
  );

  const handleClose = useCallback(() => {
    if (isBlocking) {
      onMarkRead?.();
    } else {
      navigate('/agent-news', { replace: true });
    }
  }, [isBlocking, onMarkRead, navigate]);

  useEffect(() => {
    // For survey items there are two request - completing survey and read news item
    // There can be situation when there is unstable internet connection, survey request is completed but read request is failed
    // In this case, on opening such item (when it's blocking or opened from the list again) we need to try making read request again
    if (surveyData?.status === 'FINISHED' && !item.isRead) {
      handleGeneralNewsRead(item.id);
    }
  }, [surveyData, item, handleGeneralNewsRead]);

  useEffect(() => {
    // Send read request for non-important items on open
    if (!isItemImportant(item) && !item.isRead) {
      handleNewsItemRead(item.id);
    }
  }, [item, handleNewsItemRead]);

  return item.surveyQuestions.length === 0 ? (
    <GeneralNewsItem item={item} onMarkRead={handleGeneralNewsRead} />
  ) : (
    <SurveyNewsItem
      item={item}
      isBlocking={isBlocking}
      surveyData={surveyData}
      onSurveyRead={handleNewsItemRead}
      onSurveyComplete={handleClose}
    />
  );
};
