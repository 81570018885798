import { environment } from '~app/environment';

import {
  webRequestClient,
  type RequestParams,
  type ScpClient,
} from '~shared/scp-client';

import isEmpty from '@tinkoff/utils/is/empty';

import type {
  SearchOfferResponse,
  OfferApi,
  SearchOfferByCodeResponse,
} from './offer-api';

import type { Offer } from '../offer';

type Options = { host: string };

class GatewayOfferApi implements OfferApi {
  scpClient: ScpClient;
  host: string;

  constructor(scpClient: ScpClient, options: Options) {
    this.scpClient = scpClient;
    this.host = options.host;
  }

  async getByPhone(phone: string): Promise<SearchOfferResponse> {
    const result = await this.makeRequest<Offer | undefined>({
      method: 'pos/get-active-by-phone',
      payload: { phone },
    });

    if (!result || isEmpty(result)) {
      return { status: 'NOT_FOUND' };
    }

    return { status: 'SUCCESS', ...result };
  }

  async getByCode(promoCode: string): Promise<SearchOfferByCodeResponse> {
    const result = await this.makeRequest<{ exists?: boolean }>({
      domain: 'referrals',
      service: 'public/promocode',
      method: 'check-exists-by-id',
      payload: { promoCode },
    });

    if (result && result.exists) {
      return { status: 'SUCCESS' };
    }

    return { status: 'NOT_FOUND', ...result };
  }

  private makeRequest<T>(params: Partial<RequestParams> & { method: string }) {
    return this.scpClient
      .call<T>({
        host: this.host,
        version: 'v1',
        domain: 'bros/marketing',
        service: 'public/offers',
        withCredentials: true,
        withLeadingDomain: true,
        ...params,
      })
      .then((response) => response.payload);
  }
}

export const gatewayOfferApi = new GatewayOfferApi(webRequestClient, {
  host: environment.API_HOST,
});
