import { recoverAdditionalIdDocument } from '~widgets/loan-application-form/additional-id-document';
import { recoverAlternativeContactNumber } from '~widgets/loan-application-form/alternative-contacts';
import { recoverClientAssessment } from '~widgets/loan-application-form/client-assessment';
import { recoverClientPhoneAssessment } from '~widgets/loan-application-form/client-phone-assessment';
import {
  recoverAdditionalEmploymentDetails,
  recoverEmploymentDetails,
} from '~widgets/loan-application-form/employment';
import { recoverFinances } from '~widgets/loan-application-form/finances';
import { recoverGCashDetails } from '~widgets/loan-application-form/gcash-details';
import { recoverIdDocument } from '~widgets/loan-application-form/id-document';
import { recoverIdDocumentType } from '~widgets/loan-application-form/id-document-type';
import { recoverPermanentAddress } from '~widgets/loan-application-form/permanent-address';
import { recoverPersonalDetails } from '~widgets/loan-application-form/personal-details';
import { recoverResidentialAddress } from '~widgets/loan-application-form/residential-address';

import type { Application, ApplicationRecovery } from '~entities/application';
import type { Person, PersonRecovery } from '~entities/person';

import identity from '@tinkoff/utils/function/identity';

import type {
  PersonDetailsStepName,
  ApplicationStepName,
  StepName,
} from './step-names';
import { personDetailsStepNames, applicationStepNames } from './step-names';

type ApplicationRecoveries = {
  [step in ApplicationStepName]: ApplicationRecovery<any>;
};

type PersonDetailsRecoveries = {
  [step in PersonDetailsStepName]: PersonRecovery<any>;
};

type ScreensValues = Record<StepName, any>;

const applicationRecoveries: ApplicationRecoveries = {
  [applicationStepNames.clientPhoneAssessment]: recoverClientPhoneAssessment,
  [applicationStepNames.clientAssessment]: recoverClientAssessment,
};

const personRecoveries: PersonDetailsRecoveries = {
  [personDetailsStepNames.personalDetails]: recoverPersonalDetails,
  [personDetailsStepNames.residentialAddress]: recoverResidentialAddress,
  [personDetailsStepNames.permanentAddress]: recoverPermanentAddress,
  [personDetailsStepNames.employmentDetails]: recoverEmploymentDetails,
  [personDetailsStepNames.additionalEmploymentDetails]:
    recoverAdditionalEmploymentDetails,
  [personDetailsStepNames.finances]: recoverFinances,
  [personDetailsStepNames.alternativeContacts]: recoverAlternativeContactNumber,
  [personDetailsStepNames.idDocumentType]: recoverIdDocumentType,
  [personDetailsStepNames.idDocument]: recoverIdDocument,
  [personDetailsStepNames.additionalIdDocument]: recoverAdditionalIdDocument,
  [personDetailsStepNames.clientPhoto]: identity,
  [personDetailsStepNames.gCashDetails]: recoverGCashDetails,
};

export function recoverApplicationScreenValues(application: Application) {
  return (Object.keys(applicationRecoveries) as ApplicationStepName[]).reduce(
    (acc, stepName) => {
      const screenRecovery = applicationRecoveries[stepName];
      acc[stepName] = screenRecovery(application);
      return acc;
    },
    {} as Partial<ScreensValues>
  );
}

export function recoverPersonScreenValues(person: Person) {
  return (Object.keys(personRecoveries) as PersonDetailsStepName[]).reduce(
    (acc, stepName) => {
      const screenRecovery = personRecoveries[stepName];
      acc[stepName] = screenRecovery(person);
      return acc;
    },
    {} as Partial<ScreensValues>
  );
}
