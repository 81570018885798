import { environment } from '~app/environment';

import {
  webRequestClient,
  type RequestParams,
  type ScpClient,
} from '~shared/scp-client';

import type { PosApi } from './pos-api';

import type { Pos } from '../pos';

interface Params {
  scpClient: ScpClient;
  host: string;
}

export class GatewayPosApi implements PosApi {
  client: ScpClient;
  host: string;

  constructor({ scpClient, host }: Params) {
    this.client = scpClient;
    this.host = host;
  }

  getPos() {
    return this.makeRequest<Pos[]>({
      method: 'find-all-by-employee-id',
      payload: { withZeroRate: true },
    });
  }

  private makeRequest<T>(params: Partial<RequestParams> & { method: string }) {
    return this.client
      .call<T>({
        host: this.host,
        version: 'v1',
        domain: 'merchant-portal',
        service: 'public/point-of-sales',
        withCredentials: true,
        withLeadingDomain: true,
        ...params,
      })
      .then((response) => response.payload);
  }
}

export const gatewayPosApi = new GatewayPosApi({
  scpClient: webRequestClient,
  host: environment.API_HOST,
});
