import type { FieldValues, Path, UseFormReturn } from 'react-hook-form';

import { useOnMountEffect } from './use-on-mount-effect';

export function useRevalidate<T extends FieldValues>(
  useForm: UseFormReturn<T>,
  fieldsToValidate?: Path<T>[]
) {
  useOnMountEffect(() => {
    const { trigger, getValues } = useForm;

    const fields = fieldsToValidate?.length
      ? fieldsToValidate
      : (Object.keys(getValues()) as Path<T>[]);

    fields.forEach((field) => {
      if (getValues(field)) {
        trigger(field);
      }
    });
  });
}
