import { useDi } from '~app/providers/di-provider';

import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import type { Status } from '../api';

export const useGenerateAgreementQuery = (
  options?: UseQueryOptions<'OK', Error, void>
) => {
  const { agreementGenerationApi } = useDi();
  return useQuery({
    queryKey: ['generateAgreement'],
    queryFn: () => agreementGenerationApi.generate(),
    cacheTime: 0,
    ...options,
  });
};

export const useGetAgreementStatusQuery = (
  options?: UseQueryOptions<Status, Error>
) => {
  const { agreementGenerationApi } = useDi();
  return useQuery({
    queryKey: ['agreementStatus'],
    queryFn: () => agreementGenerationApi.getStatus(),
    cacheTime: 0,
    ...options,
  });
};
