export type GetListResponse<T> = {
  data: T[];
  meta: {
    pagination: {
      page: number;
      pageSize: number;
      pageCount: number;
      total: number;
    };
  };
};

export type GetOneResponse<T> = {
  data: T;
};

export type ErrorResponse = {
  data: null;
  error: {
    status: number;
    name: string;
    message: string;
    details: any;
  };
};

export type CmsResponse<T> =
  | ErrorResponse
  | GetOneResponse<T>
  | GetListResponse<T>;

export function isErrorResponse<T>(
  response: CmsResponse<T>
): response is ErrorResponse {
  return (response as ErrorResponse).error !== undefined;
}
