import { CurrentPosInfo } from '~features/current-pos/current-pos';

import { NavCloseButton } from '~shared/ui/nav-close-button';

import { Row, Col } from '@breeze-platform-ui/layout';

import styles from './header.module.css';

type Props = {
  onClose: () => void;
};

export const Header = ({ onClose }: Props) => {
  return (
    <Col width="100%">
      <Row
        height={36}
        alignCross="center"
        alignMain="between"
        width="100%"
        data-qa-type="header"
      >
        <NavCloseButton onClick={onClose} />
        <div className={styles.container}>
          <CurrentPosInfo />
        </div>
      </Row>
    </Col>
  );
};
