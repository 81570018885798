import { Col } from '@breeze-platform-ui/layout';

import styles from './gcash-hint.module.css';
import gscoreImg from './gscore.webp';
import profileImg from './profile.webp';

export const GCashHint: React.FC = () => {
  return (
    <div className={styles.container}>
      <Col alignCross="stretch" gaps={16}>
        <h3 className={styles.header}>GScore screen</h3>
        <img src={gscoreImg} alt="gscore hint" className={styles.img} />
      </Col>
      <Col alignCross="stretch" gaps={16}>
        <h3 className={styles.header}>GCash profile screen</h3>
        <img src={profileImg} alt="gscore hint" className={styles.img} />
      </Col>
    </div>
  );
};
