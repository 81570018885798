import type {
  ApplicationFormatter,
  ApplicationRecovery,
  Application,
  QuestionnaireItem,
} from '~entities/application';

import {
  clientAssessmentOptions,
  commentOption,
} from './client-assessment-options';

import { CANT_INSTALL_APP_QUESTION } from '../../client-phone-assessment/lib/client-phone-question';
import type { FormValues } from '../ui/client-assessment';

export const formatClientAssessment: ApplicationFormatter<FormValues> = (
  application,
  stepValues
) => {
  const { clientAssessment = [], comment } = stepValues;
  const answers = new Set(clientAssessment);
  const quastionnaire = clientAssessmentOptions.map(({ value }) => ({
    question: value,
    answer: answers.has(value) ? 'true' : 'false',
  })) as QuestionnaireItem[];

  const clientPhoneAssessment = application.employeeQuestionnaire?.find(
    (q) => q.question === CANT_INSTALL_APP_QUESTION
  );
  if (clientPhoneAssessment) {
    quastionnaire.push(clientPhoneAssessment);
  }

  if (comment) {
    quastionnaire.push({
      question: commentOption.value,
      answer: 'true',
      comment,
    });
  }

  return { ...application, employeeQuestionnaire: quastionnaire };
};

export const recoverClientAssessment: ApplicationRecovery<
  Omit<FormValues, 'clientAssessment'>
> = (application: Application) => {
  const employeeQuestionnaire = application.employeeQuestionnaire || [];
  const checkedQuestions = employeeQuestionnaire.filter(
    (question) =>
      question.answer === 'true' &&
      question.question !== CANT_INSTALL_APP_QUESTION &&
      question.question !== commentOption.value
  );
  const clientAssessment = checkedQuestions.map(({ question }) => question);
  const comment = employeeQuestionnaire.find(
    (item) => item.question === commentOption.value
  )?.comment;
  return {
    clientAssessment,
    comment,
  };
};
