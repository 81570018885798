import type { TrackingState } from '@breeze/web-analytics';
import { findOrCreateWuid } from '@breeze/web-lib-wuid';

import { deviceLocation } from './geolocation';
import type { DeviceMetadata } from './types';

export const getDeviceMetadata = (
  trackingState?: TrackingState
): DeviceMetadata => {
  return {
    ...trackingState?.deviceInfo,
    ...deviceLocation.getLocation(),
    webUID: findOrCreateWuid(),
    devicePixelRatio: `${window.devicePixelRatio}`,
    deviceScreenHeight: `${window.screen.height}`,
    deviceScreenWidth: `${window.screen.width}`,
    userAgent: navigator?.userAgent,
  };
};
