import type { ZeroRateItem } from '~entities/product';

import type { SelectProps } from '@breeze-platform-ui/select';
import Text from '@breeze-platform-ui/text';

type SelectOptions = NonNullable<SelectProps['options']>;
type Option = {
  title: string;
  value: string;
  disabled: boolean;
};

// TODO: remove this logic when the promo is finished
const promoItemsIds = new Set([
  '01J2WX6PJR51QA09490YGF057M',
  '01J2WX6PK8WQJY1YD9R1HW1JYC',
]);

export function getZeroRateItemsOptions(
  items: Record<string, ZeroRateItem>
): SelectOptions {
  const honorPromoItems: Option[] = [];
  const zeroRateItems: Option[] = [];

  Object.entries(items).forEach(([id, item]) => {
    const itemOption = {
      title: item.name,
      value: id,
      disabled: !item.availableForSelection,
    };

    if (promoItemsIds.has(item.id)) {
      honorPromoItems.push(itemOption);
    } else {
      zeroRateItems.push(itemOption);
    }
  });

  const options = honorPromoItems.concat(zeroRateItems);

  return [
    {
      options: options.filter((option) => !option.disabled),
      value: 'Available',
    },
    {
      options: options.filter((option) => option.disabled),
      value: 'Unavailable',
      title: <Text bold>Unavailable with selected products</Text>,
    },
  ].filter((op) => op.options.length > 0);
}
