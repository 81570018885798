import { useRef } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { type ItemInsuranceParameters } from '~entities/vas';

import { ButtonLink } from '@breeze-platform-ui/button';
import { Row, Col } from '@breeze-platform-ui/layout';
import { RadioGroup } from '@breeze/rhf-adapters';

import { getPriceFromValueWithCurrency } from '../../lib';
import type { LoanDetailsFormValues, LoanItemInfo } from '../../types';
import styles from '../loan-details-form.module.css';
import { UploadManufacturerId } from '../manufacturer-id';
import { VasLabel } from '../vas-label';
import { VasPriceInfo } from '../vas-price-info';

type VasMap = { [name: string]: ItemInsuranceParameters };

const getVasMap = (vases: ItemInsuranceParameters[]): VasMap => {
  const parameters: VasMap = {};
  vases.forEach((vas) => {
    parameters[vas.id] = vas;
  });
  return parameters;
};

type Props = {
  applicationId: string;
  index: number;
} & LoanItemInfo;

export const ItemDetails = ({
  applicationId,
  index,
  name,
  vasList,
  zeroRateItemId,
  type,
  producerType,
}: Props) => {
  const value = useWatch({
    name: `itemsDetails.${index}.vasCatalogId`,
  });
  const { resetField, setValue, getValues, control } =
    useFormContext<LoanDetailsFormValues>();

  const parametersObject = useRef(getVasMap(vasList));

  const updateTotalVasPayment = (newItemPrice: number) => {
    const prevVasId = getValues().itemsDetails[index].vasCatalogId as string;
    const prevItemPrice = getPriceFromValueWithCurrency(
      parametersObject.current[prevVasId]?.monthlyPayment
    );
    const prevTotalPayment = getValues().totalVasMonthlyPayment;
    setValue(
      'totalVasMonthlyPayment',
      prevTotalPayment - prevItemPrice + newItemPrice
    );
  };

  const handleVasChange = (vasCatalogId: string) => {
    const currentPrice = getPriceFromValueWithCurrency(
      parametersObject.current[vasCatalogId]?.monthlyPayment
    );
    updateTotalVasPayment(currentPrice);
  };

  const handleVasReset = () => {
    updateTotalVasPayment(0);
    resetField(`itemsDetails.${index}.vasCatalogId`, {
      defaultValue: '',
    });
  };

  // we should collect manufacturer id in two cases:
  // 1. zero rate item with brand producer
  // 2. vas for the item has been selected
  const shouldCollectManufacturerId =
    (zeroRateItemId && producerType !== 'RETAILER') || value;

  return (
    <Col alignCross="stretch" className={styles.panel}>
      <Row alignCross="start" alignMain="between" gaps="s" margin="0 0 12px">
        <div
          className={styles.itemTitle}
          data-qa-data={`item-details-title-${index}`}
        >
          {name}
        </div>
        <div className={styles.buttonContainer}>
          {value && (
            <ButtonLink
              dataQaType={`clear-all-item-details-button-${index}`}
              disableHorizontalPadding
              disableVerticalPadding
              size="m"
              onClick={handleVasReset}
            >
              Clear&nbsp;all
            </ButtonLink>
          )}
        </div>
      </Row>
      {vasList.length > 0 && (
        <Controller
          name={`itemsDetails.${index}.vasCatalogId`}
          control={control}
          render={(fieldProps) => {
            const onFieldChange = fieldProps.field.onChange;
            const onChange = (vasCatalogId: string) => {
              // firstly update total monthly payment
              handleVasChange(vasCatalogId);
              // then update selected vasId
              onFieldChange(vasCatalogId);
            };
            // eslint-disable-next-line no-param-reassign
            fieldProps.field.onChange = onChange;

            return (
              <RadioGroup
                {...fieldProps}
                options={vasList.map((vas) => ({
                  value: vas.id,
                  label: <VasLabel {...vas} />,
                  separated: false,
                  borderType: 'under',
                  description: (
                    <VasPriceInfo
                      monthlyPayment={getPriceFromValueWithCurrency(
                        vas.monthlyPayment
                      )}
                      monthlyPaymentForAllChildVasList={getPriceFromValueWithCurrency(
                        vas.monthlyPaymentForAllChildVasList
                      )}
                    />
                  ),
                }))}
                vertical
                wide
              />
            );
          }}
        />
      )}

      {shouldCollectManufacturerId && (
        <UploadManufacturerId
          applicationId={applicationId}
          index={index}
          type={type}
          isZeroRate={!!zeroRateItemId}
        />
      )}
    </Col>
  );
};
