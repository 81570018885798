import { useQuery } from '@tanstack/react-query';

import { gatewayScheduleApi } from './gateway-schedule-api';

export function useGetDailySchedule(
  date: string,
  options?: { enabled?: boolean }
) {
  return useQuery({
    queryKey: ['schedule', date],
    queryFn: () => gatewayScheduleApi.getDailySchedule({ date }),
    refetchOnWindowFocus: true,
    retry: 3,
    ...options,
  });
}
