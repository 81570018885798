import { useState } from 'react';

import {
  type SurveyQuestionConfig,
  isCheckboxSurveyQuestion,
  isRadioSurveyQuestion,
  isTextAreaSurveyQuestion,
} from '~entities/agent-news';

import { Col } from '@breeze-platform-ui/layout';

import styles from './agent-survey-form.module.css';
import {
  CheckboxGroupQuestionForm,
  RadioGroupQuestionForm,
  TextAreaQuestionForm,
} from './survey-questions';

import { recoverInitialFormValues } from '../lib';
import { type SurveyFormValues } from '../types';

type Props = {
  questions: SurveyQuestionConfig[];
  initialValues: {
    [question: string]: unknown;
  };
  onSurveyExit: () => void;
  onFormChanged: (values: SurveyFormValues) => void;
  onFinalSubmit: (values: SurveyFormValues) => void;
};

export const AgentSurveyForm = ({
  questions,
  initialValues,
  onFinalSubmit,
  onFormChanged,
  onSurveyExit,
}: Props) => {
  const [questionsCount] = useState(questions.length);
  const [step, setStep] = useState(0);
  const [formState, setFormState] = useState<SurveyFormValues>(
    recoverInitialFormValues(initialValues, questions)
  );
  const isLastScreen = step === questionsCount - 1;

  const handleGoBack = (values: SurveyFormValues) => {
    setFormState((prev) => ({ ...prev, ...values }));
    step > 0 ? setStep((prev) => prev - 1) : onSurveyExit();
  };

  const handleStepSubmit = (values: SurveyFormValues) => {
    if (isLastScreen) {
      onFinalSubmit({ ...formState, ...values });
    } else {
      setFormState((prev) => ({ ...prev, ...values }));
      setStep((prev) => prev + 1);
    }
  };
  const handleFieldCompleted = (values: SurveyFormValues) => {
    if (!isLastScreen) {
      onFormChanged({ ...formState, ...values });
    }
  };

  const getSurveyStepScreen = () => {
    const questionConfig = questions[step];

    if (isRadioSurveyQuestion(questionConfig)) {
      return (
        <RadioGroupQuestionForm
          key={questionConfig.question}
          initialValue={formState[questionConfig.question] as string}
          question={questionConfig.question}
          options={questionConfig.options}
          isLastScreen={isLastScreen}
          onFieldCompleted={handleFieldCompleted}
          onPrev={handleGoBack}
          onSubmit={handleStepSubmit}
        />
      );
    }
    if (isCheckboxSurveyQuestion(questionConfig)) {
      return (
        <CheckboxGroupQuestionForm
          key={questionConfig.question}
          initialValue={formState[questionConfig.question] as string[]}
          question={questionConfig.question}
          options={questionConfig.options}
          isLastScreen={isLastScreen}
          onFieldCompleted={handleFieldCompleted}
          onPrev={handleGoBack}
          onSubmit={handleStepSubmit}
        />
      );
    }
    if (isTextAreaSurveyQuestion(questionConfig)) {
      return (
        <TextAreaQuestionForm
          key={questionConfig.question}
          initialValue={formState[questionConfig.question] as string}
          question={questionConfig.question}
          placeholder={questionConfig.placeholder}
          isLastScreen={isLastScreen}
          onFieldCompleted={handleFieldCompleted}
          onPrev={handleGoBack}
          onSubmit={handleStepSubmit}
        />
      );
    }
  };

  return (
    <Col alignCross="stretch">
      {questionsCount > 1 && (
        <div className={styles.step}>
          Question {step + 1} of {questionsCount}
        </div>
      )}

      {getSurveyStepScreen()}
    </Col>
  );
};
