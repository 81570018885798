import type { OrderItem } from '~entities/order-item';

/**
 * Return price of all added items
 * @param items - list of added items
 * @returns
 */
export function calculateItemsPrice(items: OrderItem[]): number {
  const price = items.reduce(
    (sum, item) => sum + +item.price * +item.quantity,
    0
  );

  return Math.round(price * 100) / 100;
}

export function calculateItemsCount(items: OrderItem[]): number {
  return items.reduce((count, item) => count + item.quantity, 0);
}
