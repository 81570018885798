import { useState } from 'react';
import type {
  FieldPath,
  FieldValues,
  UseControllerReturn,
} from 'react-hook-form';

import { FormRow } from '@breeze-platform-ui/form';
import { MaskedInputGlobal as PlatformMaskedInput } from '@breeze-platform-ui/input';
import type { MaskedInputProps as PlatformMaskedInputProps } from '@breeze-platform-ui/input';

interface Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> extends Omit<
      PlatformMaskedInputProps,
      'name' | 'onChange' | 'onBlur' | 'value'
    >,
    UseControllerReturn<TFieldValues, TName> {
  confirmed: boolean;
  confirmationError?: React.ReactNode | null;
}

export function ConfirmationInput<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(props: Props<TFieldValues, TName>) {
  const {
    dataQaType,
    field,
    fieldState,
    formState: _formState,
    mask = 'XXXXXX',
    maskTransitions = { X: /\d/ },
    placeholder = '••••••',
    required,
    confirmed,
    confirmationError = null,
    ...inputProps
  } = props;

  const [focused, setFocused] = useState(true);

  const hasError =
    (!focused && typeof fieldState.error !== 'undefined') ||
    confirmationError !== null;
  const errorMessage = fieldState.error?.message || confirmationError;

  const isValid = !hasError && confirmed;

  return (
    <FormRow
      dataQaType={dataQaType}
      withoutOffset
      required={required}
      error={hasError}
      errorMessage={errorMessage}
    >
      <PlatformMaskedInput
        {...field}
        {...inputProps}
        inputMode="numeric"
        onClean={() => field.onChange('')}
        valid={isValid}
        required={required}
        error={hasError}
        focused={focused}
        mask={mask}
        maskTransitions={maskTransitions}
        placeholder={placeholder}
        onFocus={() => setFocused(true)}
        onBlur={() => {
          setFocused(false);
          field.onBlur();
        }}
      />
    </FormRow>
  );
}
