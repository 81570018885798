import { useNavigate } from 'react-router-dom';

import type { PreCalcNavigationState } from '~pages/loan-calculator';

import { recoverLoanCalculator } from '~widgets/loan-calculator';

import type {
  ApplicationDetails,
  ApplicationProcessStatus,
} from '~entities/application';

import Button from '@breeze-platform-ui/button';
import Island from '@breeze-platform-ui/island';

import { ProcessStatusTitle } from '../process-status-title/process-status-title';

type Props = {
  processStatus: ApplicationProcessStatus;
  applicationDetails: ApplicationDetails;
};
export const PreApproved = ({ processStatus, applicationDetails }: Props) => {
  const navigate = useNavigate();

  const goToApplication = () => {
    const state: PreCalcNavigationState = {
      loanCalculator: recoverLoanCalculator(applicationDetails),
      preApprovedApplication: {
        applicationId: applicationDetails.id,
        phoneNumber: applicationDetails.person.mobilePhone,
      },
    };
    navigate(`/loan-calculator`, { replace: true, state });
  };

  return (
    <Island
      theme="shadow"
      text="Continue filling out&nbsp;the&nbsp;application"
      title={<ProcessStatusTitle processStatus={processStatus} />}
      footerBody={
        <Button wide size="m" onClick={goToApplication}>
          Continue application
        </Button>
      }
    />
  );
};
