export const getMinDownPayment = (
  totalPrice: number,
  loanOptions: {
    maxLoanAmount: number;
    minDownPaymentPercent: number;
  }
) => {
  // min down payment should be >= 1
  const minDownPaymentByPercent = Math.ceil(
    (totalPrice * loanOptions.minDownPaymentPercent) / 100
  );
  const minDownPaymentForMaxLoan = Math.ceil(
    totalPrice - loanOptions.maxLoanAmount
  );

  if (totalPrice - minDownPaymentByPercent > loanOptions.maxLoanAmount) {
    return minDownPaymentForMaxLoan;
  }

  return minDownPaymentByPercent;
};

export const getMaxDownPayment = (
  totalPrice: number,
  loanOptions: { minLoanAmount: number; maxDownPaymentPercent: number }
) => {
  const maxDownPaymentByPercent = Math.floor(
    (totalPrice * loanOptions.maxDownPaymentPercent) / 100
  );
  const maxDownPaymentForMinLoan = Math.floor(
    totalPrice - loanOptions.minLoanAmount
  );

  if (totalPrice - maxDownPaymentByPercent < loanOptions.minLoanAmount) {
    return maxDownPaymentForMinLoan;
  }

  return maxDownPaymentByPercent;
};

export const getDefaultDownPayment = (
  totalPrice: number,
  loanOptions: {
    maxLoanAmount: number;
    minDownPaymentPercent: number;
    maxDownPaymentPercent: number;
  }
) => {
  if (
    loanOptions.minDownPaymentPercent < 20 &&
    loanOptions.maxDownPaymentPercent > 20
  ) {
    return getMinDownPayment(totalPrice, {
      ...loanOptions,
      minDownPaymentPercent: 20,
    });
  }

  return getMinDownPayment(totalPrice, loanOptions);
};

export const getMinPriceForDownPayment = (
  downPayment: number,
  loanOptions: {
    maxLoanAmount: number;
    minLoanAmount: number;
    maxDownPaymentPercent: number;
  }
) => {
  const minPriceByPercent = Math.ceil(
    downPayment / (loanOptions.maxDownPaymentPercent / 100)
  );
  const minPriceForMinLoan = Math.ceil(downPayment + loanOptions.minLoanAmount);

  // can't calculate min price due to impossibly big downPayment
  if (minPriceByPercent - downPayment > loanOptions.maxLoanAmount) {
    return NaN;
  }

  if (minPriceByPercent - downPayment < loanOptions.minLoanAmount) {
    return minPriceForMinLoan;
  }

  return minPriceByPercent;
};

export const getMaxPriceForDownPayment = (
  downPayment: number,
  loanOptions: {
    maxLoanAmount: number;
    minLoanAmount: number;
    minDownPaymentPercent: number;
  }
) => {
  const maxPriceByPercent = Math.floor(
    downPayment / (loanOptions.minDownPaymentPercent / 100)
  );
  const maxPriceForMaxLoan = Math.floor(
    downPayment + loanOptions.maxLoanAmount
  );

  // can't calculate max price due to impossibly small downPayment
  if (maxPriceByPercent - downPayment < loanOptions.minLoanAmount) {
    return NaN;
  }

  if (maxPriceByPercent - downPayment > loanOptions.maxLoanAmount) {
    return maxPriceForMaxLoan;
  }

  return maxPriceByPercent;
};
