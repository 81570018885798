import { useState } from 'react';

import { environment } from '~app/environment';

import type { ApplicationProcessStatus } from '~entities/application';

import Island from '@breeze-platform-ui/island';

import { WaitingTime } from './waiting-time';

import { ProcessStatusTitle } from '../../process-status-title/process-status-title';

type Props = {
  processStatus: ApplicationProcessStatus;
};

export const WaitingForDecision = ({ processStatus }: Props) => {
  const [waitingTimeExceeded, setWaitingTimeExceeded] =
    useState<boolean>(false);
  const limitInMinutes = environment.POS_LOAN_APPROVAL_TIME_LIMIT;
  const text = waitingTimeExceeded ? (
    <span>
      Make sure that the&nbsp;customer and the&nbsp;references are aware
      of&nbsp;our call
    </span>
  ) : (
    <span>It can take up&nbsp;to&nbsp;{limitInMinutes} minutes.</span>
  );

  // dirty solution, need something better
  const statusMessage = waitingTimeExceeded
    ? 'We need more time to check customer’s info'
    : processStatus.message;

  return (
    <Island
      theme="shadow"
      text={text}
      title={
        <ProcessStatusTitle
          processStatus={{ ...processStatus, message: statusMessage }}
        />
      }
      picture={
        <WaitingTime
          limitInMinutes={limitInMinutes}
          statusUpdatedUTC={processStatus.dateUTC}
          onWaitingTimeExceeded={() => setWaitingTimeExceeded(true)}
        />
      }
    />
  );
};
