import Clickable from '@breeze-platform-ui/clickable';
import { Int16Close } from '@breeze-platform-ui/iconsPack';

import styles from './popup-close-button.module.css';

interface Props {
  onClick: () => void;
}

export const PopupCloseButton = ({ onClick }: Props) => {
  return (
    <Clickable className={styles.button} onClick={onClick}>
      <Int16Close theme={{ color: '#9299A2' }} />
    </Clickable>
  );
};
