import type { AbstractOption } from '~shared/types/abstract-option';

const options = [
  {
    value: 'SINGLE',
    title: 'Single',
  },
  {
    value: 'MARRIED',
    title: 'Married',
  },
  {
    value: 'SEPARATED',
    title: 'Separated',
  },
  {
    value: 'DIVORCED',
    title: 'Divorced',
  },
  {
    value: 'WIDOWED',
    title: 'Widowed',
  },
] as const;

export type CivilStatusValue = typeof options[number]['value'];
export const civilStatusOptions =
  options as unknown as AbstractOption<CivilStatusValue>[];
