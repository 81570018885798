import { useGetApplicationListQuery } from '~entities/application';
import { selectedPosAtom } from '~entities/pos';

import Badge from '@breeze-platform-ui/badge';
import { useAtomValue } from 'jotai';

export const PendingNumber = () => {
  const selectedPos = useAtomValue(selectedPosAtom);
  const { data } = useGetApplicationListQuery(selectedPos?.id);

  const pendingNumber = data?.pending?.length ?? 0;
  return pendingNumber > 0 ? (
    <Badge customBackground="#F52222" size="m" padding={8}>
      {pendingNumber}
    </Badge>
  ) : null;
};
