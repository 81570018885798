import React, { createContext } from 'react';

import type { AddressSuggestionApi } from '~entities/address';
import type { AgentIncentiveApi } from '~entities/agent-incentive';
import type { CmsAgentNewsApi, AgentNewsApi } from '~entities/agent-news';
import type { AgreementApi } from '~entities/agreement';
import type { ApplicationApi } from '~entities/application';
import type { AuthApi } from '~entities/auth';
import type { AgreementGenerationApi } from '~entities/document';
import type { DocumentApi } from '~entities/document';
import type { FileUploadApi } from '~entities/file';
import type { OfferApi } from '~entities/offer';
import type { PersonApi } from '~entities/person';
import type { PosApi } from '~entities/pos';
import type { ProductApi } from '~entities/product';
import type { VasApi } from '~entities/vas';

type DiContextValue = {
  addressSuggestionApi: AddressSuggestionApi;
  applicationApi: ApplicationApi;
  fileUploadApi: FileUploadApi;
  agreementApi: AgreementApi;
  agreementGenerationApi: AgreementGenerationApi;
  documentApi: DocumentApi;
  productApi: ProductApi;
  personApi: PersonApi;
  posApi: PosApi;
  vasApi: VasApi;
  authApi: AuthApi;
  offerApi: OfferApi;
  agentIncentiveApi: AgentIncentiveApi;
  cmsAgentNewsApi: CmsAgentNewsApi;
  agentNewsApi: AgentNewsApi;
};

const DiContext = createContext<null | DiContextValue>(null);

interface Props {
  di: DiContextValue;
}

export const DiProvider: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { di, children } = props;

  return <DiContext.Provider value={di}>{children}</DiContext.Provider>;
};

export const useDi = (): DiContextValue => {
  const maybeDi = React.useContext(DiContext);

  if (maybeDi === null) {
    throw new Error('useDi must be used within a DiProvider');
  }

  return maybeDi;
};
