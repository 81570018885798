import { useCustomerAnalytics } from '~app/providers/analytics-provider';

import styles from './zoom-button.module.css';

type Props = {
  applicationId: string;
  value: 1 | 2;
  isApplied: boolean;
  onClick: () => void;
};

export const ZoomButton = ({
  value,
  isApplied,
  applicationId,
  onClick,
}: Props) => {
  const analytics = useCustomerAnalytics();
  return (
    <button
      type="button"
      className={`${styles.zoomButton} ${
        isApplied ? styles.applied : styles.notApplied
      }`}
      onClick={(e) => {
        e.preventDefault();
        analytics.trackBarcodeRecognitionEvent('zoom', {
          applicationId,
          value,
        });
        onClick();
      }}
    >
      {value}×
    </button>
  );
};
