import type { PropsWithChildren } from 'react';

import logo from '~shared/images/logo.svg';

import { Row } from '@breeze-platform-ui/layout';

export const HeaderWithLogo = ({ children }: PropsWithChildren) => {
  return (
    <Row
      height={36}
      width="100%"
      alignCross="center"
      alignMain="between"
      data-qa-type="header"
    >
      <img src={logo} alt="" height="36" width="120" />
      {children}
    </Row>
  );
};
