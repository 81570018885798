import { format } from 'date-fns';

export const getBonusHistoryDate = (dateString: string) => {
  const date = new Date(dateString);
  const bonusYear = date.getFullYear();
  const bonusMonth = format(date, 'LLLL');

  if (new Date().getFullYear() > bonusYear) {
    return `${bonusMonth}, ${bonusYear}`;
  }

  return bonusMonth;
};
