import { HttpError, isHttpError } from '~shared/errors';

export class CodeConfirmationError extends Error {
  name = 'WRONG_CODE_ERROR';
}

export function isCodeConfirmationError(
  error: Error
): error is CodeConfirmationError {
  return error.name === 'WRONG_CODE_ERROR';
}

export class SessionExpiredError extends HttpError {
  status: 401;

  constructor(options: { message: string; status: number; code?: string }) {
    super(options);
    this.status = 401;
  }
}

export function isSessionExpiredError(
  error: Error
): error is SessionExpiredError {
  return isHttpError(error) && error.status === 401;
}

export class AttemptsLimitError extends Error {
  name = 'ATTEMPTS_LIMIT_REACHED_ERROR';
}

export function isAttemptsLimitError(
  error: Error
): error is AttemptsLimitError {
  return error.name === 'ATTEMPTS_LIMIT_REACHED_ERROR';
}
