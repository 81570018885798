import type { PropsWithChildren } from 'react';

import styles from './island.module.css';

export const Island: React.FC<
  PropsWithChildren<
    Partial<{
      dataQaType: string;
      style: React.HTMLAttributes<HTMLDivElement>['style'];
    }>
  >
> = ({ children, style, dataQaType }) => {
  return (
    <div data-qa-type={dataQaType} className={styles.container} style={style}>
      {children}
    </div>
  );
};
