import { Fragment } from 'react';

import {
  type AgentNewsDetails,
  type Media,
  isItemImportant,
} from '~entities/agent-news';

import { Int16AttentionFilled } from '@breeze-platform-ui/iconsPack';
import { Row } from '@breeze-platform-ui/layout';
import { format, isToday } from 'date-fns';

import styles from './article.module.css';
import { BlockContent } from './block-content';
import { Video } from './video';

type MediaProps = {
  item: Media;
};

export const MediaBlock = ({ item }: MediaProps) => {
  return (
    <Row alignMain="center" margin="0 0 24px">
      {item.ext === '.mp4' ? (
        <Video src={item.url} />
      ) : (
        <img
          src={item.url}
          alt={item.alternativeText || ''}
          className={styles.image}
        />
      )}
    </Row>
  );
};
type Props = {
  item: AgentNewsDetails;
};

export const Article = ({ item }: Props) => {
  const dateObj = new Date(item.publishedAt);
  const formattedDate = format(
    dateObj,
    isToday(dateObj) ? "'Today, 'h:mm a" : 'MMMM dd'
  );
  return (
    <>
      <Row alignCross="center" margin="0 0 6px">
        {isItemImportant(item) && (
          <Int16AttentionFilled theme="red" style={{ marginRight: '8px' }} />
        )}
        <div className={styles.date}>{formattedDate}</div>
      </Row>

      <h1 className={styles.title}>{item.title}</h1>
      {item.article.map((block, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={index}>
          {block.type === 'text' && <BlockContent content={block.content} />}
          {block.type === 'media' &&
            block.content.map((mediaItem, ind) => (
              // eslint-disable-next-line react/no-array-index-key
              <MediaBlock item={mediaItem} key={ind} />
            ))}
        </Fragment>
      ))}
    </>
  );
};
