const options = [
  {
    value: 'Is the customer suspicious?',
    label: <span>Is the&nbsp;customer suspicious?</span>,
  },
  {
    value: 'Was the customer rejected by competitor today?',
    label: (
      <span>Was the&nbsp;customer rejected by&nbsp;competitor today?</span>
    ),
  },
  {
    value: 'Other',
    label: 'Other',
  },
] as const;

export const commentOption = {
  value: "Agent's comment",
  title: 'Comment your choice',
};

export type ClientAssessmentValue = typeof options[number]['value'];

export const clientAssessmentOptions = options as unknown as {
  value: ClientAssessmentValue;
  label: string;
}[];
