import type { FieldErrors } from 'react-hook-form';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { useCustomerAnalytics } from '~app/providers/analytics-provider';

import type { ResidentialAddressFormValues } from '~widgets/loan-application-form/residential-address';

import { AddressSuggestion } from '~entities/address';

import { useOnMountEffect, useRevalidate } from '~shared/hooks';
import { combineValidators } from '~shared/lib/combine-validators';
import { getFieldsErrors } from '~shared/lib/get-fields-errors';
import { FormFooter } from '~shared/ui/form-footer';
import { FormHeader } from '~shared/ui/form-header';
import { Screen } from '~shared/ui/screen';
import { ScreenTitle } from '~shared/ui/screen-title';
import { validators } from '~shared/validators';

import isEmpty from '@tinkoff/utils/is/empty';

import { Checkbox, Input } from '@breeze/rhf-adapters';

import { STREET_HOUSE_FIELD_NAME, UNIT_FLOOR_FIELD_NAME } from '../constants';
import type { FormValues } from '../types';

const fieldNames = {
  country: 'country',
  region: 'region',
  postalCode: 'postalCode',
  province: 'province',
  cityOrMunicipality: 'cityOrMunicipality',
  barangay: 'barangay',
};

const formFields: (keyof FormValues)[] = [
  'country',
  'region',
  'province',
  'cityOrMunicipality',
  'barangay',
  'postalCode',
  'streetAndHouse',
  'unitAndFloor',
];

type Props = {
  name: string;
  initialValue?: Partial<FormValues>;
  residentialAddress: ResidentialAddressFormValues;
  onSubmit: (values: FormValues) => void;
  onPrev: (
    values: Partial<FormValues>,
    errors: FieldErrors<FormValues>
  ) => void;
  onFieldCompleted?: (
    values: Partial<FormValues>,
    errors: FieldErrors<FormValues>
  ) => void;
  onClose: () => void;
};

export const PermanentAddress: React.FC<Props> = (props) => {
  const {
    name,
    initialValue = { sameAsCurrent: true },
    residentialAddress,
    onSubmit,
    onFieldCompleted,
    onPrev,
    onClose,
  } = props;

  const methods = useForm<FormValues>({
    mode: 'all',
    defaultValues: initialValue,
  });
  const { getValues, handleSubmit, control, watch, reset, setValue } = methods;
  const { id } = useParams();

  const analytics = useCustomerAnalytics();
  useRevalidate(methods);

  const handleOnChange = () => {
    const actualErrors = getFieldsErrors<FormValues>(methods);

    onFieldCompleted?.(getValues(), actualErrors);
  };

  const handlePrevClick = () => {
    const actualErrors = getFieldsErrors<FormValues>(methods);

    onPrev(getValues(), actualErrors);
  };
  const sameAsCurrentValue = watch('sameAsCurrent');

  useOnMountEffect(() => {
    const { sameAsCurrent, ...initialAddress } = methods.getValues() ?? {};

    if (sameAsCurrent || isEmpty(initialAddress)) {
      reset({ sameAsCurrent: true, ...residentialAddress });
    }
  });

  const handleSameAsCurrentChange = (value: boolean) => {
    if (value) {
      reset({ sameAsCurrent: true, ...residentialAddress });
    } else {
      id && analytics.trackAddressCheckboxSwitchedOff({ applicationId: id });
      formFields.forEach((field) => setValue(field, ''));
    }
  };

  return (
    <form onBlur={handleOnChange} onSubmit={handleSubmit(onSubmit)}>
      <Screen
        header={<FormHeader onClick={onClose} text={name} />}
        footer={<FormFooter onClickPrev={handlePrevClick} />}
      >
        <ScreenTitle
          title="Permanent address"
          subtitle="The address in&nbsp;the&nbsp;customer’s legal documents"
          margin="0 0 24px 0"
        />
        <FormProvider {...methods}>
          <Controller
            name="sameAsCurrent"
            control={control}
            render={(fieldProps) => (
              <Checkbox
                {...fieldProps}
                field={{
                  ...fieldProps.field,
                  onChange: (event, params) => {
                    fieldProps.field.onChange(event, params);
                    handleSameAsCurrentChange(params.checked);
                  },
                }}
              >
                Same as the residential address
              </Checkbox>
            )}
          />

          <AddressSuggestion
            fieldNames={fieldNames}
            disabled={sameAsCurrentValue}
          />
          <Controller
            name="streetAndHouse"
            control={control}
            rules={{
              required: 'Enter customer’s house number and street name',
              validate: combineValidators(
                validators.maxLength({ maxLength: 150 }),
                validators.filipinoAddress()
              ),
            }}
            render={(fieldProps) => (
              <Input
                label={STREET_HOUSE_FIELD_NAME}
                disabled={sameAsCurrentValue}
                // chrome ignores autocomplete=off for fields with common names(like province, password, etc)
                // -> we need to provide nonexistent autoComplete to turn it off
                autoComplete="permanent-address-house"
                {...fieldProps}
              />
            )}
          />
          <Controller
            name="unitAndFloor"
            control={control}
            rules={{
              validate: combineValidators(
                validators.maxLength({ maxLength: 150 }),
                validators.filipinoAddress()
              ),
            }}
            render={(fieldProps) => (
              <Input
                label={UNIT_FLOOR_FIELD_NAME}
                disabled={sameAsCurrentValue}
                autoComplete="permanent-address-apartment"
                {...fieldProps}
              />
            )}
          />
        </FormProvider>
      </Screen>
    </form>
  );
};
