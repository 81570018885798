import type { BonusApplicationInfo } from '~entities/agent-incentive';

import { formatPrice } from '~shared/lib/format-price';
import { plural } from '~shared/lib/plural';

import { Row } from '@breeze-platform-ui/layout';

import styles from './applications-info.module.css';

export const ApplicationsInfo = ({
  applicationInfo,
  applicationTypeText,
}: {
  applicationTypeText?: 'suspicious' | 'non-suspicious';
  applicationInfo: BonusApplicationInfo;
}) => {
  return (
    <Row alignMain="between" className={styles.text}>
      <span>
        <span className={styles.count}>{applicationInfo.count}</span>&nbsp;
        {applicationTypeText ? <>{applicationTypeText}&nbsp;</> : <></>}
        {plural(applicationInfo.count, 'application', 'applications')}
      </span>
      <span>{formatPrice(applicationInfo.amount)}</span>
    </Row>
  );
};
