import { CmsError } from './cms-error';
import type {
  CmsResponse,
  ErrorResponse,
  GetListResponse,
  GetOneResponse,
} from './cms-response';
import { isErrorResponse } from './cms-response';

type GetOneParams = {
  id: string;
  resource: string;
};

type GetListParams = {
  resource: string;
};

type RequestParams = {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  resource: string;
  id?: string;
};

type ConstructorParams = {
  host: string;
  apiToken?: string;
  prefix?: string;
};

export class CmsClient {
  readonly host: string;
  readonly apiToken?: string;
  readonly prefix: string = 'cms/api/v1/resources';

  constructor(params: ConstructorParams) {
    this.host = params.host;
    this.apiToken = params.apiToken;

    if (params.prefix) {
      this.prefix = params.prefix;
    }
  }

  async getList<T>(params: GetListParams): Promise<GetListResponse<T>> {
    return this.makeRequest<GetListResponse<T>>({
      method: 'GET',
      ...params,
    });
  }

  async getOne<T>(params: GetOneParams): Promise<T> {
    return this.makeRequest<GetOneResponse<T>>({
      method: 'GET',
      ...params,
    }).then((result) => result.data);
  }

  isErrorResponse(response: CmsResponse<any>): response is ErrorResponse {
    return isErrorResponse(response);
  }

  processResponse(response: CmsResponse<any>) {
    if (this.isErrorResponse(response)) {
      throw new CmsError(response.error);
    }

    return response;
  }

  async makeRequest<T>(params: RequestParams): Promise<T> {
    const { method, resource, id } = params;

    const url = `${this.host}/${this.prefix}/${resource}${id ? `/${id}` : ''}`;

    const headers = new Headers();

    if (this.apiToken) {
      headers.append('Authorization', `Bearer ${this.apiToken}`);
    }

    return fetch(url, { headers, method, credentials: 'include' })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`${response.status} ${response.statusText}`);
        }

        return response.json();
      })
      .then((jsonResponse) => this.processResponse(jsonResponse) as T);
  }
}
