import { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCustomerAnalytics } from '~app/providers/analytics-provider';

import type { PreCalcNavigationState } from '~pages/loan-calculator';

import { recoverLoanCalculator } from '~widgets/loan-calculator';
import { PreApprovedQrReader } from '~widgets/pre-approved-qr-reader';

import { SelfAppPreApprovedIncorrect } from '~features/create-application-result';

import {
  useGetApplicationByIdQuery,
  isActiveApplication,
  isPreApproved,
} from '~entities/application';
import { useSearchOfferByPhoneMutation } from '~entities/offer';
import { selectedPosAtom } from '~entities/pos';

import Button from '@breeze-platform-ui/button';
import Loader from '@breeze-platform-ui/loader';
import { useNotifications } from '@pfa/front-notifications';
import { useAtomValue } from 'jotai';

import { HasQRPopup } from './has-qr-popup';
import styles from './start-loan-calculation.module.css';

export const StartLoanCalculation = () => {
  const navigate = useNavigate();
  const notifications = useNotifications();
  const analytics = useCustomerAnalytics();
  const [hasQrSheetOpened, setHasQrSheetOpened] = useState(false);
  const [qrReaderShown, setQrReaderShown] = useState(false);
  const [preApprovedApplication, setPreApprovedApplication] = useState<{
    applicationId: string;
    phoneNumber: string;
  }>();
  const [selfAppError, setSelfAppError] = useState<boolean>();
  const [isSearchingOffer, setIsSearchingOffer] = useState(false);
  const selectedPos = useAtomValue(selectedPosAtom);

  const { mutateAsync: searchOffer } = useSearchOfferByPhoneMutation();
  const { isFetching: isApplicationFetching } = useGetApplicationByIdQuery(
    preApprovedApplication?.applicationId as string,
    {
      enabled: !!preApprovedApplication?.applicationId && !!selectedPos,
      onSuccess(application) {
        const { requestedProduct, posId } = application;
        if (isPreApproved(application)) {
          const state: PreCalcNavigationState = { preApprovedApplication };
          if (requestedProduct && posId === selectedPos?.id) {
            state.loanCalculator = recoverLoanCalculator(application);
          }
          setIsSearchingOffer(true);
          return searchOffer(application.person.mobilePhone)
            .then((response) => {
              if (response.status === 'SUCCESS') {
                state.forcedOffer = {
                  offer: response,
                  phone: application.person.mobilePhone,
                };
              }
            })
            .finally(() => {
              setIsSearchingOffer(false);
              navigate('/loan-calculator', { state });
            });
        }
        if (isActiveApplication(application) && selectedPos?.id === posId) {
          return navigate(`/client-initiation`, {
            state: { clientPhonePrefilled: application.person.mobilePhone },
          });
        }
        setSelfAppError(true);
      },
      onError() {
        notifications.error('Failed to fetch application details');
      },
    }
  );

  useEffect(() => {
    if (preApprovedApplication?.applicationId) {
      analytics.trackPreApprovedQrScanned({
        applicationId: preApprovedApplication.applicationId,
      });
    }
  }, [preApprovedApplication?.applicationId, analytics]);

  const handleNoQr = () => {
    navigate('/loan-calculator');
  };

  const handleYesClick = () => {
    setHasQrSheetOpened(false);
    setQrReaderShown(true);
  };

  const handleScanSuccess = useCallback(
    (values: { applicationId: string; phoneNumber: string }) => {
      setPreApprovedApplication(values);
      setQrReaderShown(false);
    },
    []
  );

  const handleScanClose = useCallback(() => {
    setQrReaderShown(false);
  }, []);

  const isCheckingPreApprovedApplication =
    isApplicationFetching || isSearchingOffer;

  return (
    <>
      <Button
        type="button"
        size="l"
        wide
        onClick={() => setHasQrSheetOpened(true)}
      >
        Calculate a&nbsp;new loan
      </Button>
      <HasQRPopup
        isOpened={hasQrSheetOpened}
        onClose={() => setHasQrSheetOpened(false)}
        onNoClick={handleNoQr}
        onYesClick={handleYesClick}
      />
      {qrReaderShown && (
        <PreApprovedQrReader
          onClose={handleScanClose}
          onSuccess={handleScanSuccess}
          onCanNotScanQr={handleNoQr}
        />
      )}
      {isCheckingPreApprovedApplication && (
        <div className={styles.container}>
          <Loader centered overlay />
        </div>
      )}
      {selfAppError && (
        <div className={styles.container}>
          <SelfAppPreApprovedIncorrect
            onClose={() => {
              setSelfAppError(false);
              setPreApprovedApplication(undefined);
            }}
          />
        </div>
      )}
    </>
  );
};
