import { useState } from 'react';

import ContextMenu from '@breeze-platform-ui/contextMenu';
import { Int24MoreHorizontal } from '@breeze-platform-ui/iconsPack';

type Props = {
  onEdit: () => void;
  onDelete: () => void;
};
export const OrderItemActions = ({ onEdit, onDelete }: Props) => {
  const [actionsOpened, setActionsOpened] = useState(false);
  const items = [
    {
      title: 'Edit',
      onClick: onEdit,
    },
    {
      title: 'Delete',
      onClick: onDelete,
    },
  ];
  return (
    <ContextMenu
      items={items}
      itemSize="l"
      closeOnPopoverClick
      opened={actionsOpened}
      onClickOutside={() => setActionsOpened(false)}
    >
      <Int24MoreHorizontal
        aria-label="actions"
        cursor="pointer"
        theme="blue"
        onClick={() => setActionsOpened((opened) => !opened)}
      />
    </ContextMenu>
  );
};
