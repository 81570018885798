import styles from './popup-title.module.css';

type Props = {
  title: string;
};

export const PopupTitle = ({ title }: Props) => (
  <h4 data-qa-type="popup-title" className={styles.title}>
    {title}
  </h4>
);
