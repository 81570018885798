interface ConstructorParams {
  status: number;
  name: string;
  message: string;
}

export class CmsError extends Error {
  readonly status: number;

  constructor(params: ConstructorParams) {
    super(params.message);
    this.name = params.name;
    this.status = params.status;
  }
}
