import { environment } from '~app/environment';

import { isAuthError, isHttpError } from '~shared/errors';
import {
  webRequestClient,
  type RequestParams,
  type ScpClient,
} from '~shared/scp-client';

import type { AgreementApi, ConfirmParams } from './agreement-api';
import {
  AttemptsLimitError,
  CodeConfirmationError,
  isSessionExpiredError,
} from './errors';

interface ConstructorParams {
  client: ScpClient;
  host: string;
}

interface GatewayRequestResponse {
  sessionId: string;
}

export class GatewayAgreementApi implements AgreementApi {
  private client: ScpClient;
  private host: string;

  private session: string | null = null;

  constructor(params: ConstructorParams) {
    this.client = params.client;
    this.host = params.host;
  }

  async send() {
    this.session = null;

    const response = await this.makeRequest<GatewayRequestResponse>({
      method: 'request',
      payload: {},
    });

    this.session = response.sessionId;
  }

  async confirm(params: ConfirmParams) {
    this.ensureSession();

    try {
      await this.makeRequest({
        method: 'terminate',
        payload: {
          sessionId: this.session,
          code: params.code,
        },
      });

      this.session = null;
    } catch (error: any) {
      if (isAuthError(error) && error.code === 'SIGN_LIMIT_REACHED') {
        throw new AttemptsLimitError(error.message);
      }
      if (isHttpError(error) && error.status === 400) {
        throw new CodeConfirmationError(error.message);
      }
      throw error;
    }
  }

  private ensureSession() {
    if (this.session === null) {
      // eslint-disable-next-line no-console
      throw new Error('Agreement signing process has not been initialized');
    }
  }

  private makeRequest<T = unknown>(
    params: Partial<RequestParams> & { method: string }
  ) {
    return this.client
      .call<T>({
        host: this.host,
        version: 'v1',
        domain: 'docsign',
        service: 'sign-session',
        withCredentials: true,
        withLeadingDomain: true,
        ...params,
      })
      .then((response) => response.payload)
      .catch((error) => {
        if (isSessionExpiredError(error)) {
          this.session = null;
        }

        throw error;
      });
  }
}

export const gatewayAgreementApi = new GatewayAgreementApi({
  client: webRequestClient,
  host: environment.API_HOST,
});
