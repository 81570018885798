import { format } from 'date-fns';

import type { WorkDay } from '../schedule';

export function shiftTimeToStr(shiftTime: NonNullable<WorkDay['shiftTime']>) {
  const startTime = format(shiftTime.startAt, 'h:mm a');
  const endTime = format(shiftTime.endAt, 'h:mm a');

  return (
    <span>
      {startTime}&nbsp;–&nbsp;{endTime}
    </span>
  );
}
