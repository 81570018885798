import type {
  PersonFormatter,
  PersonScreensMapping,
  PersonRecovery,
} from '~entities/person';
import {
  recoverPersonValuesByMapping,
  formatPersonValuesByMapping,
} from '~entities/person/lib';

import type { FormValues } from '../types';

const mapping: PersonScreensMapping<FormValues> = {
  email: { path: ['email'] },
  citizenship: { path: ['citizenship'] },
  gender: { path: ['gender'] },
  placeOfBirth: { path: ['placeOfBirth'] },
  civilStatus: { path: ['civilStatus'] },
  preferredLanguages: { path: ['preferredLanguages'] },
  nickname: { path: ['name', 'preferredName'] },
};

export const formatPersonalDetails: PersonFormatter<FormValues> = (
  person,
  screenValues
) => {
  return formatPersonValuesByMapping(person, screenValues, mapping);
};

export const recoverPersonalDetails: PersonRecovery<FormValues> = (person) => {
  return recoverPersonValuesByMapping(person, mapping);
};
