type Ratio = {
  xRatio: number;
  yRatio: number;
};

// This calculations are only for object-fit === contain

export function getVideoToViewportRatio(elem: HTMLVideoElement): Ratio {
  const bbox = elem.getBoundingClientRect();

  const objectRatio = elem.videoHeight / elem.videoWidth;
  const bboxRatio = bbox.height / bbox.width;

  const outWidth =
    bboxRatio > objectRatio ? bbox.width : bbox.height / objectRatio;
  const outHeight =
    bboxRatio > objectRatio ? bbox.width * objectRatio : bbox.height;

  return {
    xRatio: elem.videoWidth / outWidth,
    yRatio: elem.videoHeight / outHeight,
  };
}
