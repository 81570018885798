import type { Pos } from '~entities/pos';

export type ScheduleType = 'WORK' | 'LEAVE';

export enum WorkDayMode {
  FULL_DAY = 'FD',
  HALF_DAY = 'HD',
  HALF_DAY_LEAVE = 'HDL',
  OVERTIME_WORK = 'RDOT',
}
export enum LeaveReason {
  REST_DAY = 'RD',
  PTO = 'PTO',
  CTO = 'CTO',
  APPROVED_ABSENCE = 'ABSENT',
  LWOP = 'LWOP',
  SUSPEND = 'SUSPENDED',
  AWOL = 'AWOL',
  MATERNITY_LEAVE = 'MAT',
  EXTENDED_MATERNITY_LEAVE = 'EXT_MAT',
}

export type WorkDay = {
  type: 'WORK';
  posList: Pick<Pos, 'id' | 'name'>[];
  mode: WorkDayMode;
  shiftTime?: {
    startAt: string;
    endAt: string;
  };
};

export type LeaveDay = {
  type: 'LEAVE';
  posList?: Pos[];
  reason: LeaveReason;
};

export type DailySchedule = LeaveDay | WorkDay;
