/* eslint-disable no-param-reassign */
export function drawCanvas(canvas: HTMLCanvasElement, img: ImageBitmap) {
  canvas.width = Number(getComputedStyle(canvas).width.split('px')[0]);
  canvas.height = Number(getComputedStyle(canvas).height.split('px')[0]);
  const ratio = Math.min(canvas.width / img.width, canvas.height / img.height);
  const x = (canvas.width - img.width * ratio) / 2;
  const y = (canvas.height - img.height * ratio) / 2;
  canvas.getContext('2d')?.clearRect(0, 0, canvas.width, canvas.height);
  canvas
    .getContext('2d')
    ?.drawImage(
      img,
      0,
      0,
      img.width,
      img.height,
      x,
      y,
      img.width * ratio,
      img.height * ratio
    );
}
/* eslint-enable no-param-reassign */
