import { useNavigate, useParams } from 'react-router-dom';

import { DownpaymentConfirmation } from '~widgets/downpayment-confirmation';

export const DownpaymentConfirmationPage = () => {
  const navigate = useNavigate();
  const { id = '' } = useParams();

  return (
    <DownpaymentConfirmation
      applicationId={id}
      onConfirmSuccess={() =>
        navigate(`/applications/${id}`, { replace: true })
      }
      onCancel={() => navigate(`/applications/${id}`)}
    />
  );
};
