import type { WorkingStatus } from '../attendance';
import { AttendanceStatus } from '../attendance';

export function getMatchingWorkingStatus(
  idleStatus: Exclude<AttendanceStatus, WorkingStatus>
): WorkingStatus {
  switch (idleStatus) {
    case AttendanceStatus.CheckOut:
      return AttendanceStatus.CheckIn;
    case AttendanceStatus.BreakIn:
      return AttendanceStatus.BreakOut;
    case AttendanceStatus.LunchIn:
      return AttendanceStatus.LunchOut;
    case AttendanceStatus.CommuteIn:
      return AttendanceStatus.CommuteOut;
  }
}
