import { useDi } from '~app/providers/di-provider';

import { selectedPosAtom } from '~entities/pos';

import { isServerError } from '~shared/errors';
import { useQueuedMutation } from '~shared/react-query-helpers';

import type { UseQueryOptions } from '@tanstack/react-query';
import {
  useQuery,
  type UseMutationOptions,
  useQueryClient,
  useMutation,
} from '@tanstack/react-query';
import { useAtomValue } from 'jotai';

import type {
  CancelApplicationPayload,
  UpdateManufacturerIdsPayload,
  ConfirmProductParams,
} from '../api';
import type {
  Application,
  ApplicationDetails,
  ApplicationListQueryResponse,
} from '../application';
import {
  isWaitingForDecision,
  isMobileAppRequired,
  isReviewingCustomer,
  isDownpaymentPending,
  updateFinishedApplicationQueryData,
} from '../lib';

export class ProcessStatusError extends Error {
  name = 'PROCESS_STATUS_ERROR' as const;

  public constructor() {
    super(`Incorrect application process status`);
  }
}

export const useGetApplicationListQuery = (
  posId?: string,
  options?: UseQueryOptions<ApplicationListQueryResponse, Error>
) => {
  const { applicationApi } = useDi();
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: ['applications', 'list', posId],
    queryFn: async () => {
      if (!posId) {
        return { pending: [], list: [] };
      }
      const list = await applicationApi.getApplicationList(posId);
      const pending: ApplicationDetails[] = [];
      list.forEach((application) => {
        queryClient.setQueryData(['applications', application.id], application);
        if (isDownpaymentPending(application)) {
          pending.push(application);
        }
      });
      return { list, pending };
    },
    refetchOnMount: true,
    refetchInterval: 15000,
    retry: (count: number, error: Error) => {
      if (isServerError(error)) {
        return count < 2;
      }
      return false;
    },
    ...options,
  });
};

export const useGetApplicationQuery = (
  options?: Omit<
    UseQueryOptions<ApplicationDetails, Error>,
    'refetchOnWindowFocus' | 'retry'
  >
) => {
  const { applicationApi } = useDi();
  const selectedPos = useAtomValue(selectedPosAtom);
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: ['applications', 'authorized'],
    queryFn: async () => {
      const application = await applicationApi.getApplication();

      const applicationsList =
        queryClient.getQueryData<ApplicationListQueryResponse>([
          'applications',
          'list',
          selectedPos?.id,
        ]);
      if (
        applicationsList?.list.length &&
        !applicationsList.list.find(({ id }) => id === application.id)
      ) {
        queryClient.setQueryData<ApplicationListQueryResponse>(
          ['applications', 'list', selectedPos?.id],
          {
            list: [application, ...applicationsList.list],
            pending: applicationsList.pending,
          }
        );
      }

      return application;
    },
    refetchOnWindowFocus: true,
    retry: (count: number, error: Error) => {
      if (isServerError(error)) {
        return count < 2;
      }
      return false;
    },
    ...options,
  });
};

export const useGetApplicationByIdQuery = (
  id: string,
  options?: Omit<
    UseQueryOptions<ApplicationDetails, any>,
    'refetchInterval' | 'retry'
  >
) => {
  const { applicationApi } = useDi();
  return useQuery({
    queryKey: ['applications', id],
    queryFn: () => applicationApi.getApplicationById(id),
    refetchInterval: (data: ApplicationDetails | undefined) => {
      const shouldPollApplication =
        isWaitingForDecision(data) ||
        isMobileAppRequired(data) ||
        isReviewingCustomer(data);

      return shouldPollApplication ? 15000 : false;
    },
    retry: 2,
    ...options,
  });
};

export const useApplicationInitialMutation = () => {
  const { applicationApi } = useDi();
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['initialUpdateApplication'],
    mutationFn: async (application: Partial<Application>) => {
      await applicationApi.updateApplication(application);
      const applicationData = queryClient.getQueryData([
        'applications',
        'authorized',
      ]);
      if (applicationData) {
        queryClient.setQueryData(['applications', 'authorized'], {
          ...applicationData,
          ...application,
        });
      }
    },

    retry: 3,
  });
};

export const useUpdateApplicationMutation = (
  options?: UseMutationOptions<unknown, unknown, Partial<Application>>
) => {
  const { applicationApi } = useDi();

  return useQueuedMutation({
    mutationKey: ['updateApplication'],
    mutationFn: (application: Partial<Application>) =>
      applicationApi.updateApplication(application),
    ...options,
  });
};

export const useCancelApplicationMutation = (
  options?: UseMutationOptions<any, Error, CancelApplicationPayload>
) => {
  const { applicationApi } = useDi();

  return useMutation({
    mutationKey: ['cancelApplication'],
    mutationFn: (payload: CancelApplicationPayload) =>
      applicationApi.cancelApplication(payload),
    ...options,
  });
};

export const useUpdateManufacturerIdsMutation = (
  options?: UseMutationOptions<
    any,
    Error,
    UpdateManufacturerIdsPayload & { applicationId: string }
  >
) => {
  const { applicationApi } = useDi();
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['updateManufacturerIds'],
    mutationFn: async (
      payload: UpdateManufacturerIdsPayload & { applicationId: string }
    ) => {
      await applicationApi.updateManufacturerIds({ items: payload.items });
      queryClient.invalidateQueries(['applications', payload.applicationId]);
    },
    ...options,
  });
};

export const useUpdateMetadataMutation = (
  options?: UseMutationOptions<
    any,
    Error,
    { metaData: Application['metaData']; applicationId: string }
  >
) => {
  const { applicationApi } = useDi();
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['updateApplicationMetadata'],
    mutationFn: async (payload: {
      metaData: Application['metaData'];
      applicationId: string;
    }) => {
      await applicationApi.updateMetadata({ metaData: payload.metaData });
      const application = queryClient.getQueryData<ApplicationDetails>([
        'applications',
        payload.applicationId,
      ]);
      queryClient.setQueryData(['applications', payload.applicationId], {
        ...application,
        metaData: payload.metaData,
      });
    },
    ...options,
  });
};

export const useConfirmDownpaymentMutation = (
  options?: UseMutationOptions<any, Error, string>
) => {
  const { applicationApi } = useDi();
  const selectedPos = useAtomValue(selectedPosAtom);
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['confirmDownpayment'],
    mutationFn: async (applicationId: string) => {
      const result = await applicationApi.confirmDownpayment();
      const updatedApplication: Partial<ApplicationDetails> = {
        applicationStatus: 'APPROVED',
        downPaymentVerified: true,
        offerSigned: true,
        applicationStatusChangedUTC: new Date().toISOString(),
        lastUpdatedUTC: new Date().toISOString(),
      };
      updateFinishedApplicationQueryData(
        queryClient,
        updatedApplication,
        applicationId,
        selectedPos?.id
      );

      return result;
    },
    ...options,
  });
};

export const useConfirmProductMutation = (id: string) => {
  const { applicationApi } = useDi();
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['confirmProduct'],
    mutationFn: (params: ConfirmProductParams) =>
      applicationApi.confirmProduct(params),
    onSuccess() {
      // Authorized application query cache should be reset because approvedProduct is added after product confirmation
      queryClient.resetQueries(['applications', 'authorized']);
      queryClient.resetQueries(['applications', id]);
    },
    retry: 3,
  });
};
