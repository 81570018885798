import { useNavigate } from 'react-router-dom';

import { DownpaymentConfirmationPage } from '~pages/downpayment-confirmation';

import {
  ClientConfirmationGuardHOC,
  ActiveApplicationGuardHOC,
} from '~features/auth/guard';
import { CheckAttendanceGuardHOC } from '~features/check-attendance';
import { SelectedPosGuardHOC } from '~features/select-pos';

export default () => {
  const navigate = useNavigate();

  return (
    <CheckAttendanceGuardHOC onCancel={() => navigate('../')}>
      <SelectedPosGuardHOC>
        <ClientConfirmationGuardHOC>
          <ActiveApplicationGuardHOC onAccessDenied={() => navigate('../')}>
            <DownpaymentConfirmationPage />
          </ActiveApplicationGuardHOC>
        </ClientConfirmationGuardHOC>
      </SelectedPosGuardHOC>
    </CheckAttendanceGuardHOC>
  );
};
