import type { AbstractOption } from '~shared/types/abstract-option';

const options = [
  {
    value: 'Can afford to buy real estate or a car',
    title: 'Can afford to buy real estate or a car',
  },
  {
    value: 'Can afford high-value goods but not to buy real estate or a car',
    title: 'Can afford high-value goods but not to buy real estate or a car',
  },
  {
    value: 'Can afford basic needs but not for high-value goods',
    title: 'Can afford basic needs but not for high-value goods',
  },
  {
    value: 'Has trouble buying food and basic needs',
    title: 'Has trouble buying food and basic needs',
  },
] as const;

export type FinancialSituationValue = typeof options[number]['value'];

export const financialSituationOptions =
  options as unknown as AbstractOption<FinancialSituationValue>[];
