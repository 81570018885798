import pick from '@tinkoff/utils/object/pick';

import type { AgentNewsDetails, AgentNewsInfo } from '../agent-news';
import type { AgentNewsDetailsDto, AgentNewsInfoDto } from '../api';
import {
  isMediaComponent,
  isCheckboxSurveyQuestion,
  isRadioSurveyQuestion,
} from '../api';

export const getSurveyControlOptions = (options: string): string[] => {
  return options.split('\n');
};

export const getItemDetailsFromDto = (
  dtoItem: AgentNewsDetailsDto
): AgentNewsDetails => {
  return {
    id: dtoItem.id,
    ...pick(
      ['importance', 'publishedAt', 'title', 'isRead', 'surveyDueDate'],
      dtoItem.attributes
    ),
    article: dtoItem.attributes.article.map((item) => {
      if (isMediaComponent(item)) {
        return {
          type: 'media',
          content: item.content,
        };
      }
      return {
        type: 'text',
        content: item.content,
      };
    }),
    surveyQuestions: dtoItem.attributes.surveyQuestions.map((item) => {
      if (isCheckboxSurveyQuestion(item)) {
        return {
          type: 'checkbox-group',
          question: item.title,
          options: getSurveyControlOptions(item.options),
        };
      }
      if (isRadioSurveyQuestion(item)) {
        return {
          type: 'radio-group',
          question: item.title,
          options: getSurveyControlOptions(item.options),
        };
      }
      return {
        type: 'text-area',
        question: item.title,
        placeholder: item.placeholder,
      };
    }),
  };
};

export const getItemInfoFromDto = (
  dtoItem: AgentNewsInfoDto
): AgentNewsInfo => ({
  id: dtoItem.id,
  ...dtoItem.attributes,
});
