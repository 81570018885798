import { selectedPosAtom } from '~entities/pos';

import {
  ToggleSwitch,
  type ToggleSwitchOption,
} from '~shared/ui/toggle-switch';

import { Col } from '@breeze-platform-ui/layout';
import { useAtomValue } from 'jotai';

import styles from './with-product-type.module.css';

import { isZeroRate } from '../../lib';
import type { ProductType } from '../../product';

type Props = {
  type: ProductType;
  onProductTypeChange: (type: ProductType) => void;
  children: React.ReactNode;
};

const loanTypeOptions: ToggleSwitchOption[] = [
  {
    title: '0% interest loan',
    value: 'LOAN_ZERO_RATE',
    qaLabel: 'zero-rate-option',
  },
  { title: 'Product loan', value: 'LOAN', qaLabel: 'product-loan-option' },
];

export const WithProductType: React.FC<Props> = ({
  type,
  onProductTypeChange,
  children,
}) => {
  const hasZeroRate = useAtomValue(selectedPosAtom)?.hasZeroRateProducts;

  const productTypeValue = hasZeroRate || !isZeroRate(type) ? type : 'LOAN';

  return (
    <Col alignCross="stretch" gaps={24}>
      {/* without div filter component collapses */}
      {hasZeroRate && (
        <div className={styles.container}>
          <ToggleSwitch
            value={productTypeValue}
            options={loanTypeOptions}
            onChangeValue={(value) => {
              if (value !== null) {
                onProductTypeChange?.(value as ProductType);
              }
            }}
          />
        </div>
      )}
      {children}
    </Col>
  );
};
