import { useNavigate } from 'react-router-dom';

import type { ApplicationProcessStatus } from '~entities/application';

import { phMoneyProps } from '~shared/constants/ph-money-props';

import Button from '@breeze-platform-ui/button';
import Island from '@breeze-platform-ui/island';
import Money from '@breeze-platform-ui/money';

import { ProcessStatusTitle } from '../process-status-title/process-status-title';

type Props = {
  processStatus: ApplicationProcessStatus;
  downPayment: number;
};

export const ActionRequired = ({ downPayment, processStatus }: Props) => {
  const navigate = useNavigate();
  return (
    <Island
      theme="shadow"
      text={
        <>
          The agreement is&nbsp;signed! Now the&nbsp;client needs to&nbsp;make
          the&nbsp;down payment of&nbsp;
          <Money {...phMoneyProps} value={downPayment} />
        </>
      }
      title={<ProcessStatusTitle processStatus={processStatus} />}
      footerBody={
        <Button
          wide
          size="m"
          onClick={() => {
            navigate('downpayment-confirmation');
          }}
        >
          Show details
        </Button>
      }
    />
  );
};
