import type { ApplicationProcessStatus } from '~entities/application';
import { getCancelDate } from '~entities/application';

import { NotificationInline } from '@breeze-platform-ui/notification';

type Props = {
  processStatus: ApplicationProcessStatus;
};

export const Cancelled = ({ processStatus }: Props) => {
  return (
    <NotificationInline
      type="info"
      timer={false}
      showClose={false}
      withIcon={false}
      title={`${processStatus.message} on ${getCancelDate(
        processStatus.dateUTC
      )}`}
    />
  );
};
