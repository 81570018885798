import type { ApplicationDetails } from '~entities/application';
import { getApplicationProcessStatus } from '~entities/application';

import { nonNullableValue } from '~shared/types/non-nullable-value';

import { ActionRequired } from './action-required';
import { Approved } from './approved';
import { Cancelled } from './cancelled';
import { Finalized } from './finalized';
import { MobileAppRequired } from './mobile-app-required/mobile-app-required';
import { Onboarding } from './onboarding';
import { PreApproved } from './pre-approved';
import { Rejected } from './rejected';
import { ReviewingCustomer } from './reviewing-customer';
import { SelfAppMobileOnboarding } from './self-app-mobile-onboarding';
import { WaitingForDecision } from './waiting-for-decision/waiting-for-decision';

type Props = {
  applicationDetails: ApplicationDetails;
};

export const ApplicationProcessStatusDetails = ({
  applicationDetails,
}: Props) => {
  const processStatus = getApplicationProcessStatus(applicationDetails);

  switch (processStatus.status) {
    case 'waiting':
      return <WaitingForDecision processStatus={processStatus} />;
    case 'mobileApp':
      return <MobileAppRequired processStatus={processStatus} />;
    case 'reviewing':
      return <ReviewingCustomer processStatus={processStatus} />;
    case 'onboarding':
      return (
        <Onboarding
          processStatus={processStatus}
          applicationDetails={applicationDetails}
        />
      );
    case 'selfAppMobileOnboarding':
      return <SelfAppMobileOnboarding processStatus={processStatus} />;
    case 'preApproved':
      return (
        <PreApproved
          processStatus={processStatus}
          applicationDetails={applicationDetails}
        />
      );
    case 'approved':
      return (
        <Approved
          processStatus={processStatus}
          agreementId={applicationDetails.agreementId}
        />
      );
    case 'rejected':
      return <Rejected processStatus={processStatus} />;
    case 'actionRequired':
      return (
        <ActionRequired
          processStatus={processStatus}
          downPayment={nonNullableValue(
            applicationDetails.approvedProduct?.downPayment
          )}
        />
      );
    case 'finalized':
      return <Finalized processStatus={processStatus} />;
    case 'cancelled':
      return <Cancelled processStatus={processStatus} />;
    default:
      return <></>;
  }
};
