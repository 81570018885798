import { adaptTramvaiHttpClientErrors } from '~shared/errors';

import request from '@tinkoff/request-core';
import type { Request } from '@tinkoff/request-core';
import type { Plugin } from '@tinkoff/request-core';
import type { ContextState } from '@tinkoff/request-core';
import http from '@tinkoff/request-plugin-protocol-http';

export type HttpClientRequest = Request;

export type HttpClient = {
  call<T = unknown>(params: HttpClientRequest): Promise<T>;
};

type ErrorFormatter = (state: ContextState) => Error | undefined;

function formatErrorPlugin(formatter: ErrorFormatter): Plugin {
  return {
    error: (context, next) => {
      const state = context.getState();
      const newError = formatter(state);

      if (newError) {
        return next({ error: newError });
      }

      return next();
    },
  };
}

const makeRequest = request([
  formatErrorPlugin(({ error }) => adaptTramvaiHttpClientErrors(error)),
  http(),
]);

export const httpClient: HttpClient = {
  call<T>(params: HttpClientRequest): Promise<T> {
    return makeRequest(params);
  },
};
