import type { ReactNode } from 'react';

import { Warning } from '~shared/ui/warning';

import { Row } from '@breeze-platform-ui/layout';
import { TuiIconTdsIllustrationsCommonDrawerEmptyLight } from '@tui-react/proprietary-icons';

type Props = {
  button: ReactNode;
};
export const EmptyList = ({ button }: Props) => {
  return (
    <Warning
      title="No active applications"
      icon={
        <TuiIconTdsIllustrationsCommonDrawerEmptyLight iconSize={180 as any} />
      }
      footerContent={
        <Row alignMain="center">
          <div style={{ maxWidth: '190px' }}>{button}</div>
        </Row>
      }
    />
  );
};
