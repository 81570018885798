import type { ApplicationProcessStatus } from '~entities/application';

import Island from '@breeze-platform-ui/island';

import { ReviewingTime } from './reviewing-time';

import { ProcessStatusTitle } from '../../process-status-title/process-status-title';

type Props = {
  processStatus: ApplicationProcessStatus;
};

export const ReviewingCustomer = ({ processStatus }: Props) => {
  return (
    <Island
      theme="shadow"
      text="We need time for&nbsp;an&nbsp;additional review. It usually takes a&nbsp;few minutes"
      title={<ProcessStatusTitle processStatus={processStatus} />}
      picture={<ReviewingTime statusUpdatedUTC={processStatus.dateUTC} />}
    />
  );
};
