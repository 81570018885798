import React, { createContext } from 'react';

type PortalConfig = {
  customerServicePhone: string;
};
const PortalConfigContext = createContext<null | PortalConfig>(null);

interface Props {
  portalConfig: PortalConfig;
}

export const PortalConfigProvider: React.FC<React.PropsWithChildren<Props>> = (
  props
) => {
  const { portalConfig, children } = props;
  return (
    <PortalConfigContext.Provider value={portalConfig}>
      {children}
    </PortalConfigContext.Provider>
  );
};

export const usePortalConfig = (): PortalConfig => {
  const maybePortalConfig = React.useContext(PortalConfigContext);

  if (maybePortalConfig === null) {
    throw new Error('usePortalConfig must be used within a AnalyticsProvider');
  }

  return maybePortalConfig;
};
