import { environment } from '~app/environment';

import type { HttpClient } from '~shared/http-client';
import { httpClient } from '~shared/http-client';
import {
  webRequestClient,
  type RequestParams,
  type ScpClient,
} from '~shared/scp-client';

import type {
  AttachAttendanceFilePayload,
  AttendanceApi,
  AttendanceLogPayload,
  AttendanceLogResponse,
  AttendanceStatusResponse,
  SendExplanatoryNotePayload,
} from './attendance-api';

import type { AttendanceStatus } from '../attendance';

type Options = { host: string };

export class GatewayAttendanceApi implements AttendanceApi {
  scpClient: ScpClient;
  httpClient: HttpClient;
  host: string;

  constructor(
    scpClient: ScpClient,
    uploadClient: HttpClient,
    options: Options
  ) {
    this.scpClient = scpClient;
    this.httpClient = uploadClient;
    this.host = options.host;
  }

  async getAttendanceStatus(): Promise<AttendanceStatusResponse> {
    return this.makeRequest<{ status: AttendanceStatus }>({ method: 'status' });
  }

  async logAttendance(
    payload: AttendanceLogPayload
  ): Promise<AttendanceLogResponse> {
    return this.makeRequest({ method: 'log', payload });
  }

  async uploadPhoto({ file, uploadSelfieUrl }: AttachAttendanceFilePayload) {
    return this.httpClient.call<void>({
      httpMethod: 'PUT',
      url: uploadSelfieUrl,
      type: 'blob',
      headers: {
        'Content-Length': `${file.size}`,
      },
      payload: file,
    });
  }

  async sendExplanatoryNote(payload: SendExplanatoryNotePayload) {
    return this.makeRequest<void>({
      method: 'explanatory-note',
      payload,
    });
  }

  private makeRequest<T>(params: Partial<RequestParams> & { method: string }) {
    return this.scpClient
      .call<T>({
        host: this.host,
        version: 'v1',
        domain: 'attendance',
        service: 'attendance',
        withCredentials: true,
        withLeadingDomain: true,
        ...params,
      })
      .then((response) => response.payload);
  }
}

export const gatewayAttendanceApi = new GatewayAttendanceApi(
  webRequestClient,
  httpClient,
  {
    host: environment.API_HOST,
  }
);
