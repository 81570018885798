import type { Paths } from '~shared/types/paths';

import identity from '@tinkoff/utils/function/identity';
import isEmpty from '@tinkoff/utils/is/empty';
import isUndefined from '@tinkoff/utils/is/undefined';
import get from '@tinkoff/utils/object/path';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { dset } from 'dset';

type MappingItem<Entity> = {
  path: Paths<Entity>;
  recover?: Function;
  format?: Function;
};

export type RecoveryMapping<StepValues, Entity> = Record<
  keyof StepValues,
  MappingItem<Entity>
>;

export function recoverByMapping<T, Entity>(
  entity: Entity,
  mapping: RecoveryMapping<T, Entity>
): Partial<T> | undefined {
  const stepValues: Record<string, unknown> = {};

  for (const fieldName in mapping) {
    const { path, recover = identity } = mapping[fieldName];
    const value = get(path, entity);

    if (!isEmpty(value)) {
      const recoveredValue = recover(value);

      if (!isUndefined(recoveredValue)) {
        stepValues[fieldName] = recover(value);
      }
    }
  }

  if (!isEmpty(stepValues)) {
    return stepValues as T;
  }
}

export function formatByMapping<
  StepValues extends Record<string, any>,
  Entity extends Record<string, any>
>(
  entity: Entity,
  stepValues: Partial<StepValues> = {},
  mapping: RecoveryMapping<StepValues, Entity>
): Entity {
  for (const fieldName in mapping) {
    const fieldValue = stepValues[fieldName];

    if (!isEmpty(fieldValue)) {
      const { path, format = identity } = mapping[fieldName];
      const formattedValue = format(fieldValue);

      if (!isUndefined(formattedValue)) {
        dset(entity, path, formattedValue);
      }
    }
  }

  return entity;
}
