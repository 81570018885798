import { environment } from '~app/environment';

import type { OrderItemCategory } from '~entities/order-item';

import {
  webRequestClient,
  type RequestParams,
  type ScpClient,
} from '~shared/scp-client';

import type {
  ProductApi,
  ProductRequestParams,
  ZeroRateItemsRequestParams,
} from './product-api';

import type { ZeroRateItem } from '../product';
import type { Product } from '../product';

type Options = { host: string };

export class GatewayProductApi implements ProductApi {
  scpClient: ScpClient;
  host: string;

  constructor(scpClient: ScpClient, options: Options) {
    this.scpClient = scpClient;
    this.host = options.host;
  }

  getProduct(params: ProductRequestParams): Promise<Product> {
    return this.makeRequest<Product>({
      version: 'v2',
      method: 'get-by-parameters',
      service: 'public/products',
      payload: params,
    });
  }

  getCategories(): Promise<OrderItemCategory[]> {
    return this.makeRequest({
      method: 'get-list',
      service: 'public/categories',
    });
  }

  getZeroRateItems(
    params: ZeroRateItemsRequestParams
  ): Promise<ZeroRateItem[]> {
    return this.makeRequest({
      service: 'public/products',
      method: 'zero-rate/get-items',
      payload: params,
    });
  }

  private makeRequest<T>(
    params: Partial<RequestParams> & { method: string; service: string }
  ) {
    return this.scpClient
      .call<T>({
        host: this.host,
        version: params.version || 'v1',
        domain: 'merchant-portal',
        withCredentials: true,
        withLeadingDomain: true,
        ...params,
      })
      .then((response) => response.payload);
  }
}

export const gatewayProductApi = new GatewayProductApi(webRequestClient, {
  host: environment.API_HOST,
});
