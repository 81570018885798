import { environment } from '~app/environment';

import { logger } from '~entities/logger';

import {
  webRequestClient,
  type RequestParams,
  type ScpClient,
} from '~shared/scp-client';

import type { AgreementApi, ConfirmParams } from './agreement-api';
import {
  CodeConfirmationError,
  AttemptsLimitError,
  isSessionExpiredError,
} from './errors';

import { getSession, setSession } from '../lib';

interface ConstructorParams {
  client: ScpClient;
  host: string;
}

interface GatewayRequestResponse {
  sessionId: string | null;
}

type GatewayConfirmResponse = {
  status: 'WRONG_ANSWER' | 'FAILED' | 'SUCCESS';
  reason: string;
};

export class ConfirmationServiceApi implements AgreementApi {
  private client: ScpClient;
  private host: string;
  private session: string | null = null;
  private idempotencyKey: string | null = null;

  constructor(params: ConstructorParams) {
    this.client = params.client;
    this.host = params.host;
  }

  private async createAndInitiate(): Promise<GatewayRequestResponse> {
    this.session = null;

    return this.makeRequest<GatewayRequestResponse>({
      method: 'create_and_initiate_order',
      payload: {
        orderType: 'POS_AGREEMENT',
      },
      headers: {
        'x-idempotency-key': this.idempotencyKey,
      },
    });
  }

  async confirm(params: ConfirmParams) {
    this.restoreSession(params.applicationId);
    if (!this.session) {
      throw new Error('Agreement signing process has not been initialized');
    }
    const response = await this.makeRequest<GatewayConfirmResponse>({
      method: 'confirm_order',
      payload: {
        sessionId: this.session,
        answer: {
          OTP: params.code,
        },
      },
    });

    if (response.status === 'WRONG_ANSWER') {
      logger('agreement-confirmation').error(
        'Agreement confirmation wrong code'
      );
      throw new CodeConfirmationError(response.reason);
    }

    if (response.status === 'FAILED') {
      logger('agreement-confirmation').error('Agreement confirmation failed');
      this.resetSession(params.applicationId);
      throw new AttemptsLimitError(response.reason);
    }

    this.resetSession(params.applicationId);
  }

  private async resend() {
    await this.makeRequest({
      method: 'resend_order_confirmation',
      payload: {
        sessionId: this.session,
      },
    });
  }

  async send(applicationId: string) {
    this.restoreSession(applicationId);
    if (this.session) {
      try {
        this.resend();
      } catch (error) {
        this.resetSession(applicationId);
        throw error;
      }
    } else {
      const { sessionId } = await this.createAndInitiate();
      setSession({ applicationId, sessionId });
    }
  }

  private resetSession(applicationId: string) {
    setSession({ applicationId, sessionId: null });
    this.session = null;
  }

  private restoreSession(applicationId: string) {
    const session = getSession(applicationId);
    if (
      session.expiresAt &&
      new Date().getTime() < new Date(session.expiresAt).getTime()
    ) {
      this.session = session.sessionId;
    } else {
      this.resetSession(applicationId);
    }

    this.idempotencyKey = session.idempotencyKey;
  }

  private makeRequest<T = unknown>(params: Partial<RequestParams>) {
    return this.client
      .call<T>({
        url: `${this.host}/confirmation/merchant_portal/api/v1/${params.method}`,
        ...params,
        withCredentials: true,
      })
      .then((response) => response.payload)
      .catch((error) => {
        if (isSessionExpiredError(error)) {
          this.session = null;
        }

        throw error;
      });
  }
}

export const confirmationServiceApi = new ConfirmationServiceApi({
  client: webRequestClient,
  host: environment.API_HOST,
});
