import { environment } from '~app/environment';

import type { HttpClient, HttpClientRequest } from '~shared/http-client';
import { httpClient } from '~shared/http-client';

import type { DocumentApi } from './document-api';

interface ConstructorParams {
  client: HttpClient;
  host: string;
}

export interface GatewayRequestResponse {
  sessionId: string;
}

export class GatewayDocumentApi implements DocumentApi {
  private client: HttpClient;
  private host: string;

  constructor(params: ConstructorParams) {
    this.client = params.client;
    this.host = params.host;
  }

  async getFile() {
    return this.makeRequest<Blob>({
      method: 'get-file',
    });
  }

  private makeRequest<T = unknown>(params: { method: string }) {
    const requestParams: HttpClientRequest = {
      httpMethod: 'POST',
      responseType: 'blob',
      url: `${this.host}/person-documents/api/v1/portal/loan-agreement/${params.method}`,
      timeout: 60 * 1000,
      withCredentials: true,
    };

    return this.client.call<T>(requestParams);
  }
}

export const gatewayDocumentApi = new GatewayDocumentApi({
  client: httpClient,
  host: environment.API_HOST,
});
