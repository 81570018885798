import type {
  PersonFormatter,
  PersonRecovery,
  PersonScreensMapping,
} from '~entities/person/lib';
import {
  formatPersonValuesByMapping,
  recoverPersonValuesByMapping,
} from '~entities/person/lib';

import { formatYesNo, recoverYesNo } from '~shared/formatters';

import type { FormValues } from '../ui/additional-employment-details/additional-employment-details';

const mapping: PersonScreensMapping<FormValues> = {
  haveEmployees: {
    path: ['work', 'haveEmployees'],
    format: formatYesNo,
    recover: recoverYesNo,
  },
  companyName: { path: ['work', 'companyName'] },
  reference: { path: ['work', 'reference'] },
  referenceName: { path: ['work', 'referenceName'] },
  workPhone: { path: ['work', 'workPhone'] },
};

export const formatAdditionalEmploymentDetails: PersonFormatter<FormValues> = (
  application,
  screenValues
) => {
  const formattedApplication = formatPersonValuesByMapping(
    application,
    screenValues,
    mapping
  );
  const { work } = formattedApplication;

  return {
    ...formattedApplication,
    work: { ...work, noCompanyAddress: true },
  };
};

export const recoverAdditionalEmploymentDetails: PersonRecovery<FormValues> = (
  application
) => {
  return recoverPersonValuesByMapping(application, mapping);
};
