import React, { createContext } from 'react';

const OpeningTimeContext = createContext<null | number>(null);

interface Props {
  openingTime: number;
}

export const OpeningTimeProvider: React.FC<React.PropsWithChildren<Props>> = (
  props
) => {
  const { openingTime, children } = props;
  return (
    <OpeningTimeContext.Provider value={openingTime}>
      {children}
    </OpeningTimeContext.Provider>
  );
};

export const useOpeningTime = (): number => {
  const maybeOpeningTime = React.useContext(OpeningTimeContext);

  if (maybeOpeningTime === null) {
    throw new Error('useOpeningTime must be used within a OpeningTimeProvider');
  }

  return maybeOpeningTime;
};
