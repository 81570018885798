import type { PaymentPlan } from '~entities/product';

import { phMoneyProps } from '~shared/constants/ph-money-props';
import { plural } from '~shared/lib/plural';

import { Col } from '@breeze-platform-ui/layout';
import Money from '@breeze-platform-ui/money';
import Text from '@breeze-platform-ui/text';

const loanMoneyProps = { ...phMoneyProps, precision: 2 };

export function getPaymentPlanOptions(paymentPlans: PaymentPlan[]) {
  return paymentPlans.map(({ term, minMonthlyPayment, maxMonthlyPayment }) => {
    return {
      value: `${term}`,
      children: (
        <Col alignCross="stretch" grow={1}>
          <Text bold size={15} color="black">
            <Money {...loanMoneyProps} value={minMonthlyPayment} />
            {minMonthlyPayment !== maxMonthlyPayment && (
              <>
                &nbsp;–&nbsp;
                <Money {...loanMoneyProps} value={maxMonthlyPayment} />
              </>
            )}
          </Text>
          <Text size={13} color="black">
            x {term} {plural(term, 'month', 'months')}
          </Text>
        </Col>
      ),
    };
  });
}
