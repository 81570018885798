import type { OrderItem } from '~entities/order-item';
import type { ProductType } from '~entities/product';

export function getProductTypeFromItems(
  hasZeroRate: boolean,
  items?: OrderItem[]
): ProductType | null {
  const hasSelectedZeroRateItems = items?.length && items[0].zeroRateItemId;
  const hasSelectedLoanItems = items?.length && !items[0].zeroRateItemId;

  if (hasSelectedZeroRateItems && hasZeroRate) {
    return 'LOAN_ZERO_RATE';
  }

  if (hasSelectedLoanItems) {
    return 'LOAN';
  }

  return null;
}
