import type { AbstractOption } from '~shared/types/abstract-option';

const options = [
  {
    value: 'FULL_TIME',
    title: 'Full-time employee',
  },
  {
    value: 'PART_TIME',
    title: 'Part-time employee',
  },
  {
    value: 'DOMESTIC_WORKER',
    title: 'Domestic worker (Kasambahay)',
  },
  {
    value: 'CONTRACTUAL_FREELANCER',
    title: 'Contractual/Freelancer',
  },
  {
    value: 'OWN_BUSINESS',
    title: 'Business owner',
  },
  {
    value: 'OFW',
    title: 'Overseas Filipino Worker',
  },
  {
    value: 'UNEMPLOYED',
    title: 'Unemployed',
  },
] as const;

export type EmploymentStatusValue = typeof options[number]['value'];
export const employmentStatusOptions =
  options as unknown as AbstractOption<EmploymentStatusValue>[];

export type EmployedWithCompanyStatusValue = Exclude<
  EmploymentStatusValue,
  'UNEMPLOYED' | 'OFW'
>;
