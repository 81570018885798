import { type PropsWithChildren, useEffect, useRef } from 'react';

import { environment } from '~app/environment';
import { useDi } from '~app/providers/di-provider';
import { useTracking } from '~app/providers/tracking-provider';

import { isAuthorizedAtom, lastInteractionTimeAtom } from '~entities/auth';
import { getDeviceMetadata } from '~entities/device-metadata';

import { useAtom, useAtomValue } from 'jotai';

import { useUserInteractionTracking, countRefreshInterval } from '../lib';

const inactiveThreshold = environment.USER_INACTIVE_THRESHOLD * 60 * 1000;

export const UserActivityGuard: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { authApi } = useDi();
  const lastInteraction = useRef(Date.now());
  const lastScheduledHandler = useRef<NodeJS.Timeout | null>(null);
  const tracking = useTracking();
  const [isAuthorized, setIsAuthorized] = useAtom(isAuthorizedAtom);
  const lastInteractionTime = useAtomValue(lastInteractionTimeAtom);

  useUserInteractionTracking();

  lastInteraction.current = lastInteractionTime;

  useEffect(() => {
    const initiateSessionUpdates = () => {
      if (Date.now() - lastInteraction.current < inactiveThreshold) {
        authApi
          .validate({
            webFingerprint: getDeviceMetadata(tracking?.state),
          })
          .then((resp) => {
            const refreshInterval = countRefreshInterval(
              resp.refreshRemainingMinutes,
              resp.expRemainingMinutes
            );

            if (lastScheduledHandler.current !== null) {
              clearTimeout(lastScheduledHandler.current);
            }

            lastScheduledHandler.current = setTimeout(
              initiateSessionUpdates,
              refreshInterval
            );
          })
          .catch(() => {
            setIsAuthorized(false);
          });
      } else {
        setIsAuthorized(false);
        authApi.revoke();
      }
    };

    if (isAuthorized) {
      initiateSessionUpdates();
    }
    return () => {
      if (lastScheduledHandler.current) {
        clearTimeout(lastScheduledHandler.current);
      }
    };
  }, [isAuthorized, setIsAuthorized, authApi, tracking]);

  return <>{children}</>;
};
