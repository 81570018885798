import { useNavigate } from 'react-router-dom';

import { ApplicationAgreementSigningPage } from '~pages/application-agreement-signing';

import {
  ActiveApplicationGuardHOC,
  ClientConfirmationGuardHOC,
} from '~features/auth/guard';
import { CheckAttendanceGuardHOC } from '~features/check-attendance/check-attendance-guard';
import { SelectedPosGuardHOC } from '~features/select-pos';

export default () => {
  const navigate = useNavigate();

  return (
    <ClientConfirmationGuardHOC>
      <SelectedPosGuardHOC>
        <ActiveApplicationGuardHOC onAccessDenied={() => navigate('../')}>
          <CheckAttendanceGuardHOC onCancel={() => navigate('/applications')}>
            <ApplicationAgreementSigningPage />
          </CheckAttendanceGuardHOC>
        </ActiveApplicationGuardHOC>
      </SelectedPosGuardHOC>
    </ClientConfirmationGuardHOC>
  );
};
