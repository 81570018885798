import { useDi } from '~app/providers/di-provider';

import { useQuery } from '@tanstack/react-query';

export const useGetFileQuery = (id: string) => {
  const { documentApi } = useDi();

  return useQuery({
    queryKey: ['agreement', id],
    queryFn: async () => {
      const blob = await documentApi.getFile();
      const file = new File([blob], `agreement-${id}.pdf`, {
        type: 'application/pdf',
      });

      return file;
    },
    cacheTime: 0,
  });
};
