const getYesterday = (today: Date) => {
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);
  return yesterday;
};

export const isToday = (applicationDate: Date) => {
  const today = new Date();
  return today.toDateString() === applicationDate.toDateString();
};

export const isYesterday = (applicationDate: Date) => {
  const yesterday = getYesterday(new Date());
  return yesterday.toDateString() === applicationDate.toDateString();
};

export const getApplicationDateString = (dateUTC: string) => {
  const applicationDate = new Date(dateUTC);

  if (isToday(applicationDate)) {
    return 'Today';
  }
  if (isYesterday(applicationDate)) {
    return 'Yesterday';
  }

  return applicationDate.toLocaleString('en', {
    month: 'long',
    day: '2-digit',
  });
};
