import { useState, useEffect } from 'react';

import styles from './reviewing-time.module.css';

import { getWaitingTimeInSeconds, getWaitingInfo } from '../../../lib';

type WaitingTimeProps = {
  statusUpdatedUTC: string;
};

export const ReviewingTime = ({ statusUpdatedUTC }: WaitingTimeProps) => {
  const [waitingSeconds, setWaitingSeconds] = useState(0);

  useEffect(() => {
    setWaitingSeconds(() => getWaitingTimeInSeconds(statusUpdatedUTC));
    const interval = setInterval(() => {
      setWaitingSeconds((seconds) => seconds + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [statusUpdatedUTC]);

  const { timeStr, containerWidth } = getWaitingInfo(waitingSeconds);
  return (
    <div style={{ width: `${containerWidth}px` }} className={styles.badge}>
      {timeStr}
    </div>
  );
};
