import { Controller, useForm } from 'react-hook-form';

import { combineValidators } from '~shared/lib/combine-validators';
import { validators } from '~shared/validators';

import { LoadingButton } from '@breeze-platform-ui/button';
import { Box, Col } from '@breeze-platform-ui/layout';
import Text from '@breeze-platform-ui/text';
import { Input, Textarea } from '@breeze/rhf-adapters';

import styles from './missed-checkout-explanation.module.css';

export type ExplanationForm = {
  time: string;
  note: string;
};
export const MissedCheckoutExplanation: React.FC<{
  isLoading?: boolean;
  onDone: (value: ExplanationForm) => void;
}> = ({ isLoading, onDone }) => {
  const { control, handleSubmit } = useForm<ExplanationForm>({
    mode: 'all',
    defaultValues: {
      time: '18:00',
    },
  });
  return (
    <form onSubmit={handleSubmit(onDone)} className={styles.container}>
      <Col alignCross="stretch" gaps={32}>
        <Col alignCross="stretch" gaps={16}>
          <h2 className={styles.title}>
            You did&apos;t time&nbsp;out on&nbsp;your previous working day
          </h2>
          <Text size={15}>
            Fill&nbsp;in the&nbsp;info below so that your work schedule
            is&nbsp;reflected correctly
          </Text>
        </Col>
        <Col alignCross="stretch" gaps={12}>
          <Controller
            control={control}
            name="time"
            rules={{ required: 'Enter the time' }}
            render={(props) => (
              <Col gaps={16}>
                <Text size={15} bold tagName="label">
                  What time did you leave the workplace?
                </Text>
                <Box width={110}>
                  <Input
                    withValidityMark={false}
                    type={'time' as any}
                    {...props}
                  />
                </Box>
              </Col>
            )}
          />
          <Controller
            control={control}
            name="note"
            rules={{
              validate: combineValidators(
                validators.required({ text: 'Please, enter the reason' }),
                validators.maxLength({ maxLength: 350 })
              ),
            }}
            render={(props) => (
              <Col alignCross="stretch" gaps={16}>
                <Text size={15} bold tagName="label">
                  Why didn&apos;t you time out?
                </Text>
                <Textarea {...props} label="Your answer" />
              </Col>
            )}
          />
        </Col>
      </Col>
      <LoadingButton type="submit" loading={isLoading} disabled={isLoading}>
        Done
      </LoadingButton>
    </form>
  );
};
