import type { OrderItem } from '~entities/order-item';

export function addOrderItem(
  product: OrderItem,
  currentProducts: OrderItem[]
): OrderItem[] {
  const existingIndex = currentProducts.findIndex((p) =>
    p.zeroRateItemId
      ? p.zeroRateItemId && p.zeroRateItemId === product.zeroRateItemId
      : p.name === product.name &&
        p.category === product.category &&
        p.price === product.price
  );
  const products = [...currentProducts];

  // If the user adds a product with the same name, category and price,
  // we should increase its amount instead
  // of adding a new item to the list
  if (existingIndex !== -1) {
    products[existingIndex].quantity += +product.quantity;
  } else {
    products.push(product);
  }

  return products;
}
