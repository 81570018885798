import type { DailySchedule } from '../schedule';
import { WorkDayMode } from '../schedule';

export const workDayModeToStr: Record<WorkDayMode, string> = {
  [WorkDayMode.FULL_DAY]: 'Full day',
  [WorkDayMode.HALF_DAY]: 'Half day',
  [WorkDayMode.HALF_DAY_LEAVE]: 'Half day leave',
  [WorkDayMode.OVERTIME_WORK]: 'Overtime work',
};

export function getWorkDayTitle(schedule: DailySchedule) {
  if (schedule.type === 'WORK') {
    return workDayModeToStr[schedule.mode];
  }

  return 'Rest day';
}
