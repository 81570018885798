import type { RadioOption } from '~shared/types/radio-option';

const options = [
  {
    value: 'Client changed his/her mind',
    label: 'Customer changed his/her mind about taking a loan',
  },
  {
    value: 'Need to resubmit this application',
    label: 'Need to resubmit this application',
  },
  {
    value: 'Others',
    label: 'Others',
  },
] as const;

export type CancellationReasonValue = typeof options[number]['value'];
export const cancellationReasonOptions =
  options as unknown as RadioOption<CancellationReasonValue>[];

export const cancellationReasonCodeMap: Record<
  CancellationReasonValue,
  string
> = {
  'Client changed his/her mind': 'CLIENT_REJECT_WITH_EMPLOYEE',
  Others: 'CLIENT_REJECT_WITH_EMPLOYEE',
  'Need to resubmit this application': 'CLIENT_REJECT_WITH_EMPLOYEE_REAPPLY',
};
