import { environment } from '~app/environment';

import { createInstance } from '@amplitude/analytics-browser';

import { type AmplitudeCalculatorAnalytics } from '../types';

const amplitude = createInstance();

const getAnalytics = (): AmplitudeCalculatorAnalytics => {
  amplitude?.init(environment.AMPLITUDE_ANALYTICS_BY_CALC_KEY, {
    defaultTracking: false,
    appVersion: environment.VERSION,
  });

  function setUserId(id?: string) {
    amplitude?.setUserId(id);
  }

  return {
    setUserId,
    trackCalculatorUpdate(payload) {
      amplitude?.track('calculator_update', payload);
    },
  };
};

export const amplitudeCalculatorAnalytics = getAnalytics();
