import { BasicHeader } from '~shared/ui/basic-header';

import styles from './form-header.module.css';

import { NavCloseButton } from '../nav-close-button';

type Props = {
  href?: string;
  onClick?: () => void;
  text?: string;
};

export const FormHeader: React.FC<Props> = ({ text = null, href, onClick }) => {
  return (
    <BasicHeader
      actionButton={<NavCloseButton href={href} onClick={onClick} />}
      mainContent={text && <div className={styles.name}>{text}</div>}
    />
  );
};
