// Available vas list types

export type ValueWithCurrency = {
  value: string;
  currency: number;
};

export type ItemInsuranceParameters = {
  id: string;
  monthlyPayment: ValueWithCurrency;
  displayName: string;
  isBundle: boolean;
  displayDetails?: string;
  monthlyPaymentForAllChildVasList?: ValueWithCurrency;
};

export type AvailableItemInsurance = {
  name: string;
  price: ValueWithCurrency;
  category: string;
  vasList: ItemInsuranceParameters[];
  orderItemId?: number;
};

export type AvailableLoanInsurance = {
  id: string;
  monthlyPayment: ValueWithCurrency;
  displayName: string;
  displayDetails?: string;
};

export type AvailableLoanVasBundle = AvailableLoanInsurance & {
  displayCategory: 'LOAN_INSURANCE';
  monthlyPaymentForAllChildVasList: ValueWithCurrency;
};

export type AvailableItemVasBundle = {
  displayCategory: 'ITEM_INSURANCE';
  id: string;
  monthlyPayment: ValueWithCurrency;
  displayName: string;
  displayDetails?: string;
  monthlyPaymentForAllChildVasList: ValueWithCurrency;
};

export type AvailableVasBundle =
  | AvailableLoanVasBundle
  | AvailableItemVasBundle;

export type AvailableVasList = {
  itemInsuranceList: AvailableItemInsurance[];
  loanInsuranceList: AvailableLoanInsurance[];
  bundleList?: AvailableVasBundle[]; // Currently add support only for LOAN_INSURANCE as it's not clear yet what will be the behavior for ITEM_INSURANCE
};

export const isLoanBundle = (
  item: AvailableLoanVasBundle | AvailableLoanInsurance
): item is AvailableLoanVasBundle =>
  !!(item as AvailableLoanVasBundle).monthlyPaymentForAllChildVasList;

// Selected application vas types

export type SelectedItemInsurance = {
  itemName: string;
  itemManufacturerId: string;
  itemPrice: number;
  itemCategory: string;
  vasCatalogId: string;
  orderItemId?: number;
};

export type SelectedLoanInsurance = {
  vasCatalogId: string;
};

export type SelectedBundleVas = {
  vasBundleCatalogId: string;
};
export type ApplicationVasList = {
  vasItemInsuranceList: SelectedItemInsurance[];
  vasLoanInsuranceList: SelectedLoanInsurance[];
  vasBundleList?: SelectedBundleVas[];
};
