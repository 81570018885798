import { phMoneyProps } from '~shared/constants/ph-money-props';

import Money from '@breeze-platform-ui/money';
import { NotificationInline } from '@breeze-platform-ui/notification';

type Props = {
  totalPrice: number;
  minPrice?: number;
  maxPrice?: number;
  itemsCount: number;
  maxItemsCount?: number;
};

const moneyProps = { ...phMoneyProps, precision: 2 };

export const ValidationWarning: React.FC<Props> = ({
  totalPrice,
  minPrice = 0,
  maxPrice = Infinity,
  itemsCount,
  maxItemsCount = Infinity,
}) => {
  if (totalPrice < minPrice) {
    return (
      <NotificationInline
        showClose={false}
        type="info"
        withIcon={false}
        timer={false}
        animateFirstRender={false}
      >
        Add <Money {...moneyProps} value={minPrice - totalPrice} />{' '}
        to&nbsp;reach the&nbsp;minimum amount of&nbsp;
        <Money {...moneyProps} value={minPrice} />
      </NotificationInline>
    );
  }

  if (totalPrice > maxPrice) {
    return (
      <NotificationInline
        showClose={false}
        type="warning"
        timer={false}
        withIcon={false}
        animateFirstRender={false}
      >
        Lessen by&nbsp;
        <Money {...moneyProps} value={totalPrice - maxPrice} />{' '}
        to&nbsp;be&nbsp;within the&nbsp;maximum amount of&nbsp;
        <Money {...moneyProps} value={maxPrice} />
      </NotificationInline>
    );
  }

  if (maxItemsCount > 1 && itemsCount >= maxItemsCount) {
    return (
      <NotificationInline
        showClose={false}
        type="warning"
        timer={false}
        withIcon={false}
        animateFirstRender={false}
      >
        You’ve reached the&nbsp;maximum number of&nbsp;items
      </NotificationInline>
    );
  }

  return null;
};
