import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { combineValidators } from '~shared/lib/combine-validators';
import { validators } from '~shared/validators';

import { Row } from '@breeze-platform-ui/layout';
import { Input } from '@breeze/rhf-adapters';

import type { ManageOfferFormValue } from '../manage-offer-by-phone/manage-offer-by-phone';
import { SubmitButton } from '../submit-button/submit-button';

export const PromoCodeForm: React.FC<{
  isLoading?: boolean;
  isError?: boolean;
  onSubmitCode: (code?: string) => void;
}> = ({ isLoading, isError, onSubmitCode }) => {
  const { handleSubmit, control, formState, watch, setError } =
    useFormContext<ManageOfferFormValue>();
  const { isValid } = formState;
  const code = watch('promoCode');

  const handleCodeSubmit = ({ promoCode }: ManageOfferFormValue) => {
    onSubmitCode(promoCode);
  };

  useEffect(() => {
    if (isError) {
      setError('promoCode', {
        message: "Promo code doesn't exist or has expired",
      });
    }
  }, [isError, setError]);

  return (
    <form
      onBlur={(event) => event.stopPropagation()}
      onSubmit={(event, ...restArgs) => {
        event.stopPropagation();
        handleSubmit(handleCodeSubmit)(event, ...restArgs);
      }}
    >
      <Row gaps={8} alignMain="between" alignCross="start">
        <Controller
          name="promoCode"
          control={control}
          rules={{
            pattern: {
              value: /^[\da-zA-Z]*$/,
              message: 'Promo code can include only latin symbols and numbers',
            },
            validate: combineValidators(
              validators.exactLength({
                length: 5,
                text: 'Promo code length should be 5 characters',
              })
            ),
          }}
          render={({ field: { onChange, ...field }, ...props }) => (
            <Input
              {...props}
              label="Enter promo code"
              field={{
                ...field,
                onChange: (e) => {
                  const element = e.target as HTMLInputElement;
                  element.value = element.value.toLocaleUpperCase();

                  onChange(e);
                },
              }}
            />
          )}
        />
        <SubmitButton disabled={!isValid || !code} isLoading={isLoading} />
      </Row>
    </form>
  );
};
