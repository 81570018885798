import { useGetPosListQuery } from '~entities/pos';

import { Loader } from '~shared/ui/loader';
import { SectionTitle } from '~shared/ui/section-title';

import Text from '@breeze-platform-ui/text';

export const StoreInfo: React.FC<{ posId: string }> = ({ posId }) => {
  const posListQuery = useGetPosListQuery();
  const currentPos = posListQuery.data?.find((item) => item.id === posId);

  if (posListQuery.isLoading) {
    return <Loader />;
  }

  if (!posListQuery.isSuccess || !currentPos || posListQuery.data.length < 2) {
    return null;
  }

  const addressWithoutCountry = currentPos.fullAddress.replace(/, PHL$/, '');

  return (
    <div>
      <SectionTitle title="Store name" margin="0 0 16px" padding={0}>
        Store name
      </SectionTitle>
      <div>
        <Text size={15}>{currentPos.name}</Text>
        <Text size={13} color="#0000008A">
          {addressWithoutCountry}
        </Text>
      </div>
    </div>
  );
};
