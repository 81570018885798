import { useMemo, useState } from 'react';

import type { FileUploadResponse } from '~entities/file';

import { phMoneyProps } from '~shared/constants/ph-money-props';
import { Loader } from '~shared/ui/loader';

import throttle from '@tinkoff/utils/function/throttle';

import AttachFilePlatform from '@breeze-platform-ui/attachFile';
import { FILE_STATUS } from '@breeze-platform-ui/attachFile/AttachFile';
import type { FileItem as FileItemPlatform } from '@breeze-platform-ui/attachFile/AttachFile';
import { LoadingButton } from '@breeze-platform-ui/button';
import { Col, Row } from '@breeze-platform-ui/layout';
import Money from '@breeze-platform-ui/money';
import Text from '@breeze-platform-ui/text';
import { TuiIconTdsMediumDocCheckbox } from '@tui-react/proprietary-icons';

import styles from './confirm-downpayment.module.css';

export interface FileItem extends FileItemPlatform {
  file: File;
}

type Props = {
  isApplicationLoading: boolean;
  isConfirmationInProgress?: boolean;
  downPayment?: number;
  uploadFile: (file: FileItem) => Promise<FileUploadResponse>;
  onConfirm: () => void;
};
export const ConfirmDownpayment = ({
  isApplicationLoading,
  isConfirmationInProgress,
  downPayment,
  onConfirm,
  uploadFile,
}: Props) => {
  const [receipt, setReceipt] = useState<FileItem>();
  const throttledConfirm = useMemo(
    () => throttle(1000, onConfirm),
    [onConfirm]
  );

  const handleDownpaymentConfirmation = () => {
    if (!isConfirmationInProgress) {
      throttledConfirm();
    }
  };

  if (isApplicationLoading) {
    return <Loader centered />;
  }
  if (downPayment) {
    return (
      <Col height="100%" gaps="xl" alignCross="stretch" alignMain="start">
        <Row alignMain="center">
          <TuiIconTdsMediumDocCheckbox
            theme={{
              color: 'var(--tds-color-primary)',
              background: 'var(--tds-color-info-bg)',
            }}
            withContainer
            iconSize={32}
            containerSize={64}
          />
        </Row>

        <div
          className={styles['downpayment-message']}
          data-qa-type="downpayment-message"
        >
          The customer has signed the&nbsp;agreement.{' '}
          <Money {...phMoneyProps} value={downPayment} /> down&nbsp;payment
          is&nbsp;required
        </div>

        <Text align="center" size={15}>
          Take a photo of the receipt as proof that payment was made
        </Text>

        <AttachFilePlatform
          labels={{
            common: 'Take a photo of the receipt',
            desktop: ' ',
            dragging: ' ',
          }}
          onAdd={(_e, data) => {
            const fileItem = data.files[0] as FileItem;
            setReceipt({
              file: fileItem.file,
              status: FILE_STATUS.LOADING,
            });
            return uploadFile(fileItem)
              .then(() => {
                setReceipt({
                  file: fileItem.file,
                  status: FILE_STATUS.SUCCESS,
                  previewUrl: URL.createObjectURL(fileItem.file),
                });
              })
              .catch((err) => {
                setReceipt({
                  file: fileItem.file,
                  status: FILE_STATUS.ERROR,
                  message: err.message,
                });
              });
          }}
          onRemove={(_e) => {
            setReceipt(undefined);
          }}
          files={receipt ? [receipt] : undefined}
          sizeUnits={['B', 'KB', 'MB', 'GB']}
          preview
          single
          accept="image/*"
          capture="environment"
        />
        {receipt?.status === FILE_STATUS.SUCCESS && (
          <LoadingButton
            size="m"
            wide
            loading={isConfirmationInProgress}
            onClick={handleDownpaymentConfirmation}
          >
            Confirm payment
          </LoadingButton>
        )}
      </Col>
    );
  }
  return <Text>Something went wrong</Text>;
};
