import { useEffect } from 'react';
import { useBlocker, useNavigate } from 'react-router-dom';

export function useApplicationPageNavigationConstraints() {
  const navigate = useNavigate();
  const blocker = useBlocker(
    ({ nextLocation, historyAction }) =>
      historyAction === 'POP' &&
      ['/loan-calculator', '/client-initiation'].includes(nextLocation.pathname)
  );

  useEffect(() => {
    if (blocker.state === 'blocked') {
      blocker.reset();
      navigate('/applications');
    }
  }, [blocker, navigate]);
}
