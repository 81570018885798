import type { Validate } from 'react-hook-form';

import isEmpty from '@tinkoff/utils/is/empty';
import isNumber from '@tinkoff/utils/is/number';
import isObject from '@tinkoff/utils/is/object';
import isString from '@tinkoff/utils/is/string';
import trim from '@tinkoff/utils/string/trim';

import { plural } from '../../lib/plural';

interface ExactLengthOptions {
  length: number;
  text?: string;
}

export function exactLength(options: ExactLengthOptions): Validate<unknown> {
  return function minLengthValidator(value) {
    const isOptionsValid = isObject(options) && isNumber(options.length);
    if (!isOptionsValid) {
      return undefined;
    }

    if (!isString(value) || isEmpty(value)) {
      return undefined;
    }

    if (trim(value).length !== options.length) {
      return (
        options.text ??
        `Length should be ${options.length} ${plural(
          options.length,
          'character',
          'characters'
        )}`
      );
    }
  };
}
