import styles from './vas-price-info.module.css';

type Props = {
  monthlyPayment: number;
  monthlyPaymentForAllChildVasList?: number;
};

export const VasPriceInfo = ({
  monthlyPayment,
  monthlyPaymentForAllChildVasList,
}: Props) => {
  return (
    <>
      {!!monthlyPaymentForAllChildVasList && (
        <>
          <span
            className={styles.previousPrice}
            data-qa-type="vas-previous-payment"
          >
            ₱{monthlyPaymentForAllChildVasList}
          </span>{' '}
        </>
      )}
      <span>₱{monthlyPayment}/month</span>
    </>
  );
};
