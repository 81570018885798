import { environment } from '~app/environment';

import { webRequestClient, type ScpClient } from '~shared/scp-client';

import type {
  AgreementGenerationApi,
  Status,
} from './agreement-generation-api';

type Options = { host: string };
export class ScpAgreementGenerationApi implements AgreementGenerationApi {
  private client: ScpClient;
  host: string;

  constructor(client: ScpClient, options: Options) {
    this.client = client;
    this.host = options.host;
  }

  generate() {
    return this.makeRequest<'OK'>({
      method: 'generate',
    });
  }

  getStatus() {
    return this.makeRequest<Status>({
      method: 'status',
    });
  }

  private makeRequest<T>({ method }: { method: string }) {
    return this.client
      .call<T>({
        url: `${this.host}/origination/documents/public/${method}`,
        payload: { type: 'POS_LOAN_AGREEMENT' },
        withCredentials: true,
      })
      .then((response) => response.payload);
  }
}

export const scpAgreementGenerationApi = new ScpAgreementGenerationApi(
  webRequestClient,
  {
    host: environment.API_HOST,
  }
);
