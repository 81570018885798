import { useDi } from '~app/providers/di-provider';

import { AuthByOtp } from '~features/auth/otp';

import { agreementErrors } from '~entities/agreement';

interface Props {
  applicationId: string;
  phoneNumber: string;
  onSuccess(): void;
}

export function ApplicationAgreementSingning(props: Props) {
  const { phoneNumber, applicationId, onSuccess } = props;
  const { agreementApi } = useDi();

  return (
    <AuthByOtp
      phone={phoneNumber}
      type="agreement"
      onSend={() => agreementApi.send(applicationId)}
      onConfirm={(code) => agreementApi.confirm({ code, applicationId })}
      onResend={() => agreementApi.send(applicationId)}
      onSuccess={onSuccess}
      isSuccessResult={() => false}
      isAuthorizationError={agreementErrors.isSessionExpiredError}
      isConfirmationError={agreementErrors.isCodeConfirmationError}
      isAttemptsLimitError={agreementErrors.isAttemptsLimitError}
    />
  );
}
