import React, { useEffect, useCallback } from 'react';
import { useState } from 'react';
import {
  useNavigate,
  useLocation,
  useParams,
  createSearchParams,
} from 'react-router-dom';

import { FormLoadingPage } from '~widgets/form-loading-page';

import { AuthorizationError } from '~features/auth/otp';
import { setOtpRequestTime } from '~features/auth/otp/lib';
import { CreateApplicationError } from '~features/create-application-result';

import {
  activeApplicationIdAtom,
  downgradeApplicationIdAtom,
  useGetApplicationByIdQuery,
} from '~entities/application';
import { useGetApplicationQuery } from '~entities/application';
import {
  isSuccessResponse,
  useUpgradeInitiateMutation,
  gatewayAuthApi,
  isFindOrCreateApplicationError,
} from '~entities/auth';

import { FormHeader } from '~shared/ui/form-header';
import { Screen } from '~shared/ui/screen';

import { useSetAtom } from 'jotai';

export const ClientConfirmationGuardHOC = ({
  children,
}: React.PropsWithChildren) => {
  const navigate = useNavigate();
  const id = useParams().id as string;
  const { pathname, state } = useLocation();
  const [error, setError] = useState<Error | null>(null);
  const [showContent, setShowContent] = useState(false);
  const upgradeApplicationId = useSetAtom(activeApplicationIdAtom);
  const downgradeApplicationId = useSetAtom(downgradeApplicationIdAtom);

  const activate = useCallback(() => {
    upgradeApplicationId(id as string);
    setShowContent(true);
  }, [id, upgradeApplicationId]);

  const { data: activeApplication, isError: isActiveApplicationError } =
    useGetApplicationQuery();
  const { data: currentApplication, isError: isCurrentApplicationError } =
    useGetApplicationByIdQuery(id);
  const { mutate: upgradeInitiate } = useUpgradeInitiateMutation({
    onSuccess: (response) => {
      if (isSuccessResponse(response)) {
        activate();
      } else {
        gatewayAuthApi
          .confirmOtpSending()
          .then(() => {
            const currentPhone = currentApplication?.person.mobilePhone;

            if (currentPhone) {
              setOtpRequestTime('login', currentPhone, new Date().getTime());
            }

            return navigate(
              {
                pathname: '/client-confirmation',
                search: createSearchParams({
                  redirectUrl: pathname as string,
                }).toString(),
              },
              { state }
            );
          })
          .catch(setError);
      }
    },
    onError: setError,
  });

  useEffect(() => {
    const shouldInitiateUpgrade =
      (activeApplication &&
        activeApplication.id !== id &&
        currentApplication) ||
      isActiveApplicationError ||
      isCurrentApplicationError;
    const shouldActivateApplication =
      activeApplication && activeApplication.id === id;
    if (shouldActivateApplication) {
      activate();
    }
    if (shouldInitiateUpgrade) {
      downgradeApplicationId(id);
      upgradeInitiate({
        authParameters: { applicationId: id },
      });
    }
  }, [
    currentApplication,
    activeApplication,
    isActiveApplicationError,
    isCurrentApplicationError,
    downgradeApplicationId,
    upgradeInitiate,
    id,
    activate,
  ]);

  if (error) {
    return isFindOrCreateApplicationError(error) ? (
      <CreateApplicationError
        error={error}
        onClose={() => navigate(`/applications/${id}`)}
        application={activeApplication}
      />
    ) : (
      <Screen header={<FormHeader href="/" />}>
        <AuthorizationError />
      </Screen>
    );
  }

  if (showContent) {
    return <>{children}</>;
  }
  return <FormLoadingPage />;
};
