import { Skeleton } from '~shared/ui/skeleton';

import styles from './skeleton.module.css';

export const ActionsSkeleton = () => (
  <div className={styles.container}>
    <div className={styles.buttonContainer}>
      <Skeleton className={styles.button} />
      <Skeleton className={styles.text} />
    </div>
    <div className={styles.buttonContainer}>
      <Skeleton className={styles.button} />
      <Skeleton className={styles.text} />
    </div>
    <div className={styles.buttonContainer}>
      <Skeleton className={styles.button} />
      <Skeleton className={styles.text} />
    </div>
    <div className={styles.buttonContainer}>
      <Skeleton className={styles.button} />
      <Skeleton className={styles.text} />
    </div>
  </div>
);
