import { Skeleton } from '~shared/ui/skeleton';

import styles from './agreement-skeleton.module.css';

type Props = {
  numPages?: number;
};

export const AgreementSkeleton = ({ numPages = 4 }: Props) => (
  <>
    {Array.from({ length: numPages }).map((_, i) => (
      <Skeleton key={`p-${i + 1}`} className={styles.page} />
    ))}
  </>
);
