import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

import type { Pos } from '../pos';

export const selectedPosAtom = atomWithStorage<Pos | null>(
  'merchant_portal_selected_pos',
  null,
  undefined,
  { getOnInit: true }
);

export const isPosInitializedAtom = atom(false);
