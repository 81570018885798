import { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import type { DocumentProps } from 'react-pdf';
import { Document, pdfjs, Page } from 'react-pdf';

import { useCustomerAnalytics } from '~app/providers/analytics-provider';

import { Col } from '@breeze-platform-ui/layout';

import './agreement-document.module.css';

import { AgreementFallback } from '../agreement-fallback/agreement-fallback';
import { AgreementSkeleton } from '../agreement-skeleton/agreement-skeleton';

pdfjs.GlobalWorkerOptions.workerSrc =
  '/assets/scripts/pdf.worker.polyfilled.v9.1.0.mjs';

type Props = {
  applicationId: string;
  agreement: File;
};

export const AgreementDocument = (props: Props) => {
  const { applicationId, agreement } = props;

  const customerAnalytics = useCustomerAnalytics();
  const [numPages, setNumPages] = useState<number>();

  const handleDocumentLoadSuccess: DocumentProps['onLoadSuccess'] = ({
    numPages: nextNumPages,
  }) => {
    customerAnalytics.trackAgreementRenderSuccess({ applicationId });
    setNumPages(nextNumPages);
  };

  const handleDocumentLoadError = () => {
    customerAnalytics.trackAgreementRenderError({ applicationId });
  };

  return (
    <Col
      width="100%"
      alignCross="center"
      alignMain="center"
      className="agreement-document"
    >
      <ErrorBoundary
        fallback={
          <AgreementFallback
            agreement={agreement}
            applicationId={applicationId}
          />
        }
      >
        <Document
          file={agreement}
          onLoadError={handleDocumentLoadError}
          onLoadSuccess={handleDocumentLoadSuccess}
          loading={<AgreementSkeleton />}
          error={
            <AgreementFallback
              agreement={agreement}
              applicationId={applicationId}
            />
          }
        >
          {Array.from(new Array(numPages), (_el, index) => (
            <Page
              loading={<AgreementSkeleton numPages={1} />}
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              width={window.innerWidth}
              renderTextLayer={false}
              renderAnnotationLayer={false}
            />
          ))}
        </Document>
      </ErrorBoundary>
    </Col>
  );
};
