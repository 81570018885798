import { useWatch, useFormContext } from 'react-hook-form';

import Island from '@breeze-platform-ui/island';
import { Row, Col } from '@breeze-platform-ui/layout';

import styles from './loan-payments.module.css';

import { formatMoney } from '../../lib';
import type { LoanDetailsFormValues } from '../../types';

type Props = {
  initialMonthlyPayment: number;
  loanTerm: number;
  interestRate: number;
  hasVases: boolean;
};

export const LoanPayments = ({
  initialMonthlyPayment,
  loanTerm,
  interestRate,
  hasVases,
}: Props) => {
  const { control } = useFormContext<LoanDetailsFormValues>();

  const monthlyVasPayment = useWatch({
    name: `totalVasMonthlyPayment`,
    control,
  });

  return (
    <Island
      theme="gray"
      size="m"
      footerBody={
        <Col alignCross="stretch" gaps="xs">
          <Row alignMain="between">
            <div>Monthly payment</div>
            <div
              className={styles.details}
              data-qa-data="total-monthly-payment-value"
            >
              {formatMoney(initialMonthlyPayment + monthlyVasPayment)}
            </div>
          </Row>
          {hasVases && (
            <Row alignMain="between">
              <div>Insurance and warranty</div>
              <div
                className={styles.details}
                data-qa-data="insurance-monthly-payment-value"
              >
                {formatMoney(monthlyVasPayment)}
              </div>
            </Row>
          )}

          <Row alignMain="between">
            <div>Installment period</div>
            <div className={styles.details}>{loanTerm}&nbsp;months</div>
          </Row>
          <Row alignMain="between">
            <div>Interest rate</div>
            <div className={styles.details}>{interestRate}%</div>
          </Row>
        </Col>
      }
    />
  );
};
