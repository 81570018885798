import { isHttpError } from '~shared/errors/validators';
import type { RequestError } from '~shared/scp-client/errors';

export enum ProductValidationErrorCode {
  ZeroRateItemNotFound = 'ZERO_RATE_ITEMS_NOT_FOUND',
}

export const isItemNotFoundError = (error: RequestError): boolean =>
  isHttpError(error) &&
  error.code === ProductValidationErrorCode.ZeroRateItemNotFound;
