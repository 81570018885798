import React from 'react';
import type { FieldErrors } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';

import { useUploadFileMutation, useCheckImageMutation } from '~entities/file';

import { useRevalidate } from '~shared/hooks';
import { getFieldsErrors } from '~shared/lib/get-fields-errors';
import { FormFooter } from '~shared/ui/form-footer';
import { FormHeader } from '~shared/ui/form-header';
import { Screen } from '~shared/ui/screen';
import { ScreenTitle } from '~shared/ui/screen-title';
import { validators } from '~shared/validators';

import { Col } from '@breeze-platform-ui/layout';
import { AttachFile, FILE_STATUS } from '@breeze/rhf-adapters';
import type { FileItem } from '@breeze/rhf-adapters';
import { useNotifications } from '@pfa/front-notifications';

import { PhotoRequirements } from './photo-requirements';

export type FormValues = { image: FileItem };

type Props = Readonly<{
  applicationId: string;
  name: string;
  initialValue?: FormValues;
  onSubmit: (values: FormValues) => void;
  onPrev: (values: FormValues, errors: FieldErrors<FormValues>) => void;
  onClose: () => void;
}>;

const delayStatusCheck = () => new Promise((res) => setTimeout(res, 1500));

export const ClientPhoto: React.FC<Props> = ({
  name,
  applicationId,
  initialValue = {},
  onSubmit,
  onPrev,
  onClose,
}) => {
  const notifications = useNotifications();
  const { mutateAsync: upload } = useUploadFileMutation();
  const { mutateAsync: checkImage } = useCheckImageMutation();

  const methods = useForm<FormValues>({
    defaultValues: initialValue,
    mode: 'all',
  });

  useRevalidate(methods);

  const { control, getValues, handleSubmit } = methods;

  const handleClickButtonPrev = () => {
    const actualErrors = getFieldsErrors<FormValues>(methods);
    onPrev(getValues(), actualErrors);
  };

  const uploadWithCheck = async (file: FileItem) => {
    const uploadResult = await upload({
      applicationId,
      fileItem: {
        docType: 'CLIENT_PORTRAIT',
        frontImage: file.file,
      },
    });
    await delayStatusCheck();
    const checkResult = await checkImage(uploadResult.transactionId);
    if (checkResult.status === 'ERROR') {
      throw new Error(checkResult.message);
    }
  };

  const handleFormSubmit = (value: FormValues) => {
    const file = getValues('image');
    const image = Array.isArray(file) ? (file[0] as FileItem) : file;

    if (image && image.status === FILE_STATUS.LOADING) {
      notifications.warning('The file is still loading', {
        showClose: true,
        timer: 3_000,
      });
      return;
    }
    onSubmit(value);
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Screen
        header={<FormHeader onClick={onClose} text={name} />}
        footer={<FormFooter onClickPrev={handleClickButtonPrev} />}
      >
        <Col gaps={32} alignCross="stretch">
          <ScreenTitle title="Customer’s photo" />
          <PhotoRequirements />
          <Controller
            control={control}
            name="image"
            rules={{
              validate: validators.fileRequired({
                message: 'Please, take a photo of the customer',
              }),
            }}
            render={(fieldProps) => (
              <AttachFile
                {...fieldProps}
                uploadFile={uploadWithCheck}
                single
                preview
                accept="image/*"
                capture="environment"
                labels={{
                  common: 'Take a photo of the customer',
                  desktop: ' ',
                  dragging: ' ',
                }}
              />
            )}
          />
        </Col>
      </Screen>
    </form>
  );
};
