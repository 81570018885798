import type { OrderItem } from '~entities/order-item';
import type { ZeroRateItem } from '~entities/product';

import type { FormValue } from './zero-rate-item-form';

export const OrderItemConverter = {
  toFormValue(item: Partial<OrderItem> = {}): Partial<FormValue> {
    return {
      zeroRateItemId: item.zeroRateItemId,
      price: item.price,
      quantity: `${item.quantity || 1}`,
    };
  },

  fromFormValue(value: FormValue, zeroRateItem: ZeroRateItem): OrderItem {
    return {
      name: zeroRateItem.name,
      category: zeroRateItem.categoryCode,
      quantity: +value.quantity || 1,
      price: value.price,
      zeroRateItemId: value.zeroRateItemId,
    };
  },
};
