import { useState } from 'react';

import type { AgentIncentive } from '~entities/agent-incentive';

import { BasicHeader } from '~shared/ui/basic-header';
import { NavBackButton } from '~shared/ui/nav-back-button/nav-back-button';
import { Screen } from '~shared/ui/screen';

import { Col } from '@breeze-platform-ui/layout';

import { BonusCalculationDetails } from './bonus-calculation-details/bonus-calculation-details';
import { BonusHistory } from './bonus-history/bonus-history';
import { BonusLevels } from './bonus-levels';
import { CurrentBonus } from './current-bonus/current-bonus';

type Props = {
  agentIncentive: AgentIncentive;
  onBack: () => void;
};

export const AgentIncentiveScreen = ({ agentIncentive, onBack }: Props) => {
  const [calculateDetailsOpened, setCalculateDetailsOpened] = useState(false);
  const isAldiAgent = !agentIncentive.bonus.applications.find(
    ({ type }) => type === 'SUSPICIOUS'
  );
  const handleBackClick = () => {
    calculateDetailsOpened ? setCalculateDetailsOpened(false) : onBack();
  };
  const screenTitle = calculateDetailsOpened
    ? 'How it’s calculated'
    : 'My bonuses';

  return (
    <Screen
      header={
        <BasicHeader
          actionButton={<NavBackButton onClick={handleBackClick} />}
          mainContent={screenTitle}
        />
      }
    >
      <Col alignCross="stretch" gaps={24}>
        {calculateDetailsOpened ? (
          <BonusCalculationDetails steps={agentIncentive.steps} />
        ) : (
          <Col alignCross="stretch">
            <CurrentBonus
              bonusInfo={agentIncentive.bonus}
              isAldiAgent={isAldiAgent}
            />
            <BonusLevels
              isAldiAgent={isAldiAgent}
              bonusSteps={agentIncentive.steps}
              currentCount={agentIncentive.bonus.totalApplicationsCount}
              onOpenDetails={() => setCalculateDetailsOpened(true)}
            />
          </Col>
        )}
        <BonusHistory isAldiAgent={isAldiAgent} />
      </Col>
    </Screen>
  );
};
