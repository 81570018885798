import { PendingNumber } from '~features/pending-number';

import type { ApplicationDetails } from '~entities/application';

import { Row, Col } from '@breeze-platform-ui/layout';

import styles from './pending-applications.module.css';

import { getSortedApplicationsList } from '../../lib';
import { ApplicationItem } from '../application-item/application-item';

type Props = {
  applications: ApplicationDetails[];
};
export const PendingApplications = ({ applications }: Props) => {
  return (
    <Col className={styles.container} alignCross="stretch" grow={0}>
      <Col className={styles.header} alignCross="stretch">
        <Row alignCross="center">
          <span className={styles.title}>Pending applications</span>
          <PendingNumber />
        </Row>
        <Row className={styles.subtitle}>
          Upload the receipt or cancel the application
        </Row>
      </Col>

      {getSortedApplicationsList(applications).map((application) => {
        return (
          <ApplicationItem
            key={application.id}
            application={application}
            showArrow
          />
        );
      })}
    </Col>
  );
};
