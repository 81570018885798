import { environment } from '~app/environment';

import {
  webRequestClient,
  type RequestParams,
  type ScpClient,
} from '~shared/scp-client';

import type { AgentIncentiveApi } from './agent-incentive-api';

import type {
  AgentIncentive,
  AgentIncentiveMonthHistory,
} from '../agent-incentive';

type Options = { host: string };

class GatewayAgentIncentiveApi implements AgentIncentiveApi {
  scpClient: ScpClient;
  host: string;

  constructor(scpClient: ScpClient, options: Options) {
    this.scpClient = scpClient;
    this.host = options.host;
  }

  async getAgentIncentive(): Promise<AgentIncentive> {
    return this.makeRequest<AgentIncentive>({
      method: 'get-progress',
    });
  }

  async getAgentIncentiveHistory(): Promise<AgentIncentiveMonthHistory[]> {
    return this.makeRequest<AgentIncentiveMonthHistory[]>({
      method: 'get-progress-history',
    });
  }

  private makeRequest<T>(params: Partial<RequestParams> & { method: string }) {
    return this.scpClient
      .call<T>({
        host: this.host,
        version: 'v1',
        domain: 'agent-portal',
        service: 'public/incentive-program',
        withCredentials: true,
        withLeadingDomain: true,
        ...params,
      })
      .then((response) => response.payload);
  }
}

export const gatewayAgentIncentiveApi = new GatewayAgentIncentiveApi(
  webRequestClient,
  {
    host: environment.API_HOST,
  }
);
