import { Link, useNavigate } from 'react-router-dom';

import { BasicHeader } from '~shared/ui/basic-header';
import { Screen } from '~shared/ui/screen';
import { Warning } from '~shared/ui/warning';

import Button from '@breeze-platform-ui/button';
import { Int24Close } from '@breeze-platform-ui/iconsPack';

import continueApplicationImage from './images/continue-application.svg';

type Props = {
  applicationId: string;
};

export const ActiveApplicationWarning: React.FC<Props> = ({
  applicationId,
}) => {
  const navigate = useNavigate();
  return (
    <Screen
      header={
        <BasicHeader
          actionButton={
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <Link to="/applications">
              <Int24Close cursor="pointer" />
            </Link>
          }
        />
      }
    >
      <Warning
        icon={continueApplicationImage}
        title="This customer already has an&nbsp;active application"
        footerContent={
          <Button
            size="m"
            onClick={() => navigate(`/applications/${applicationId}`)}
            wide
          >
            Continue application
          </Button>
        }
      />
    </Screen>
  );
};
