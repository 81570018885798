export const getCancelDate = (statusUpdatedUTC: string) =>
  new Date(statusUpdatedUTC).toLocaleString('en', {
    day: '2-digit',
    month: 'long',
  });

export const getReleaseDate = (statusUpdatedUTC: string) =>
  new Date(statusUpdatedUTC).toLocaleString('en', {
    day: '2-digit',
    month: 'long',
    hour: '2-digit',
    minute: '2-digit',
  });

export const getReleaseTime = (statusUpdatedUTC: string) =>
  new Date(statusUpdatedUTC).toLocaleString('en', {
    hour: '2-digit',
    minute: '2-digit',
  });
