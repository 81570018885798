import { Row } from '@breeze-platform-ui/layout';
import { Hint } from '@breeze-platform-ui/tooltip';

import styles from './vas-label.module.css';

type Props = {
  displayName: string;
  displayDetails?: string;
  isBundle?: boolean;
  isTitle?: boolean;
};

export const Hints = ({
  displayDetails,
  isBundle,
}: Omit<Props, 'displayName'>) => (
  <Row alignCross="center">
    {displayDetails && (
      <Hint
        onClick={(event) => {
          event.preventDefault();
        }}
        direction="top"
        theme="dark"
        popoverContent={displayDetails}
      />
    )}
    {isBundle && (
      <span className={styles.offer} data-qa-type="best-offer-label">
        Best offer
      </span>
    )}
  </Row>
);

export const VasLabel = (props: Props) => {
  const { displayName, isTitle } = props;
  // We need the last word to be wrapped to the next string with hint block to avoid hints on the next line alone
  const lastSpaceIndex = displayName.trim().lastIndexOf(' ');
  const name = displayName.substring(0, lastSpaceIndex);
  const lastWord = displayName.substring(lastSpaceIndex);

  return (
    <span className={isTitle ? styles.title : styles.name}>
      {name}
      {/* if keep space in name it is removed in html :( */}
      {name && ' '}
      <div className={styles.noWrap}>
        {lastWord}
        <Hints {...props} />
      </div>
    </span>
  );
};
