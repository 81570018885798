export function leftpad(s: string, count: number, pad = '0') {
  if (s.length < count) {
    return new Array(count - s.length + 1).join(pad) + s;
  }
  return s;
}

export const generateIdempotencyKey = () => {
  const bytes = new Uint8Array(16);
  crypto.getRandomValues(bytes);
  const idempotencyKey = bytes.reduce(
    (acc, byte) => acc + leftpad(byte.toString(16), 2, '0'),
    ''
  );

  return idempotencyKey;
};
