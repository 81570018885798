import type { OrderItem } from '..';

export function coupleOrderItems(items: OrderItem[]) {
  const groupedItemsMap: Record<string, OrderItem> = {};
  const groupedItems: OrderItem[] = [];
  items.forEach((item) => {
    const key = `${item.name}_${item.category}_${item.price}`;
    const grouped = groupedItemsMap[`${key}`];

    if (grouped) {
      grouped.quantity += 1;
    } else {
      const newItem = { ...item };
      groupedItems.push(newItem);
      groupedItemsMap[`${key}`] = newItem;
    }

    return groupedItems;
  });

  return groupedItems;
}
