import { Col } from '@breeze-platform-ui/layout';

import type { OrderItem } from '../../model/order-item';
import { OrderItemUI } from '../order-item/order-item';

type Props = {
  items: OrderItem[];
  actions?: (index: number) => React.ReactNode;
};

export const OrderItemList: React.FC<Props> = ({ items, actions }) => {
  return (
    <Col gaps={16} alignCross="stretch">
      {items.map((item, index) => (
        <OrderItemUI
          key={
            item.zeroRateItemId ?? `${item.name}${item.category}${item.price}`
          }
          item={item}
          actions={actions?.(index)}
        />
      ))}
    </Col>
  );
};
