import { AuthorizationError } from '~features/auth/otp';

import { Screen } from '~shared/ui/screen';

const AccessDenied = () => {
  const invalidRoleError = {
    code: 'INVALID_ROLE',
    name: 'HttpError',
    message: '',
  };
  return (
    <Screen>
      <AuthorizationError error={invalidRoleError} />
    </Screen>
  );
};
export default AccessDenied;
