import { useEffect } from 'react';
import type { PropsWithChildren } from 'react';

import { Divider } from '@breeze-platform-ui/layout';

import styles from './screen.module.css';

type Props = {
  header?: React.ReactNode;
  footer?: React.ReactNode;
} & PropsWithChildren;

/**
 * Wrapper for page layout
 * @param header - Sticky header, will be displayed at the top of the page, above the scrolled content
 * @param footer - Fixed footer, will be displayed at the bottom of the page
 */
export const Screen: React.FC<Props> = ({ header, footer, children }) => {
  useEffect(() => {
    const setVh = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    setVh();
    window.addEventListener('resize', setVh);
    return () => {
      window.removeEventListener('resize', setVh);
    };
  }, []);
  return (
    <div className={styles.wrapper}>
      {header && (
        <div className={styles.header}>
          <div className={styles.fixedContent}>{header}</div>
          <Divider color="rgba(0, 0, 0, 0.07)" />
        </div>
      )}
      <div className={styles.main}>{children}</div>
      {footer && (
        <div className={styles.footer}>
          <Divider color="rgba(0, 0, 0, 0.07)" />
          <div className={styles.fixedContent}>{footer}</div>
        </div>
      )}
    </div>
  );
};
