import Button from '@breeze-platform-ui/button';
import { Col, Row } from '@breeze-platform-ui/layout';
import Text from '@breeze-platform-ui/text';

import styles from './offer-banner.module.css';
import starsImg from './stars.svg';

type Props = {
  title?: string;
  subtitle?: string;
  onClose: () => void;
};
export const ForceOfferBanner = ({ title, subtitle, onClose }: Props) => {
  return (
    <div className={styles.overlay} data-qa-type="force-offer-banner">
      <Col gaps={24} alignCross="stretch" className={styles.container}>
        <Row alignMain="center">
          <Text size={24} bold align="center">
            This customer has a&nbsp;special&nbsp;offer
          </Text>
        </Row>
        <Row
          className={styles.offerIsland}
          gaps={16}
          alignCross="center"
          alignMain="between"
        >
          <div className={styles.icon_container}>
            <img
              className={styles.icon_img}
              src={starsImg}
              alt=""
              height="24"
              width="24"
            />
          </div>
          <Col grow={1}>
            <Text size={15} bold>
              {title}
            </Text>
            <Text size={13} color="rgba(0, 0, 0, 0.80)">
              {subtitle}
            </Text>
          </Col>
        </Row>
        <Button size="l" onClick={onClose}>
          OK
        </Button>
      </Col>
    </div>
  );
};
