import { useDi } from '~app/providers/di-provider';

import { useQueuedMutation } from '~shared/react-query-helpers';

import type { Person } from '.';
import type {
  UseMutationOptions,
  UseQueryOptions,
} from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

export const useGetPersonQuery = (
  options?: Omit<UseQueryOptions, 'cacheTime'>
) => {
  const { personApi } = useDi();

  return useQuery({
    queryKey: ['getPerson'],
    queryFn: () => personApi.getPerson(),
    cacheTime: 0,
    ...options,
  });
};

export const useUpdatePersonMutation = (
  options?: UseMutationOptions<Person, Error, Partial<Person>>
) => {
  const { personApi } = useDi();

  return useQueuedMutation({
    mutationKey: ['updatePerson'],
    mutationFn: (payload: Partial<Person>) => personApi.updatePerson(payload),
    ...options,
  });
};
