import { BasicHeader } from '~shared/ui/basic-header';
import { NavBackButton } from '~shared/ui/nav-back-button';
import { Screen } from '~shared/ui/screen';

import Button from '@breeze-platform-ui/button';

import styles from './item-form-container.module.css';

type Props = {
  onBack: () => void;
  isEditing?: boolean;
};

export const ItemFormContainer: React.FC<React.PropsWithChildren & Props> = ({
  children,
  onBack,
}) => {
  return (
    <div className={styles.form_container}>
      <Screen
        header={
          <BasicHeader actionButton={<NavBackButton onClick={onBack} />} />
        }
        footer={
          <div className={styles.footer}>
            <Button type="submit" wide size="m">
              Done
            </Button>
          </div>
        }
      >
        {children}
      </Screen>
    </div>
  );
};
