import type { AbstractOption } from '~shared/types/abstract-option';

const options = [
  {
    value: 'MALE',
    title: 'Male',
  },
  {
    value: 'FEMALE',
    title: 'Female',
  },
] as const;

export type GenderValue = typeof options[number]['value'];
export const genderOptions =
  options as unknown as AbstractOption<GenderValue>[];
