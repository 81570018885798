import { useState, useEffect } from 'react';

import styles from './waiting-time.module.css';

import { getWaitingTimeInSeconds, getWaitingInfo } from '../../../lib';

type WaitingTimeProps = {
  limitInMinutes: number;
  statusUpdatedUTC: string;
  onWaitingTimeExceeded: () => void;
};

export const WaitingTime = ({
  limitInMinutes,
  statusUpdatedUTC,
  onWaitingTimeExceeded,
}: WaitingTimeProps) => {
  const [limitExceeded, setLimitExceeded] = useState(false);
  const [waitingSeconds, setWaitingSeconds] = useState(0);

  useEffect(() => {
    if (!limitExceeded && waitingSeconds > limitInMinutes * 60) {
      setLimitExceeded(true);
      onWaitingTimeExceeded();
    }
  }, [waitingSeconds, limitExceeded, limitInMinutes, onWaitingTimeExceeded]);

  useEffect(() => {
    setWaitingSeconds(() => getWaitingTimeInSeconds(statusUpdatedUTC));
    const interval = setInterval(() => {
      setWaitingSeconds((seconds) => seconds + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [statusUpdatedUTC]);

  const { timeStr, containerWidth } = getWaitingInfo(waitingSeconds);
  return (
    <div style={{ width: `${containerWidth}px` }} className={styles.badge}>
      {timeStr}
    </div>
  );
};
