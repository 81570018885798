import { Controller, useForm } from 'react-hook-form';

import { ScreenTitle } from '~shared/ui/screen-title';
import { validators } from '~shared/validators';

import Button from '@breeze-platform-ui/button';
import { Col } from '@breeze-platform-ui/layout';
import { RadioGroup, Textarea } from '@breeze/rhf-adapters';

type Props = {
  onSubmit: (answer: string) => void;
};

const options = [
  'Customer didn’t plan to make a purchase today',
  'Customer didn’t like our offer',
  'Customer has a better offer from a competitor',
  'Other reason',
];

type FormValues = {
  answer: string;
  comment?: string;
};
export const ConfirmationSurvey = ({ onSubmit }: Props) => {
  const methods = useForm<FormValues>({
    mode: 'all',
    defaultValues: { answer: '' },
  });

  const answer = methods.watch('answer');
  const comment = methods.watch('comment')?.trim();

  const showComment = answer === 'Other reason';
  const isSubmitDisabled = !answer || (showComment && !comment);

  return (
    <Col alignCross="stretch" dataQaType="cancellation-survey">
      <form
        onSubmit={methods.handleSubmit((values) => {
          onSubmit(values.comment?.trim() || values.answer);
        })}
      >
        <ScreenTitle
          title="Why are you closing this calculation?"
          margin="0 0 16px"
        />
        <Controller
          name="answer"
          control={methods.control}
          rules={{ required: 'Please, select an option' }}
          render={(fieldProps) => (
            <RadioGroup
              {...fieldProps}
              options={options.map((option) => ({
                value: option,
                label: option,
              }))}
              reverse
              vertical
              noWrapper
            />
          )}
        />
        {showComment && (
          <Controller
            shouldUnregister
            control={methods.control}
            name="comment"
            rules={{
              validate: validators.required({
                text: 'Please, enter the reason',
              }),
            }}
            render={(fieldProps) => (
              <Textarea
                {...fieldProps}
                placeholder="Please type the reason here"
              />
            )}
          />
        )}
        <Button type="submit" wide size="l" disabled={isSubmitDisabled}>
          Submit
        </Button>
      </form>
    </Col>
  );
};
