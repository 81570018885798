import type { AbstractOption } from '~shared/types/abstract-option';

const options = [
  { value: 'PARENT', title: 'Parent' },
  { value: 'SPOUSE', title: 'Spouse' },
  { value: 'CHILD', title: 'Child' },
  { value: 'SIBLING', title: 'Sibling' },
] as const;

export type FamilyRelationValue = typeof options[number]['value'];
export const familyRelationOptions = options as unknown as AbstractOption[];
