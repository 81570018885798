import { useDi } from '~app/providers/di-provider';

import type {
  UseQueryOptions,
  UseMutationOptions,
} from '@tanstack/react-query';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import type { UpdateApplicationVasListPayload } from '../api';
import type { AvailableVasList, ApplicationVasList } from '../vas';

export const useGetAvailableVasListQuery = (
  applicationId: string,
  options?: UseQueryOptions<AvailableVasList, Error>
) => {
  const { vasApi } = useDi();

  return useQuery({
    queryKey: ['availableVasList', applicationId],
    queryFn: () => vasApi.getAvailableVasList({ applicationId }),
    ...options,
  });
};

export const useGetApplicationVasListQuery = (
  applicationId: string,
  options?: UseQueryOptions<ApplicationVasList, Error>
) => {
  const { vasApi } = useDi();

  return useQuery({
    queryKey: ['applicationVasList', applicationId],
    queryFn: () => vasApi.getApplicationVasList(applicationId),
    ...options,
  });
};

export const useApplicationVasListMutation = (
  options?: UseMutationOptions<'ok', Error, UpdateApplicationVasListPayload>
) => {
  const { vasApi } = useDi();
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['updateApplicationVasList'],
    mutationFn: async (payload: UpdateApplicationVasListPayload) => {
      const response = await vasApi.updateApplicationVasList(payload);
      queryClient.setQueryData(
        ['applicationVasList', payload.applicationId],
        payload
      );
      return response;
    },
    ...options,
  });
};
