import type { ApplicationLoanDetails } from '~entities/application';

import { formatNumber, recoverNumber } from '~shared/formatters';

import type { LoanCalculatorFormValues } from '../loan-calculator-form-values';

export const formatLoanCalculator = (
  formValues: Omit<LoanCalculatorFormValues, 'paymentPlans'>
): ApplicationLoanDetails => {
  const desiredLoanAmount =
    Math.round((formValues.totalPrice - +formValues.downPayment) * 100) / 100;
  return {
    orderItems: formValues.items,
    requestedProduct: {
      type: 'POS_LOAN',
      desiredLoanAmount: `${desiredLoanAmount}`,
      desiredLoanTerm: formatNumber(formValues.period),
      downPayment: formatNumber(formValues.downPayment),
      productId: formValues.productId,
      campaignId: formValues.campaignId,
    },
    offer: formValues.offer
      ? {
          id: formValues.offer.id,
        }
      : undefined,
    promoCode: formValues.promoCode,
  };
};

export const recoverLoanCalculator = (
  loanDetails: ApplicationLoanDetails
): Partial<LoanCalculatorFormValues> => {
  const { requestedProduct } = loanDetails;
  const totalPrice =
    requestedProduct?.desiredLoanAmount && requestedProduct.downPayment
      ? Math.round(
          (+requestedProduct.desiredLoanAmount + requestedProduct.downPayment) *
            100
        ) / 100
      : undefined;
  return {
    items: loanDetails.orderItems,
    totalPrice,
    downPayment:
      typeof requestedProduct?.downPayment !== 'undefined'
        ? recoverNumber(requestedProduct.downPayment)
        : undefined,
    period:
      typeof requestedProduct?.desiredLoanTerm !== 'undefined'
        ? recoverNumber(requestedProduct?.desiredLoanTerm)
        : undefined,
    productId: requestedProduct?.productId,
    campaignId: requestedProduct?.campaignId,
    promoCode: loanDetails.promoCode,
  };
};
