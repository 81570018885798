import { useState } from 'react';

import Button, { ButtonLink } from '@breeze-platform-ui/button';
import { Int16Close } from '@breeze-platform-ui/iconsPack';
import { Row } from '@breeze-platform-ui/layout';
import Sheet from '@breeze-platform-ui/sheet';
import type {
  Mode,
  Modes,
  RequireAtLeastOne,
} from '@breeze-platform-ui/sheet/types';

import styles from './show-pos-help.module.css';

const sheetModes: RequireAtLeastOne<Modes> = {
  normal: {
    minHeight: 250,
    maxHeight: 300,
  },
  collapsed: false,
  expanded: false,
};

export const ShowPosHelp: React.FC<{ customerServicePhone: string }> = ({
  customerServicePhone,
}) => {
  const [sheetOpened, setSheetOpened] = useState<boolean>(false);
  const [sheetMode, setSheetMode] = useState<Mode>('normal');

  return (
    <>
      <ButtonLink
        size="s"
        disableHorizontalPadding
        disableVerticalPadding
        onClick={() => setSheetOpened(true)}
      >
        Can’t find my store
      </ButtonLink>
      <Sheet
        mode={sheetMode}
        modes={sheetModes}
        opened={sheetOpened}
        onClose={() => setSheetOpened(false)}
        onChange={(_event, params) => setSheetMode(params.next)}
      >
        <Row alignCross="start" alignMain="between" gaps={18}>
          <div>
            <h2 className={styles.header}>
              Contact us if you can’t find your store
            </h2>
            <p className={styles.description}>
              Customer service is&nbsp;ready to&nbsp;help from&nbsp;
              9&nbsp;AM&nbsp;to&nbsp;6&nbsp;PM
            </p>
          </div>
          <Button
            icon={<Int16Close theme="gray" />}
            theme="secondaryLight"
            size="s"
            round
            onClick={() => setSheetOpened(false)}
          />
        </Row>
        {/* TODO: remove hardcoded phone number, get it from static config instead */}
        <Button size="m" wide href={`tel:${customerServicePhone}`}>
          Call customer service
        </Button>
      </Sheet>
    </>
  );
};
