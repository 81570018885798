import type { AbstractOption } from '~shared/types/abstract-option';

const options = [
  {
    value: 'SALARY',
    title: 'Salary',
  },
  {
    value: 'BUSINESS_INCOME',
    title: 'Business income',
  },
  {
    value: 'PERSONAL_SAVINGS',
    title: 'Personal savings',
  },
  {
    value: 'PENSION_ALLOWANCE',
    title: 'Pension or allowance',
  },
  {
    value: 'INHERITANCE',
    title: 'Inheritance',
  },
  {
    value: 'DEPOSIT_OR_INVESTMENTS_INTEREST',
    title: 'Deposit or investments interest',
  },
  {
    value: 'TRANSFERS',
    title: 'Remittance or transfers',
  },
  {
    value: 'OTHER',
    title: 'Other',
  },
] as const;

export type IncomeSourceValue = typeof options[number]['value'];
export const incomeSourceOptions =
  options as unknown as AbstractOption<IncomeSourceValue>[];
