import { useCallback, useEffect } from 'react';
import type { FieldErrors, FieldPath, Validate } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';

import type { PhoneType } from '~entities/person';

import { useRevalidate } from '~shared/hooks';
import { combineValidators } from '~shared/lib/combine-validators';
import { getFieldsErrors } from '~shared/lib/get-fields-errors';
import { FormFooter } from '~shared/ui/form-footer';
import { FormHeader } from '~shared/ui/form-header';
import { Screen } from '~shared/ui/screen';
import { ScreenTitle } from '~shared/ui/screen-title';
import { SectionTitle } from '~shared/ui/section-title';
import { validators } from '~shared/validators';

import { Col } from '@breeze-platform-ui/layout';
import { InputCapitalize, InputPhone, Select } from '@breeze/rhf-adapters';

import {
  ADDITIONAL_PHONE_FIELD_NAME,
  FAMILY_RELATION_FIELD_NAME,
  REFERENCE_CONTACT_NUMBER_FIELD_NAME,
  REFERENCE_CONTACT_NUMBER_REQUIRED_ERROR,
  REFERENCE_NAME_FIELD_NAME,
  REFERENCE_NAME_REQUIRED_ERROR,
  RELATIONSHIP_FIELD_NAME,
  SELECT_REQUIRED_ERROR,
} from '../constants';
import type { AlternativeContactValue, FamilyRelationValue } from '../lib';
import { familyRelationOptions } from '../lib';
import { alternativeContactOptions } from '../lib';

export interface FormValues {
  additionalPhone?: string;
  name: string;
  type: AlternativeContactValue;
  familyRelation?: FamilyRelationValue;
  number: string;
}

type Props = Readonly<{
  initialValue?: Partial<FormValues>;
  name: string;
  onFieldCompleted?: (
    values: Partial<FormValues>,
    errors: Partial<FieldErrors<FormValues>>
  ) => void;
  onPrev: (
    values: Partial<FormValues>,
    errors: FieldErrors<FormValues>
  ) => void;
  onSubmit: (formValues: FormValues) => void;
  validatePhoneUnicity: (phoneType: PhoneType) => Validate<string | undefined>;
  onPhoneUpdate: (phone: string | undefined, type: PhoneType) => void;
  onClose: () => void;
}>;

export const AlternativeContacts: React.FC<Props> = ({
  initialValue = {},
  name,
  validatePhoneUnicity,
  onFieldCompleted,
  onPrev,
  onSubmit,
  onClose,
  onPhoneUpdate,
}) => {
  const methods = useForm<FormValues>({
    mode: 'all',
    defaultValues: initialValue,
  });
  const { control, handleSubmit, getValues, getFieldState, watch, trigger } =
    methods;

  useRevalidate(methods);

  const triggerPhone = useCallback(
    async (field: FieldPath<FormValues>, type: PhoneType) => {
      const value = getValues(field);
      if (getFieldState(field).isDirty || value) {
        const isValid = await trigger(field);

        onPhoneUpdate(isValid ? value : undefined, type);
      }
    },
    [getFieldState, getValues, trigger, onPhoneUpdate]
  );

  useEffect(() => {
    async function triggerRevalidation() {
      await triggerPhone('additionalPhone', 'additional');
      await triggerPhone('number', 'alternative');
    }

    triggerRevalidation();
  }, [triggerPhone, validatePhoneUnicity]);

  const handleBlur = () => {
    const actualErrors = getFieldsErrors<FormValues>(methods);

    onFieldCompleted?.(getValues(), actualErrors);
  };

  const handleClickButtonPrev = () => {
    const actualErrors = getFieldsErrors<FormValues>(methods);

    onPrev(getValues(), actualErrors);
  };

  const isFamilyMember = watch('type') === 'FAMILY_MEMBER';

  return (
    <form onBlur={handleBlur} onSubmit={handleSubmit(onSubmit)}>
      <Screen
        header={<FormHeader onClick={onClose} text={name} />}
        footer={<FormFooter onClickPrev={handleClickButtonPrev} />}
      >
        <ScreenTitle title="Other ways to&nbsp;keep in&nbsp;touch" />
        <Col
          tagName="section"
          gaps={16}
          margin="0 0 12px 0"
          alignCross="stretch"
        >
          <SectionTitle title="Second contact number" />
          <Controller
            name="additionalPhone"
            control={control}
            rules={{
              validate: combineValidators(
                validators.phoneLength(),
                validators.phoneFormat(),
                validatePhoneUnicity('additional')
              ),
            }}
            render={(fieldProps) => (
              <InputPhone
                label={ADDITIONAL_PHONE_FIELD_NAME}
                {...fieldProps}
                field={{
                  ...fieldProps.field,
                  onBlur: () => {
                    const { error } = fieldProps.fieldState;
                    const { value } = fieldProps.field;

                    onPhoneUpdate(!error ? value : undefined, 'additional');
                    fieldProps.field.onBlur();
                  },
                }}
              />
            )}
          />
        </Col>
        <Col tagName="section" gaps={16} alignCross="stretch">
          <SectionTitle title="Personal reference" />
          <Controller
            name="name"
            control={control}
            rules={{
              validate: combineValidators(
                validators.required({
                  text: REFERENCE_NAME_REQUIRED_ERROR,
                }),
                validators.maxLength({ maxLength: 300 }),
                validators.filipinoName()
              ),
            }}
            render={(fieldProps) => (
              <InputCapitalize
                label={REFERENCE_NAME_FIELD_NAME}
                {...fieldProps}
              />
            )}
          />
          <Controller
            name="type"
            control={control}
            rules={{
              required: SELECT_REQUIRED_ERROR,
            }}
            render={(fieldProps) => (
              <Select
                label={RELATIONSHIP_FIELD_NAME}
                options={alternativeContactOptions}
                {...fieldProps}
              />
            )}
          />
          {isFamilyMember && (
            <Controller
              name="familyRelation"
              control={control}
              shouldUnregister
              rules={{
                required: SELECT_REQUIRED_ERROR,
              }}
              render={(fieldProps) => (
                <Select
                  label={FAMILY_RELATION_FIELD_NAME}
                  options={familyRelationOptions}
                  {...fieldProps}
                />
              )}
            />
          )}
          <Controller
            name="number"
            control={control}
            rules={{
              required: REFERENCE_CONTACT_NUMBER_REQUIRED_ERROR,
              validate: combineValidators(
                validators.phoneLength(),
                validators.phoneFormat(),
                validatePhoneUnicity('alternative')
              ),
            }}
            render={(fieldProps) => (
              <InputPhone
                label={REFERENCE_CONTACT_NUMBER_FIELD_NAME}
                {...fieldProps}
                field={{
                  ...fieldProps.field,
                  onBlur: () => {
                    const { error } = fieldProps.fieldState;
                    const { value } = fieldProps.field;

                    onPhoneUpdate(!error ? value : undefined, 'alternative');
                    fieldProps.field.onBlur();
                  },
                }}
              />
            )}
          />
        </Col>
      </Screen>
    </form>
  );
};
