import type { OrderItem } from '~entities/order-item';

import { phMoneyProps } from '~shared/constants/ph-money-props';

import { Col, Row } from '@breeze-platform-ui/layout';
import Money from '@breeze-platform-ui/money';

import styles from './order-item.module.css';

type Props = {
  item: OrderItem;
  actions?: React.ReactNode;
};

export const OrderItemUI: React.FC<Props> = ({ item, actions }) => {
  return (
    <Row gaps={24} alignMain="between" dataQaType="order-list-item">
      <Col gaps={2} grow={1} alignCross="stretch">
        <div className={styles.mainText} data-qa-type="order-item-name">
          {item.name}
        </div>
        <div className={styles.secondaryText}>
          <span data-qa-type="order-item-quantity">{item.quantity}</span>
          &nbsp;×&nbsp;
          <Money
            {...phMoneyProps}
            dataQaType="order-item-price"
            value={item.price}
          />
        </div>
      </Col>
      <Row gaps={20}>
        <div className={styles.mainText} data-qa-type="order-item-total-price">
          <Money {...phMoneyProps} value={+item.price * +item.quantity} />
        </div>
        {actions}
      </Row>
    </Row>
  );
};
