import {
  calculateMonthlyPayment,
  type ApprovedProductOption,
} from '~entities/product';

import { phMoneyProps } from '~shared/constants/ph-money-props';
import { plural } from '~shared/lib/plural';

import { Col } from '@breeze-platform-ui/layout';
import Money from '@breeze-platform-ui/money';
import Text from '@breeze-platform-ui/text';

const loanMoneyProps = { ...phMoneyProps, precision: 2 };

export function getPaymentPlanOptions(
  loanAmount: number,
  availableProductOptions: ApprovedProductOption[]
) {
  return availableProductOptions.map(
    ({
      id,
      loanTermInMonths,
      monthlyInterestRate,
      originationFeeFlat,
      originationFeeRate,
    }) => {
      return {
        value: `${id}`,
        children: (
          <Col alignCross="stretch" grow={1}>
            <Text bold size={15} color="black">
              <Money
                {...loanMoneyProps}
                value={calculateMonthlyPayment(
                  loanAmount,
                  loanTermInMonths,
                  monthlyInterestRate,
                  originationFeeRate,
                  originationFeeFlat
                )}
              />
            </Text>
            <Text size={13} color="black">
              x {loanTermInMonths} {plural(loanTermInMonths, 'month', 'months')}
            </Text>
          </Col>
        ),
      };
    }
  );
}
