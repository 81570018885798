export function countRefreshInterval(
  refreshRemainingMinutes: number,
  expRemainingMinutes: number
): number {
  const defaultInterval = Math.floor(refreshRemainingMinutes * 1.5);
  const averageInterval = Math.floor(
    (expRemainingMinutes + refreshRemainingMinutes) / 2
  );
  const minInterval = Math.min(defaultInterval, averageInterval);

  // min -> milliseconds
  return minInterval * 60 * 1000;
}
