import type { AbstractOption } from '~shared/types/abstract-option';

const options = [
  {
    value: 'PHL',
    title: 'Filipino',
  },
  {
    value: 'OTHER',
    title: 'Non-Filipino',
  },
] as const;

export type CitizenshipValue = typeof options[number]['value'];
export const citizenshipOptions =
  options as unknown as AbstractOption<CitizenshipValue>[];
