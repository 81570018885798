import { useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import {
  type AvailableLoanInsurance,
  type AvailableLoanVasBundle,
  isLoanBundle,
} from '~entities/vas';

import { ButtonLink } from '@breeze-platform-ui/button';
import { Row, Col } from '@breeze-platform-ui/layout';
import { RadioGroup } from '@breeze/rhf-adapters';

import { getPriceFromValueWithCurrency } from '../../lib';
import type { LoanDetailsFormValues, LoanVasInsuranceType } from '../../types';
import styles from '../loan-details-form.module.css';
import { VasLabel } from '../vas-label';
import { VasPriceInfo } from '../vas-price-info';

type Props = {
  vasList: (AvailableLoanVasBundle | AvailableLoanInsurance)[];
};

type VasMap = Record<string, { price: number; type: LoanVasInsuranceType }>;

const getVasMap = (
  vases: (AvailableLoanInsurance | AvailableLoanVasBundle)[]
): VasMap => {
  const vasMap: VasMap = {};
  vases.forEach((vas) => {
    vasMap[vas.id] = {
      price: getPriceFromValueWithCurrency(vas.monthlyPayment),
      type: (vas as AvailableLoanVasBundle).monthlyPaymentForAllChildVasList
        ? 'bundle'
        : 'single',
    };
  });
  return vasMap;
};

export const LoanVasList = ({ vasList }: Props) => {
  const { getValues, resetField, setValue, watch } =
    useFormContext<LoanDetailsFormValues>();

  const vasMap = useRef(getVasMap(vasList));

  const selectedLoanVas = watch('vasLoanInsurance');

  const handleTotalPaymentUpdate = (id?: string) => {
    const prevId = getValues().vasLoanInsurance;
    const prevTotalPayment = getValues().totalVasMonthlyPayment;

    const paymentWithoutLoanVas = prevId
      ? prevTotalPayment - vasMap.current[prevId].price
      : prevTotalPayment;

    const paymentWithSelectedLoanVas = id
      ? paymentWithoutLoanVas + vasMap.current[id].price
      : paymentWithoutLoanVas;
    setValue('totalVasMonthlyPayment', paymentWithSelectedLoanVas);
  };

  const handleButtonClick = () => {
    handleTotalPaymentUpdate(undefined);
    resetField('vasLoanInsuranceType', {
      defaultValue: '',
    });
    resetField(`vasLoanInsurance`, {
      defaultValue: '',
    });
  };

  return (
    <Col alignCross="stretch" className={styles.panel}>
      <Row alignCross="start" alignMain="between" gaps="s" margin="0 0 12px">
        <div className={styles.itemTitle} data-qa-data="loan-vas-title">
          Loan protection
        </div>
        <div className={styles.buttonContainer}>
          {selectedLoanVas && (
            <ButtonLink
              dataQaType="clear-loan-vas"
              disableHorizontalPadding
              disableVerticalPadding
              size="m"
              onClick={handleButtonClick}
            >
              Clear all
            </ButtonLink>
          )}
        </div>
      </Row>

      <Controller
        name="vasLoanInsurance"
        render={(fieldProps) => {
          const onFieldChange = fieldProps.field.onChange;
          const onChange = (value: string) => {
            // firstly update total monthly payment
            handleTotalPaymentUpdate(value);
            // then update selected vasId
            onFieldChange(value);
            setValue('vasLoanInsuranceType', vasMap.current[value].type);
          };
          // eslint-disable-next-line no-param-reassign
          fieldProps.field.onChange = onChange;
          return (
            <RadioGroup
              {...fieldProps}
              wide
              vertical
              separated={false}
              borderType="under"
              options={vasList.map((item) => ({
                value: item.id,
                label: <VasLabel {...item} isBundle={isLoanBundle(item)} />,
                description: (
                  <VasPriceInfo
                    monthlyPayment={getPriceFromValueWithCurrency(
                      item.monthlyPayment
                    )}
                    monthlyPaymentForAllChildVasList={
                      isLoanBundle(item)
                        ? getPriceFromValueWithCurrency(
                            item.monthlyPaymentForAllChildVasList
                          )
                        : undefined
                    }
                  />
                ),
              }))}
            />
          );
        }}
      />
    </Col>
  );
};
