import { useRef } from 'react';

import type { ApplicationDetails } from '~entities/application';

import { Col, Row } from '@breeze-platform-ui/layout';
import Text from '@breeze-platform-ui/text';

import { ApplicationItem } from './application-item/application-item';
import { PendingApplications } from './pending-applications/pending-applications';

import { getApplicationDateString, getSortedApplicationsList } from '../lib';

type Props = {
  pending: ApplicationDetails[];
  applications: ApplicationDetails[];
};

const DateItem = ({ date }: { date: string }) => (
  <Row margin="8px">
    <Text bold size={15}>
      {date}
    </Text>
  </Row>
);

export const ApplicationsList = ({ applications, pending }: Props) => {
  const applicationDate = useRef<string>('');

  const getDateHeader = (statusChangedUTC: string, index: number) => {
    let dateHeader = <></>;
    const date = getApplicationDateString(statusChangedUTC);
    if (date !== applicationDate.current || index === 0) {
      applicationDate.current = date;
      dateHeader = <DateItem date={date} />;
    }
    return dateHeader;
  };

  return (
    <Col alignCross="stretch" margin="0 0 56px" data-qa-type="application-list">
      {pending.length > 0 && <PendingApplications applications={pending} />}
      {getSortedApplicationsList(applications).map((application, index) => {
        return (
          <Col key={application.id} width="100%">
            {getDateHeader(application.applicationStatusChangedUTC, index)}
            <ApplicationItem application={application} />
          </Col>
        );
      })}
    </Col>
  );
};
