import { Warning } from '~shared/ui/warning';

import Button from '@breeze-platform-ui/button';

import errorImage from './images/self-app-warning.svg';

type Props = {
  onClose: () => void;
};

export const SelfAppPreApprovedIncorrect = ({ onClose }: Props) => (
  <Warning
    icon={errorImage}
    title="Customer’s pre-approved application has already been used"
    footerContent={
      <Button size="m" wide onClick={onClose}>
        Ok
      </Button>
    }
  />
);
