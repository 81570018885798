import { useNavigate } from 'react-router-dom';

import { environment } from '~app/environment';

import { AgentNewsList } from '~widgets/agent-news-list';

import { useGetNewsListQuery } from '~entities/agent-news';

import { HeaderWithLogo } from '~shared/ui/header-with-logo';
import { Loader } from '~shared/ui/loader';
import { NavigationFooter } from '~shared/ui/navigation-footer';
import { Screen } from '~shared/ui/screen';
import { ScreenTitle } from '~shared/ui/screen-title';

import Text from '@breeze-platform-ui/text';

export const AgentNewsListPage = () => {
  const navigate = useNavigate();
  const { data, isLoading, isError, isSuccess } = useGetNewsListQuery({
    enabled: environment.AGENT_NEWS_FEATURE === 'ON',
  });

  const getContent = () => {
    if (isLoading) {
      return <Loader />;
    }
    if (isError) {
      return (
        <Text size={15} align="center">
          Couldn’t load the news. Please try again later.
        </Text>
      );
    }
    if (isSuccess) {
      return (
        <AgentNewsList
          agentNewsList={data.items}
          onItemClick={(id) => navigate(`/agent-news/${id}`)}
        />
      );
    }
  };

  return (
    <Screen header={<HeaderWithLogo />} footer={<NavigationFooter />}>
      <ScreenTitle title="News" margin="0 8px 16px" />
      {getContent()}
    </Screen>
  );
};
