import type { ApplicationDetails } from '~entities/application';

export const getSortedApplicationsList = (applications: ApplicationDetails[]) =>
  applications.sort((item1, item2) => {
    if (item1.applicationStatusChangedUTC < item2.applicationStatusChangedUTC) {
      return 1;
    }
    if (item1.applicationStatusChangedUTC > item2.applicationStatusChangedUTC) {
      return -1;
    }
    return 0;
  });
