import type { Validate } from 'react-hook-form';

import type { FileItem } from '@breeze/rhf-adapters';
import { FILE_STATUS } from '@breeze/rhf-adapters';

type Options = {
  message: string;
};

export function fileRequired(
  options: Options = { message: 'File is required' }
): Validate<FileItem | FileItem[] | undefined> {
  return function fileRequiredValidator(value): string | boolean | undefined {
    const file = value && Array.isArray(value) ? value[0] : (value as FileItem);

    if (!file) {
      return options.message;
    }

    if (file.status === FILE_STATUS.ERROR) {
      return false;
    }

    return undefined;
  };
}
