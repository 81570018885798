import { environment } from '~app/environment';

import type { EmploymentStatusValue } from '~widgets/loan-application-form/employment/lib';

import { nonNullableValue } from '~shared/types/non-nullable-value';

import { createMachine } from 'xstate';

import type { FormValues, StepName } from '../lib';
import { stepNames } from '../lib';

export const StepFlowMachine = (
  initialStep: StepName
): ReturnType<typeof createMachine> => {
  return createMachine({
    id: 'cash-loan-application-form',
    predictableActionArguments: true,
    initial: initialStep,
    states: {
      [stepNames.idDocumentType]: {
        on: {
          next: {
            target: stepNames.idDocument,
          },
        },
      },
      [stepNames.idDocument]: {
        on: {
          next: [
            {
              target: stepNames.additionalIdDocument,
              cond: (formValues: any) => {
                const { idDocumentType } = formValues as FormValues;
                const type = nonNullableValue(
                  idDocumentType?.typeOther || idDocumentType?.typeMain
                );

                return !['UMID', 'SSS'].includes(type);
              },
            },
            {
              target: stepNames.clientPhoto,
              cond: (formValues: any) => {
                const { idDocumentType } = formValues as FormValues;
                const type = nonNullableValue(
                  idDocumentType?.typeOther || idDocumentType?.typeMain
                );

                return ['UMID', 'SSS'].includes(type);
              },
            },
          ],
        },
      },
      [stepNames.additionalIdDocument]: {
        on: {
          next: {
            target: stepNames.clientPhoto,
          },
        },
      },
      [stepNames.clientPhoto]: {
        on: {
          next: {
            target: stepNames.personalDetails,
          },
        },
      },
      [stepNames.personalDetails]: {
        on: {
          next: {
            target: stepNames.residentialAddress,
          },
        },
      },
      [stepNames.residentialAddress]: {
        on: {
          next: {
            target: stepNames.permanentAddress,
          },
        },
      },
      [stepNames.permanentAddress]: {
        on: {
          next: {
            target: stepNames.employmentDetails,
          },
        },
      },
      [stepNames.employmentDetails]: {
        on: {
          next: [
            /**
             * TODO: rewrite it later, added just to make
             * transitions work
             */
            {
              target: stepNames.additionalEmploymentDetails,
              cond: (formValues: any) =>
                !['OFW', 'UNEMPLOYED'].includes(
                  formValues.employmentDetails
                    ?.employmentStatus as EmploymentStatusValue
                ),
            },
            {
              target: stepNames.finances,
              cond: (formValues: any) =>
                ['OFW', 'UNEMPLOYED'].includes(
                  formValues.employmentDetails
                    ?.employmentStatus as EmploymentStatusValue
                ),
            },
          ],
        },
      },
      [stepNames.additionalEmploymentDetails]: {
        on: {
          next: {
            target: stepNames.finances,
          },
        },
      },
      [stepNames.finances]: {
        on: {
          next: {
            target: stepNames.alternativeContacts,
          },
        },
      },
      [stepNames.alternativeContacts]: {
        on: {
          next: [
            {
              target: stepNames.gCashDetails,
              cond: () => environment.COLLECT_GCASH_DATA_FEATURE === 'ON',
            },
            {
              target: stepNames.clientPhoneAssessment,
              cond: () => environment.COLLECT_GCASH_DATA_FEATURE !== 'ON',
            },
          ],
        },
      },
      [stepNames.gCashDetails]: {
        on: {
          next: {
            target: stepNames.clientPhoneAssessment,
          },
        },
      },
      [stepNames.clientPhoneAssessment]: {
        on: {
          next: {
            target: stepNames.clientAssessment,
          },
        },
      },
      [stepNames.clientAssessment]: {
        type: 'final',
      },
    },
  });
};
