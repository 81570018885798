export function mergeFio(
  firstName?: string | null,
  lastName?: string | null,
  middleName?: string | null
): string {
  if (!firstName || !lastName) {
    return '';
  }

  return middleName
    ? `${firstName} ${middleName} ${lastName}`
    : `${firstName} ${lastName}`;
}
