import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { getMaxItemsAmount } from '~features/manage-order-items/lib/get-max-items-amount';

import type { OrderItem } from '~entities/order-item';
import { useGetItemsCategoriesQuery } from '~entities/product';

import { phMoneyProps } from '~shared/constants/ph-money-props';
import { combineValidators } from '~shared/lib/combine-validators';
import { SectionTitle } from '~shared/ui/section-title';
import { validators } from '~shared/validators';

import { Col } from '@breeze-platform-ui/layout';
import { NotificationInline } from '@breeze-platform-ui/notification';
import { Input, InputCount, InputMoney, Select } from '@breeze/rhf-adapters';

import { OrderItemConverter } from './order-item-converter';

import {
  CATEGORY_LABEL,
  NAME_LABEL,
  PRICE_LABEL,
  QUANTITY_LABEL,
  CATEGORY_REQUIRED_ERROR,
  NAME_REQUIRED_ERROR,
  PRICE_REQUIRED_ERROR,
  QUANTITY_REQUIRED_ERROR,
} from '../../constants';
import { ItemFormContainer } from '../item-form-container/item-form-container';

export type FormValue = {
  name: string;
  price: string;
  category: string;
  quantity: number | string;
};

type Props = {
  initialValue?: Partial<OrderItem>;
  totalItemsAmount?: number;
  onSubmit: (value: OrderItem) => void;
  onCancel: () => void;
};

export const LoanItemForm: React.FC<Props> = ({
  initialValue,
  totalItemsAmount = 0,
  onSubmit,
  onCancel,
}) => {
  const { control, handleSubmit, reset } = useForm<FormValue>({
    mode: 'all',
    defaultValues: OrderItemConverter.toFormValue(initialValue),
  });
  const { data: categories = [], isLoading } = useGetItemsCategoriesQuery();

  useEffect(() => {
    reset(OrderItemConverter.toFormValue(initialValue));
  }, [initialValue, reset]);

  const handleSubmitAndReset = (value: FormValue) => {
    onSubmit(OrderItemConverter.fromFormValue(value));
    reset(OrderItemConverter.toFormValue({}));
  };

  const newItem = !initialValue?.name;
  const title = <span>{newItem ? 'Add' : 'Edit'} an&nbsp;item</span>;
  const categoryOptions = categories.map(
    ({ code, displayName, displayDetails }) => ({
      title: displayName,
      description: displayDetails,
      value: code,
      checkboxed: false,
    })
  );
  const maxAvailableItems = getMaxItemsAmount('LOAN') - totalItemsAmount;

  return (
    <form
      onBlur={(event) => event.stopPropagation()}
      onSubmit={(event, ...restArgs) => {
        event.stopPropagation();
        handleSubmit(handleSubmitAndReset)(event, ...restArgs);
      }}
    >
      <ItemFormContainer onBack={onCancel}>
        <SectionTitle title={title} margin="0 0 24px 0" />
        <Col gaps={20} alignCross="stretch">
          <NotificationInline
            showClose={false}
            type="warning"
            animateFirstRender={false}
            hideOnAction={false}
            hideOnCancel={false}
            timer={false}
          >
            Check if the&nbsp;item is&nbsp;in&nbsp;stock
          </NotificationInline>
          <Controller
            control={control}
            name="category"
            rules={{ required: CATEGORY_REQUIRED_ERROR }}
            render={(fieldProps) => (
              <Select
                label={CATEGORY_LABEL}
                {...fieldProps}
                options={categoryOptions}
                loading={isLoading}
              />
            )}
          />
          <Controller
            control={control}
            name="name"
            rules={{
              validate: combineValidators(
                validators.required({
                  text: NAME_REQUIRED_ERROR,
                }),
                validators.maxLength({ maxLength: 300 }),
                validators.latinText()
              ),
            }}
            render={(fieldProps) => (
              <Input label={NAME_LABEL} {...fieldProps} />
            )}
          />
          <Controller
            control={control}
            name="price"
            rules={{ required: PRICE_REQUIRED_ERROR }}
            render={(fieldProps) => (
              <InputMoney
                label={PRICE_LABEL}
                currencyInValue
                precision={2}
                max={999999}
                {...phMoneyProps}
                {...fieldProps}
              />
            )}
          />

          <Controller
            control={control}
            name="quantity"
            rules={{
              validate: validators.required({ text: QUANTITY_REQUIRED_ERROR }),
            }}
            render={(fieldProps) => (
              <InputCount
                label={QUANTITY_LABEL}
                min={1}
                max={maxAvailableItems}
                {...fieldProps}
              />
            )}
          />
        </Col>
      </ItemFormContainer>
    </form>
  );
};
