import { useNavigate } from 'react-router-dom';

import { recoverLoanCalculator } from '~widgets/loan-calculator';

import type { Application } from '~entities/application';
import {
  FindOrCreateApplicationErrorCode,
  isFindOrCreateApplicationError,
} from '~entities/auth';
import type { FindOrCreateApplicationError } from '~entities/auth';

import { useOnMountEffect } from '~shared/hooks';
import errorImage from '~shared/images/error.svg';
import { Warning } from '~shared/ui/warning';

import Button from '@breeze-platform-ui/button';
import { useNotifications } from '@pfa/front-notifications';

type Props = {
  error: FindOrCreateApplicationError;
  onClose: () => void;
  application?: Application;
};

const createApplicationErrorDescription: Partial<
  Record<FindOrCreateApplicationErrorCode, React.ReactNode>
> = {
  [FindOrCreateApplicationErrorCode.activeAgreement]: (
    <span>
      The customer already has a&nbsp;loan with Skyro or an&nbsp;active loan
      application
    </span>
  ),
  [FindOrCreateApplicationErrorCode.activeApplication]: (
    <span>
      The customer already has a&nbsp;loan with Skyro or an&nbsp;active loan
      application
    </span>
  ),
  [FindOrCreateApplicationErrorCode.activeLoanWithOffer]: (
    <span>
      The customer already has a&nbsp;loan with Skyro or an&nbsp;active loan
      application
    </span>
  ),
  [FindOrCreateApplicationErrorCode.rejectionCooldown]: (
    <span>
      The customer has recently applied for&nbsp;a&nbsp;loan with Skyro. We
      recommend this customer to&nbsp;reapply 30&nbsp;days after
      the&nbsp;previous application.
    </span>
  ),
};

export const CreateApplicationError: React.FC<Props> = ({
  error,
  application,
  onClose,
}) => {
  const notifications = useNotifications();
  const navigate = useNavigate();

  useOnMountEffect(() => {
    if (
      isFindOrCreateApplicationError(error) &&
      error.code === FindOrCreateApplicationErrorCode.inactiveOffer
    ) {
      notifications.error('The special offer has expired', {
        showClose: true,
        timer: false,
      });

      navigate('/loan-calculator', {
        state: {
          loanCalculator: application && recoverLoanCalculator(application),
        },
      });
    }
  });

  return (
    <Warning
      icon={errorImage}
      title="Can’t apply for&nbsp;a&nbsp;loan now"
      description={createApplicationErrorDescription[error.code]}
      footerContent={
        <Button size="m" wide onClick={onClose}>
          Got it
        </Button>
      }
    />
  );
};
