import { assign, createMachine } from 'xstate';

type OtpAuthContext = {
  result?: unknown;
};

type OtpAuthEvent = { type: 'ERROR' };

type OtpAuthTypestate =
  | {
      value: 'initialization';
      context: OtpAuthContext;
    }
  | {
      value: 'initialized';
      context: OtpAuthContext;
    }
  | {
      value: 'success';
      context: OtpAuthContext;
    }
  | {
      value: 'confirmation';
      context: OtpAuthContext;
    }
  | {
      value: 'error';
      context: OtpAuthContext;
    };

export const otpAuthStatesMachine = createMachine<
  OtpAuthContext,
  OtpAuthEvent,
  OtpAuthTypestate
>({
  schema: {
    services: {} as {
      initialize: { data: undefined };
    },
  },
  id: 'auth-process',
  initial: 'initialization',
  predictableActionArguments: true,
  states: {
    initialization: {
      invoke: {
        src: 'initialize',
        onDone: {
          target: 'initialized',
          actions: assign<OtpAuthContext, { type: string; data: Error }>({
            result: (
              _context: OtpAuthContext,
              event: { type: string; data: Error }
            ) => event.data,
          }),
        },
        onError: 'error',
      },
    },
    initialized: {
      always: [
        {
          target: 'success',
          cond: 'isSuccessResult',
        },
        {
          target: 'confirmation',
        },
      ],
    },
    success: {},
    confirmation: {
      on: {
        ERROR: { target: 'error' },
      },
    },
    error: {},
  },
});
