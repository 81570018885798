import { FileValidationErrorCode } from '../api/file-upload-api';

export const isFileValidationCode = (
  errorCode = ''
): errorCode is FileValidationErrorCode =>
  (Object.values(FileValidationErrorCode) as string[]).includes(errorCode);

export const fileValidationErrorMessageMap: Record<
  FileValidationErrorCode,
  string
> = {
  [FileValidationErrorCode.TooLargeImageSize]: 'The maximum file size is 4 MB',
  [FileValidationErrorCode.RequestEntityTooLarge]:
    'The maximum file size is 4 MB',
  [FileValidationErrorCode.UnsupportedImageType]: 'Must be a .jpg image',
  [FileValidationErrorCode.LimitsResolution]:
    'Must be larger than 256 x 256 pixels and smaller than 4096 x 4096 pixels',
};
