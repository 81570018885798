import type { OrderItem } from '..';

export function decoupleOrderItems(items: OrderItem[]) {
  return items.reduce((decoupledItems, item) => {
    for (let i = 0; i < item.quantity; i++) {
      decoupledItems.push({ ...item, quantity: 1 });
    }

    return decoupledItems;
  }, [] as OrderItem[]);
}
