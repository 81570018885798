import type { ApplicationProcessStatus } from '~entities/application';

import styles from './status-indicator.module.css';

type Props = {
  processStatus: ApplicationProcessStatus;
};

export const StatusIndicator = ({ processStatus }: Props) => {
  return (
    <span className={`${styles.status} ${styles[processStatus.status]}`} />
  );
};
