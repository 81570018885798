import { useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { FinalLoanCalculatorForm } from '~widgets/final-calculator/ui/final-calculator-form';

import {
  useGetApplicationByIdQuery,
  useConfirmProductMutation,
} from '~entities/application';

import { nonNullableValue } from '~shared/types/non-nullable-value';
import { Loader } from '~shared/ui/loader';
import { NavBackButton } from '~shared/ui/nav-back-button/nav-back-button';
import { Screen } from '~shared/ui/screen';

export const ProductConfirmationPage = () => {
  const navigate = useNavigate();
  const { id = '' } = useParams();
  const { data, isLoading, isError } = useGetApplicationByIdQuery(id);

  const {
    mutate,
    isLoading: isConfirmProductLoading,
    isSuccess: isConfirmProductSuccess,
  } = useConfirmProductMutation(id);

  const goToApplicationDetails = useCallback(
    () => navigate(`/applications/${id}`, { replace: true }),
    [navigate, id]
  );

  useEffect(() => {
    if (isConfirmProductSuccess) {
      navigate(`/applications/${id}/loan-details`, { replace: true });
    }
  }, [isConfirmProductSuccess, id, navigate]);

  useEffect(() => {
    if (data?.agreementId) {
      goToApplicationDetails();
    }
  }, [data, goToApplicationDetails]);

  if (isLoading) {
    return (
      <Screen header={<NavBackButton onClick={goToApplicationDetails} />}>
        <Loader overlay alignTop="100px" />
      </Screen>
    );
  }
  if (isError) {
    return (
      <Screen header={<NavBackButton onClick={goToApplicationDetails} />}>
        Something went wrong
      </Screen>
    );
  }

  if (data && !data.agreementId) {
    return (
      <FinalLoanCalculatorForm
        approvedProductOptions={data.approvedProductOptions || []}
        requestedLoan={nonNullableValue(data.requestedProduct)}
        isLoading={isConfirmProductLoading}
        onSubmit={mutate}
        onClose={goToApplicationDetails}
      />
    );
  }

  return null;
};
