import type { OrderItem } from '~entities/order-item';

import type { FormValue } from './loan-item-form';

const defaultValue = { quantity: 1 } as Partial<OrderItem>;

export const OrderItemConverter = {
  toFormValue(item: Partial<OrderItem> = defaultValue): Partial<FormValue> {
    return {
      name: item.name,
      category: item.category,
      quantity: `${item.quantity || 1}`,
      price: item.price,
    };
  },

  fromFormValue(value: FormValue): OrderItem {
    return {
      name: value.name,
      category: value.category,
      quantity: value.quantity ? +value.quantity : 1,
      price: value.price,
    };
  },
};
