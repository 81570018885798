import { type QueryClient } from '@tanstack/react-query';

import { type AgentNewsDetails } from '../agent-news';
import { type NewsItemsQueryData } from '../model';

export const markReadNewsInQueryCache = (
  queryClient: QueryClient,
  ids: string[]
) => {
  const idsSet = new Set(ids);
  const newsList = queryClient.getQueryData<NewsItemsQueryData>(['agent-news']);
  if (newsList) {
    let { unreadCount } = newsList;
    const items = newsList.items.map((item) => {
      if (!item.isRead && idsSet.has(item.id)) {
        unreadCount--;
        return { ...item, isRead: true };
      }
      return item;
    });
    queryClient.setQueryData<NewsItemsQueryData>(['agent-news'], {
      unreadCount,
      items,
    });
  }

  ids.forEach((id) => {
    const newsItem = queryClient.getQueryData<AgentNewsDetails>([
      'agent-news-details',
      id,
    ]);
    if (newsItem) {
      queryClient.setQueryData<AgentNewsDetails>(['agent-news-details', id], {
        ...newsItem,
        isRead: true,
      });
    }
  });
};
