import type { ValidationRule } from 'react-hook-form';

import identity from '@tinkoff/utils/function/identity';

import type {
  MaskedInputOnChangeEvent,
  MaskedInputOnChangeParams,
} from '@breeze-platform-ui/input';

import type { AdditionalIdDocumentTypeValue } from './additional-id-document-options';

import { DOCUMENT_NUMBER_FIELD_NAME, NUMBER_FORMAT_ERROR } from '../constants';

type AdditionalIdDocumentMeta = {
  validators: Record<string, ValidationRule>;
  mask: Record<string, any>;
  transform: (
    event: MaskedInputOnChangeEvent,
    params: MaskedInputOnChangeParams<string>
  ) => string | MaskedInputOnChangeEvent;
  placeholder: string;
  label: string;
};

export const additionalIdDocumentMetaMap: {
  [key in AdditionalIdDocumentTypeValue]: AdditionalIdDocumentMeta;
} = {
  TIN: {
    validators: {
      pattern: {
        value: /^\d{3}-\d{3}-\d{3}(-\d{3})?$/,
        message: NUMBER_FORMAT_ERROR,
      },
    },
    mask: {
      value: '000-000-000-000',
      transitions: { 0: /\d/i },
    },
    placeholder: '000-000-000-000',
    label: DOCUMENT_NUMBER_FIELD_NAME,
    transform: (_event, { maskedValue }) =>
      maskedValue.endsWith('-')
        ? maskedValue.slice(0, maskedValue.length - 1)
        : maskedValue,
  },
  GSIS: {
    validators: {
      pattern: {
        value: /^\d{11}$/,
        message: NUMBER_FORMAT_ERROR,
      },
    },
    mask: {
      value: '00000000000',
      transitions: { 0: /\d/i },
    },
    placeholder: '00000000000',
    label: DOCUMENT_NUMBER_FIELD_NAME,
    transform: identity,
  },
  UMID: {
    validators: {
      pattern: {
        value: /^\d{4}-\d{7}-\d$/,
        message: NUMBER_FORMAT_ERROR,
      },
    },
    mask: {
      value: '0000-0000000-0',
      transitions: { 0: /\d/i },
    },
    placeholder: '0000-0000000-0',
    label: DOCUMENT_NUMBER_FIELD_NAME,
    transform: identity,
  },
  SSS: {
    validators: {
      pattern: {
        value: /^\d{2}-\d{7}-\d$/,
        message: NUMBER_FORMAT_ERROR,
      },
    },
    mask: {
      value: '00-0000000-0',
      transitions: { 0: /\d/i },
    },
    placeholder: '00-0000000-0',
    label: DOCUMENT_NUMBER_FIELD_NAME,
    transform: identity,
  },
};
