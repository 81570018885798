export const COMPANY_NAME_FIELD_NAME = 'Company name';
export const HAVE_EMPLOYEES_FIELD_NAME = 'Does the customer employ people?';
export const COMPANY_REFERENCE_FIELD_NAME = 'Company reference';
export const OTHER_REFERENCE_FIELD_NAME = 'Reference’s relationship to you';
export const REFERENCE_PHONE_FIELD_NAME = 'Reference’s mobile number';
export const REFERENCE_NAME_FIELD_NAME = 'Name of the reference';

export const EMPLOYMENT_STATUS_FIELD_NAME = 'Type of employment';
export const NATURE_OF_WORK_FIELD_NAME = 'Industry';
export const NATURE_OF_WORK_OTHER_FIELD_NAME = 'Industry name';
export const WORK_EXPIRIENCE_FIELD_NAME = 'Work experience';
export const UNEMPLOYED_REASON_OTHER_FIELD_NAME = 'Other reason';
