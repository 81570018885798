import { useState, useRef, useEffect } from 'react';

import styles from './video.module.css';

import playIconSrc from '../images/play-icon.svg';

type Props = {
  src: string;
};
export const Video = ({ src }: Props) => {
  const [isFirstPlay, setIsFirstPlay] = useState(true);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const [videoW, setVideoW] = useState(0);
  const [videoH, setVideoH] = useState(0);

  useEffect(() => {
    const video = videoRef.current;
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.contentRect) {
          setVideoW(entry.contentRect.width);
          setVideoH(entry.contentRect.height);
        }
      }
    });
    if (video) {
      resizeObserver.observe(video);
    }

    return () => {
      video && resizeObserver.unobserve(video);
    };
  }, []);

  return (
    <div
      style={{
        height: videoH,
        width: videoW,
        position: 'relative',
      }}
    >
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        src={src}
        ref={videoRef}
        autoPlay={false}
        className={styles.video}
        controls={!isFirstPlay}
        controlsList="nofullscreen"
      />
      {isFirstPlay && (
        <div className={styles.overlay}>
          <button
            type="button"
            className={styles.playButton}
            onClick={() => {
              setIsFirstPlay(false);
              videoRef.current?.play();
            }}
          >
            <img src={playIconSrc} alt="play" />
          </button>
        </div>
      )}
    </div>
  );
};
