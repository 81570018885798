export const CommuteIcon: React.FC<React.SVGAttributes<SVGSVGElement>> = (
  props
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="m7.773 18.481.996-2.325 2.421 2.421-.793 1.626a1.5 1.5 0 0 1-.3.416L7.1 23.541a1.505 1.505 0 0 1-2.11-2.148l2.46-2.437a1.5 1.5 0 0 0 .323-.475Zm1.175-8.476 1.242-.493-.849 3.873c-.05.23.01.47.164.649l.013.015 2.499 2.826-.008.024.01-.021 1.539 2.074c.115.155.2.33.247.518l.867 3.353a1.505 1.505 0 0 0 1.846 1.072 1.505 1.505 0 0 0 1.073-1.814l-1-3.996a1.5 1.5 0 0 0-.282-.571l-2.55-3.2.791-3.081.455 1.207c.1.264.272.494.496.665l1.59 1.208a1.5 1.5 0 0 0 1.817-2.389l-1.177-.896a1.5 1.5 0 0 1-.478-.623l-1.016-2.473a1.367 1.367 0 0 0-.66-.707l.002-.006s-1.204-.818-2.085-1.055c-.917-.247-2.374-.09-2.54-.072-.184 0-.372.033-.554.105L6.984 7.545a.469.469 0 0 0-.203.155L5.228 9.77a1.501 1.501 0 0 0 2.4 1.804l.998-1.324a.75.75 0 0 1 .322-.245Zm4.944-4.755a2.625 2.625 0 1 0 0-5.25 2.625 2.625 0 0 0 0 5.25Z"
    />
  </svg>
);
