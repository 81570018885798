import AttachFilePlatform from '@breeze-platform-ui/attachFile';
import Button from '@breeze-platform-ui/button';
import { Col, Row } from '@breeze-platform-ui/layout';
import Text from '@breeze-platform-ui/text';
import { FILE_STATUS, type FileItem } from '@breeze/rhf-adapters';
import { useNotifications } from '@pfa/front-notifications';
import { TuiIconTdsMediumCameraPhoto } from '@tui-react/proprietary-icons';

import styles from './confirm-client-with-item.module.css';

export const CofirmClientWithItem: React.FC<{
  clientPhoto?: FileItem;
  onPhotoUpdate: (file?: FileItem) => void;
  onPhotoSubmit: () => void;
}> = ({ clientPhoto, onPhotoUpdate, onPhotoSubmit }) => {
  const notifications = useNotifications();

  const handleSubmit = () => {
    if (clientPhoto?.status === FILE_STATUS.LOADING) {
      notifications.warning('The file is still loading', {
        showClose: true,
        timer: 3_000,
      });
      return;
    }

    onPhotoSubmit();
  };

  return (
    <Col alignCross="stretch" gaps={24}>
      <Row alignMain="center" margin="0 0 24px">
        <TuiIconTdsMediumCameraPhoto
          theme={{
            color: 'var(--tds-color-primary)',
            background: 'var(--tds-color-info-bg)',
          }}
          withContainer
          iconSize={32}
          containerSize={64}
        />
      </Row>

      <div>
        <h2 className={styles.header} data-qa-type="downpayment-message">
          Take a&nbsp;photo of the customer with&nbsp;the&nbsp;item
        </h2>

        <Text align="center" size={15}>
          Make sure that the&nbsp;customer&apos;s face is clearly visible. There
          must be only one face in&nbsp;the&nbsp;photo
        </Text>
      </div>

      <AttachFilePlatform
        labels={{
          common: 'Take a photo',
          desktop: ' ',
          dragging: ' ',
        }}
        onAdd={async (_e, data) => onPhotoUpdate(data.files[0] as FileItem)}
        onRemove={(_e) => {
          onPhotoUpdate(undefined);
        }}
        files={clientPhoto ? [clientPhoto] : undefined}
        sizeUnits={['B', 'KB', 'MB', 'GB']}
        preview
        single
        accept="image/*"
        capture="environment"
      />
      {clientPhoto?.status === FILE_STATUS.SUCCESS && (
        <Button size="m" wide onClick={handleSubmit}>
          Submit
        </Button>
      )}
    </Col>
  );
};
