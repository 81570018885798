import type { OrderItem } from '~entities/order-item';
import type { ZeroRateItem } from '~entities/product';
import type { AvailableItemInsurance } from '~entities/vas';

import type { LoanItemInfo } from '../types';

// Here we need to prepare the list of
// 1. all items that have available item vases (so that the user could select item vas for them on the loan details screen)
// 2. remaining zero rate items with brand producer (so that the agent could fill in manufacturer id for them)
// Prepared item should contain manufacturerIdType and vasList where possible
export const getLoanItemsDetailsList = (
  orderItems: OrderItem[],
  vasList: AvailableItemInsurance[],
  zeroRateItems: Record<string, ZeroRateItem>
): LoanItemInfo[] => {
  const listWithVas: LoanItemInfo[] = [];
  const remainingZeroRateList: LoanItemInfo[] = [];
  const availableItemVasWithVisited = vasList.map((vas) => ({
    ...vas,
    visited: false,
  }));

  orderItems.forEach((item) => {
    const availableItemVas = availableItemVasWithVisited.find(
      ({ name, category, visited }) =>
        name === item.name && category === item.category && !visited
    );
    const isNonRetailerZeroRateItem =
      item.zeroRateItemId &&
      zeroRateItems[item.zeroRateItemId]?.producerType !== 'RETAILER';

    const shouldAddItem = availableItemVas || isNonRetailerZeroRateItem;

    if (!shouldAddItem) {
      return;
    }

    // decoupled order items, should be matched each to its own item vas, with orderItemId
    if (item.quantity <= 1) {
      const array = availableItemVas ? listWithVas : remainingZeroRateList;
      array.push({
        name: item.name,
        category: item.category,
        price: item.price,
        zeroRateItemId: item.zeroRateItemId,
        type: item.zeroRateItemId
          ? zeroRateItems[item.zeroRateItemId]?.manufacturerIdType
          : undefined,
        vasList: availableItemVas?.vasList || [],
        producerType: item.zeroRateItemId
          ? zeroRateItems[item.zeroRateItemId]?.producerType
          : undefined,
        orderItemId: availableItemVas?.orderItemId,
      });
    }

    // TODO: remove when orderItems are decoupled for all applications
    // old order items (uncoupled, multiple quantity), should be all matched to the same item vas, no orderItemId
    if (item.quantity > 1) {
      for (let i = 0; i < item.quantity; i++) {
        const array = availableItemVas ? listWithVas : remainingZeroRateList;
        array.push({
          name: item.name,
          category: item.category,
          price: item.price,
          zeroRateItemId: item.zeroRateItemId,
          type: item.zeroRateItemId
            ? zeroRateItems[item.zeroRateItemId]?.manufacturerIdType
            : undefined,
          vasList: availableItemVas?.vasList || [],
          producerType: item.zeroRateItemId
            ? zeroRateItems[item.zeroRateItemId]?.producerType
            : undefined,
        });
      }
    }

    if (availableItemVas) {
      availableItemVas.visited = true;
    }
  });

  return [...listWithVas, ...remainingZeroRateList];
};
