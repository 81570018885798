import type { IncentiveProgramStep } from '~entities/agent-incentive';

import { Row, Col } from '@breeze-platform-ui/layout';
import Text from '@breeze-platform-ui/text';

import styles from './bonus-calculation-details.module.css';

import attentionSrc from '../../images/attention.webp';

type Props = {
  steps: IncentiveProgramStep[];
};

const getLevelTitle = (level: IncentiveProgramStep) => {
  if (level.lowerBound === 0 && level.upperBound) {
    return `Less than ${level.upperBound + 1} loans`;
  }
  if (!level.upperBound) {
    return `${level.lowerBound}+ loans`;
  }
  return `${level.lowerBound}–${level.upperBound} loans`;
};

export const BonusCalculationDetails = ({ steps }: Props) => {
  return (
    <>
      <Col alignCross="stretch" gaps={24}>
        <Col alignCross="stretch" gaps={24}>
          <h1 className={styles.title}>Bonuses per loans</h1>
          {steps.map((level) => (
            <Row
              alignCross="center"
              alignMain="between"
              key={level.bonusAmountPerApplication}
            >
              <div className={styles.text}>{getLevelTitle(level)}</div>
              <div className={styles.text}>
                ₱{level.bonusAmountPerApplication} per loan
              </div>
            </Row>
          ))}
        </Col>
        <Col alignCross="stretch" gaps={6}>
          <h1 className={styles.title}>What is a “suspicious application”?</h1>
          <div className={styles.text}>
            These are customers’ applications that you marked as suspicious
            during customer assessment.
          </div>
        </Col>
        <Row className={styles.notification} gaps={12}>
          <img src={attentionSrc} alt="attention" width={24} height={24} />
          <Text>
            Note that the&nbsp;current utilization bonus amount
            doesn’t&nbsp;take into account your risk malus
          </Text>
        </Row>
      </Col>
    </>
  );
};
