import type { OcrResult } from '~entities/file';

import identity from '@tinkoff/utils/function/identity';
import capitalize from '@tinkoff/utils/string/capitalize';

import { format } from 'date-fns';

import type { PreFillableFieldName } from '../types';

export const ocrBirthDateToForm = (ocrDate: string) => {
  const date = new Date(ocrDate);
  if (Number.isNaN(date.getTime())) {
    return '';
  }
  return format(date, 'MM/dd/yyyy');
};

export type OcrPrefillingFields = keyof Pick<
  OcrResult,
  'firstName' | 'lastName' | 'middleName' | 'birthday' | 'idNumber'
>;

export type OcrToFormMapping = {
  [key in OcrPrefillingFields]: {
    field: PreFillableFieldName;
    format: (ocrValue: string) => string;
  };
};

export const ocrToFormMapping: OcrToFormMapping = {
  firstName: {
    field: 'firstName',
    format: capitalize,
  },
  lastName: {
    field: 'lastName',
    format: capitalize,
  },
  middleName: {
    field: 'middleName',
    format: capitalize,
  },
  idNumber: {
    field: 'idNumber',
    format: identity,
  },
  birthday: {
    field: 'birthDate',
    format: ocrBirthDateToForm,
  },
};
