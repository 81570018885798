import type { ApplicationProcessStatus } from '~entities/application';

import Island from '@breeze-platform-ui/island';

import { ProcessStatusTitle } from '../process-status-title/process-status-title';

// TODO: calculate the date for a new application
type Props = {
  processStatus: ApplicationProcessStatus;
};
export const Rejected = ({ processStatus }: Props) => {
  return (
    <Island
      theme="shadow"
      text="The&nbsp;customer can apply again later"
      title={<ProcessStatusTitle processStatus={processStatus} />}
    />
  );
};
