import { useEffect } from 'react';
import {
  useNavigate,
  useParams,
  useSearchParams,
  useLocation,
} from 'react-router-dom';

import { useCustomerAnalytics } from '~app/providers/analytics-provider';

import { useGetFileQuery } from '~entities/document';

import { GeneralError } from '~shared/ui/general-error';
import { NavBackButton } from '~shared/ui/nav-back-button/nav-back-button';

import { LoadingButton } from '@breeze-platform-ui/button';
import { Row, Col } from '@breeze-platform-ui/layout';

import { AgreementDocument } from './agreement-document/agreement-document';
import { AgreementSkeleton } from './agreement-skeleton/agreement-skeleton';
import styles from './agreement.module.css';

type NavigationState = {
  vasAvailable: boolean;
};

export const ApplicationAgreement = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { state: navigationState } = useLocation();
  const back = searchParams.get('back') || '';
  const customerAnalytics = useCustomerAnalytics();

  const {
    data: agreement,
    isError,
    isLoading,
    refetch,
  } = useGetFileQuery(id as string);

  const { vasAvailable } = (navigationState as NavigationState) || {};

  useEffect(() => {
    id && customerAnalytics.trackAgreementShown({ applicationId: id });
  }, [customerAnalytics, id]);

  const handleAgreementSigning = () => {
    id &&
      customerAnalytics.trackSignAgreementButtonTap({
        applicationId: id,
        vasAvailable,
      });
    navigate(`/applications/${id}/agreement-signing`);
  };

  return (
    <Col width="100%" alignCross="center" alignMain="center">
      <Row className={styles.header}>
        <NavBackButton href={`/applications/${id}/${back}`} />
      </Row>
      {agreement && (
        <AgreementDocument applicationId={id as string} agreement={agreement} />
      )}
      {isLoading && <AgreementSkeleton />}
      {isError && <GeneralError onReload={refetch} />}
      {!isError && (
        <Row className={styles.footer}>
          <LoadingButton
            size="l"
            wide
            loading={!agreement}
            disabled={!agreement}
            onClick={handleAgreementSigning}
          >
            Sign the agreement
          </LoadingButton>
        </Row>
      )}
    </Col>
  );
};
