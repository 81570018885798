import type { BreakStatus, IdleStatus, WorkingStatus } from '../attendance';
import { AttendanceStatus } from '../attendance';

const workingStatuses = [
  AttendanceStatus.CheckIn,
  AttendanceStatus.BreakOut,
  AttendanceStatus.CommuteOut,
  AttendanceStatus.LunchOut,
];

const breakStatuses = [
  AttendanceStatus.BreakIn,
  AttendanceStatus.CommuteIn,
  AttendanceStatus.LunchIn,
];

const idleStatuses = [...breakStatuses, AttendanceStatus.CheckOut];

export const isWorkingStatus = (
  status: AttendanceStatus
): status is WorkingStatus => workingStatuses.includes(status);

export const isIdleStatus = (status: AttendanceStatus): status is IdleStatus =>
  idleStatuses.includes(status);

export const isBreakStatus = (
  status: AttendanceStatus
): status is BreakStatus => breakStatuses.includes(status);
