import { Controller, useForm } from 'react-hook-form';

import { FormFooter } from '~shared/ui/form-footer';
import { ScreenTitle } from '~shared/ui/screen-title';

import { Col } from '@breeze-platform-ui/layout';
import { Textarea } from '@breeze/rhf-adapters';

import { type SurveyFormValues } from '../../types';

type RadioGroupQuestionProps = {
  question: string;
  placeholder?: string;
  initialValue?: string;
  isLastScreen: boolean;
  onPrev: (values: SurveyFormValues) => void;
  onSubmit: (values: SurveyFormValues) => void;
  onFieldCompleted?: (values: SurveyFormValues) => void;
};

export const TextAreaQuestionForm = ({
  question,
  placeholder,
  initialValue,
  isLastScreen,
  onFieldCompleted,
  onSubmit,
  onPrev,
}: RadioGroupQuestionProps) => {
  const methods = useForm<{ answer: string }>({
    mode: 'all',
    defaultValues: { answer: initialValue },
  });

  const handleBlur = () => {
    onFieldCompleted?.({ [question]: methods.getValues().answer });
  };
  const handleClickPrev = () => {
    onPrev({ [question]: methods.getValues().answer });
  };
  return (
    <Col alignCross="stretch" gaps={16}>
      <form
        onBlur={handleBlur}
        onSubmit={methods.handleSubmit((values) =>
          onSubmit({ [question]: values.answer })
        )}
      >
        <ScreenTitle title={question} margin="0 0 16px" />
        <Controller
          name="answer"
          control={methods.control}
          render={(fieldProps) => (
            <Textarea
              {...fieldProps}
              placeholder={placeholder || 'Put your thoughts here'}
            />
          )}
        />
        <FormFooter onClickPrev={handleClickPrev} isLastScreen={isLastScreen} />
      </form>
    </Col>
  );
};
