import { useGetAgentIncentiveHistoryQuery } from '~entities/agent-incentive';

import { Loader } from '~shared/ui/loader';

import { Col } from '@breeze-platform-ui/layout';

import styles from './bonus-history.module.css';

import { MonthBonusDetails } from '../month-bonus-details/month-bonus-details';

export const BonusHistory: React.FC<{ isAldiAgent: boolean }> = ({
  isAldiAgent,
}) => {
  const { data: history, isError } = useGetAgentIncentiveHistoryQuery();

  if (isError) {
    return null;
  }

  if (!history) {
    return <Loader centered />;
  }

  return (
    <Col alignCross="stretch" gaps={4}>
      <h3 className={styles.header}>History</h3>
      {history.map((monthHistory) => (
        <MonthBonusDetails
          key={monthHistory.date}
          isAldiAgent={isAldiAgent}
          history={monthHistory}
        />
      ))}
    </Col>
  );
};
