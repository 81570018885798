export const COMPANY_NAME_REQUIRED_ERROR =
  'Enter the\u00A0name of\u00A0the\u00A0company or\u00A0project the\u00A0customer is\u00A0working\u00A0on';
export const SELECT_REQUIRED_ERROR = 'Select an option';
export const REFERENCE_PHONE_REQUIRED_ERROR =
  'Enter the reference’s contact number';
export const REFERENCE_NAME_REQUIRED_ERROR = 'Enter the reference’s name';
export const NATURE_OF_WORK_OTHER_REQUIRED_ERROR =
  'Enter customer’s industry name';

export const SELECT_ONE_REQUIRED_ERROR = 'Select one option';
