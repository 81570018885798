export const TAKE_PHOTO_FIELD_NAME = 'Take a photo of the document';
export const FIRST_NAME_FIELD_NAME = 'First name';
export const LAST_NAME_FIELD_NAME = 'Last name';
export const MIDDLE_NAME_FIELD_NAME = 'Middle name (if any)';
export const BIRTHDATE_FIELD_NAME = 'Date of birth';

export const documentNumberFieldNames = {
  umid: 'CRN (Common Reference Number)',
  philId: 'PSN (PhilSys Number)',
  prc: 'PRC license number',
  drivingLicence: 'License number',
  passport: 'Passport number',
  sss: 'SSS Number',
  postalId: 'Postal ID',
} as const;
