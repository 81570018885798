import React, { useState } from 'react';
import type { FieldErrors } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';

import { environment } from '~app/environment';

import { useRevalidate } from '~shared/hooks';
import { combineValidators } from '~shared/lib/combine-validators';
import { getFieldsErrors } from '~shared/lib/get-fields-errors';
import { FormFooter } from '~shared/ui/form-footer';
import { FormHeader } from '~shared/ui/form-header';
import { Screen } from '~shared/ui/screen';
import { ScreenTitle } from '~shared/ui/screen-title';
import { validators } from '~shared/validators';

import { CheckboxLabeled } from '@breeze-platform-ui/label';
import { CheckboxGroup, Textarea } from '@breeze/rhf-adapters';

import type { ClientAssessmentValue } from '../lib';
import { clientAssessmentOptions } from '../lib';
import { commentOption } from '../lib/client-assessment-options';

export type FormValues = {
  clientAssessment: ClientAssessmentValue[];
  comment?: string;
};

type Props = Readonly<{
  name: string;
  initialValue?: Partial<FormValues>;
  onPrev: (
    values: Partial<FormValues>,
    errors: FieldErrors<FormValues>
  ) => void;
  onSubmit: (formValues: FormValues, sendFeatureFlags?: boolean) => void;
  onClose: () => void;
  isSelfApp?: boolean;
}>;

export const ClientAssessment: React.FC<Props> = ({
  name,
  initialValue,
  onSubmit,
  onPrev,
  onClose,
  isSelfApp,
}) => {
  const methods = useForm<FormValues>({
    defaultValues: initialValue,
    mode: 'all',
    shouldUnregister: true,
  });
  const { control, getValues, handleSubmit, watch } = methods;

  useRevalidate(methods);

  const canSkipServerValidation = environment.SKIP_VALIDATION_FEATURE === 'ON';
  const [sendDevFeatureFlags, setSendDevFeatureFlags] = useState(false);

  const handleOnPrev = () => {
    const actualErrors = getFieldsErrors<FormValues>(methods);

    onPrev(getValues(), actualErrors);
  };

  const assessment = watch('clientAssessment');
  const showComment = assessment?.length > 0;
  const isCommentRequired = assessment?.includes('Other');

  return (
    <form
      onSubmit={handleSubmit((formValues) =>
        onSubmit(formValues, sendDevFeatureFlags)
      )}
    >
      <Screen
        header={<FormHeader onClick={onClose} text={name} />}
        footer={<FormFooter onClickPrev={handleOnPrev} isLastScreen />}
      >
        <ScreenTitle
          title="Customer Assessment"
          subtitle="For your eyes only. Share your observations about your customer on&nbsp;this checklist"
        />
        <Controller
          control={control}
          name="clientAssessment"
          render={(fieldProps) => (
            <CheckboxGroup
              {...fieldProps}
              wide
              vertical
              options={clientAssessmentOptions}
            />
          )}
        />

        {showComment && (
          <Controller
            shouldUnregister
            control={control}
            name="comment"
            rules={{
              validate: combineValidators(
                isCommentRequired
                  ? validators.required({ text: 'Please, enter the comment' })
                  : () => undefined,
                validators.maxLength({ maxLength: 255 })
              ),
            }}
            render={(fieldProps) => (
              <Textarea {...fieldProps} placeholder={commentOption.title} />
            )}
          />
        )}
        {!isSelfApp && canSkipServerValidation && (
          <CheckboxLabeled
            checked={sendDevFeatureFlags}
            onChange={(_e, parameters) => {
              setSendDevFeatureFlags(parameters.checked);
            }}
          >
            Move the application to the status APPROVED (dev envs only)
          </CheckboxLabeled>
        )}
      </Screen>
    </form>
  );
};
