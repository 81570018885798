import { atomWithBroadcast } from '~shared/atoms/atom-with-broadcast';

import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

export const activeApplicationIdAtom = atomWithBroadcast<string | null>(
  'merchant_portal_active_application_id',
  null
);

export const downgradeApplicationIdAtom = atom(null, (get, set, update) => {
  if (get(activeApplicationIdAtom) === update) {
    set(activeApplicationIdAtom, null);
  }
});

export const pendingNotificationViewTimeAtom = atomWithStorage<number | null>(
  'pending_notification_view_time',
  null,
  undefined,
  { getOnInit: true }
);
