import { Mask } from '@breeze-platform-ui/input';

export enum PhoneNumberType {
  mobile = 'mobile', // маска для мобильного телефонов
  landline = 'landline', // стационарый телефон - первая цифра (после префикса) 2
  combined = 'combined', // стационарный телефон с длинной как у мобильного первая цифра (после префикса) от 1 до 7 или первая цифра 8 вторая НЕ 9
}

export type MaskWithMeta = {
  origin: string;
  instance: Mask;
  meta: {
    placeholder: string;
  };
};

export const phoneMasksWithMetaPh: Record<PhoneNumberType, MaskWithMeta> = {
  [PhoneNumberType.mobile]: {
    origin: '+63 999 999 9999',
    instance: new Mask('+63 999 999 9999'),
    meta: {
      placeholder: '+63 901 234 5678',
    },
  },
  [PhoneNumberType.landline]: {
    origin: '+63 9 9999 9999',
    instance: new Mask('+63 9 9999 9999'),
    meta: {
      placeholder: '+63 2 012 34567',
    },
  },
  [PhoneNumberType.combined]: {
    origin: '+63 99 999 9999',
    instance: new Mask('+63 99 999 9999'),
    meta: {
      placeholder: '+63 70 123 4567',
    },
  },
};
