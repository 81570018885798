import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { OfferBanner } from '~entities/offer';
import { useSearchOfferByCodeMutation } from '~entities/offer/model/offer-queries';

import { PromoCodeForm } from '../promo-code-form/promo-code-form';

export const ManageOfferByCode: React.FC<{
  activeCode?: string;
  onUpdateCode: (code?: string) => void;
  onSearchCodeError: () => void;
}> = ({ activeCode, onUpdateCode, onSearchCodeError }) => {
  const methods = useForm<{ promoCode: string }>({
    defaultValues: { promoCode: activeCode },
    mode: 'onBlur',
  });

  const { getValues, setValue } = methods;

  const { data, isError, isLoading, mutate } = useSearchOfferByCodeMutation();

  useEffect(() => {
    if (data?.status === 'SUCCESS') {
      onUpdateCode(getValues('promoCode'));
    }
  }, [data, onUpdateCode, getValues]);

  useEffect(() => {
    if (activeCode !== getValues('promoCode')) {
      setValue('promoCode', activeCode ?? '');
    }
  }, [activeCode, getValues, setValue]);

  const isSearchOCodeError = isError || data?.status === 'NOT_FOUND';

  useEffect(() => {
    if (isSearchOCodeError) {
      onSearchCodeError();
    }
  }, [isSearchOCodeError, onSearchCodeError]);

  const handleCancelPromoCode = () => {
    methods.reset({ promoCode: '' });
    onUpdateCode(undefined);
  };

  return (
    <FormProvider {...methods}>
      {activeCode ? (
        <OfferBanner
          title={activeCode}
          subtitle="Your promo code"
          onCancel={handleCancelPromoCode}
        />
      ) : (
        <PromoCodeForm
          isLoading={isLoading}
          isError={isSearchOCodeError}
          onSubmitCode={(code) => code && mutate(code)}
        />
      )}
    </FormProvider>
  );
};
