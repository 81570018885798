import { Fragment } from 'react';

import type { IncentiveProgramStep } from '~entities/agent-incentive';

import { Row } from '@breeze-platform-ui/layout';
import Text from '@breeze-platform-ui/text';

import styles from './bonus-levels.module.css';

import stepActiveImg from '../../images/stepActive.svg';
import stepInactiveImg from '../../images/stepInactive.svg';
import { getBonusLevelFillingPercent } from '../../lib';

type Props = {
  bonusSteps: IncentiveProgramStep[];
  currentCount: number;
};
const isLevelAchieved = (lowerBound: number, currentCount: number) =>
  currentCount >= lowerBound;

export const BonusStepper = ({ bonusSteps, currentCount }: Props) => {
  return (
    <>
      <Row alignCross="center" alignMain="between">
        {bonusSteps.map((bonus, index) => {
          const shouldShowProgress = index < bonusSteps.length - 1;
          const fillngPercent = getBonusLevelFillingPercent(
            currentCount,
            bonus.lowerBound,
            bonus.upperBound as number
          );
          return (
            <Fragment key={bonus.bonusAmountPerApplication}>
              <img
                className={styles.step}
                src={
                  isLevelAchieved(bonus.lowerBound, currentCount)
                    ? stepActiveImg
                    : stepInactiveImg
                }
                alt=""
              />
              {shouldShowProgress && (
                <div className={styles.progressLine}>
                  <span
                    className={styles.filledLine}
                    style={{
                      width: fillngPercent,
                    }}
                  />
                </div>
              )}
            </Fragment>
          );
        })}
      </Row>
      <Row alignMain="between" margin="12px 0">
        {bonusSteps.map(({ bonusAmountPerApplication, lowerBound }) => (
          <div
            key={bonusAmountPerApplication}
            className={
              isLevelAchieved(lowerBound, currentCount)
                ? styles.labelFilled
                : styles.label
            }
          >
            <Text align="center" size={15} bold>
              ₱{bonusAmountPerApplication}
            </Text>
            <Text align="center" size={11}>
              per loan
            </Text>
          </div>
        ))}
      </Row>
    </>
  );
};
