import { useMemo } from 'react';

import type { OrderItem } from '~entities/order-item';
import { OrderItemList } from '~entities/order-item';
import { coupleOrderItems } from '~entities/order-item/lib/couple-order-items';

import { SectionTitle } from '~shared/ui/section-title';

type Props = { items: OrderItem[] };

export const OrderItems = ({ items }: Props) => {
  const coupledItems = useMemo(() => coupleOrderItems(items), [items]);
  return (
    <div>
      <SectionTitle title="Items to&nbsp;buy" margin="0 0 16px" padding={0} />
      <OrderItemList items={coupledItems} />
    </div>
  );
};
