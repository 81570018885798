import { useNavigate } from 'react-router-dom';

import { CancellationSurvey } from '~widgets/application-cancellation-survey';

import Sheet from '@breeze-platform-ui/sheet';
import { useNotifications } from '@pfa/front-notifications';

type Props = {
  applicationId: string;
  isOpened: boolean;
  onClose: () => void;
};
export const ApplicationCancelPopup = ({
  applicationId,
  isOpened,
  onClose,
}: Props) => {
  const navigate = useNavigate();
  const notifications = useNotifications();

  const handleApplicationCancel = () => {
    notifications.info(
      'Note that it might take a few minutes for the status to change to Cancelled',
      { showClose: true }
    );
    navigate('/applications');
  };

  return (
    <Sheet
      mode="normal"
      modes={{
        collapsed: false,
        normal: {
          minHeight: 320,
          maxHeight: 440,
        },
        expanded: false,
      }}
      opened={isOpened}
      onChange={() => {
        // do nothing
      }}
      onClose={onClose}
    >
      <CancellationSurvey
        applicationId={applicationId}
        onClose={onClose}
        onCancel={handleApplicationCancel}
      />
    </Sheet>
  );
};
