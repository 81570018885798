import type { AbstractOption } from '~shared/types/abstract-option';

const options = [
  {
    value: 'ENGLISH',
    title: 'English',
  },
  {
    value: 'TAGALOG',
    title: 'Tagalog',
  },
  {
    value: 'BISAYA',
    title: 'Bisaya',
  },
  {
    value: 'CEBUANO',
    title: 'Cebuano',
  },
  {
    value: 'ILOCANO',
    title: 'Ilocano',
  },
  {
    value: 'ILONGGO/HILIGAYNON',
    title: 'Ilonggo/Hiligaynon',
  },
  {
    value: 'KAPAMPANGAN',
    title: 'Kapampangan',
  },
  {
    value: 'BICOLANO',
    title: 'Bicolano',
  },
  {
    value: 'OTHER',
    title: 'Other',
  },
] as const;

export type PreferedLanguageValue = typeof options[number]['value'];
export const preferredLanguageOptions =
  options as unknown as AbstractOption<PreferedLanguageValue>[];
