import { useNavigate } from 'react-router-dom';

import { useCustomerAnalytics } from '~app/providers/analytics-provider';

import { StatusIndicator } from '~features/application-process-status';

import {
  getApplicationProcessStatus,
  getReleaseTime,
} from '~entities/application';
import type { ApplicationDetails } from '~entities/application';

import { Int24ArrowRight } from '@breeze-platform-ui/iconsPack';
import { Col, Row } from '@breeze-platform-ui/layout';
import Text from '@breeze-platform-ui/text';

import styles from './application-item.module.css';

import { isToday } from '../../lib';

type Props = {
  application: ApplicationDetails;
  showArrow?: boolean;
};

export const ApplicationItem = ({ application, showArrow }: Props) => {
  const navigate = useNavigate();
  const analytics = useCustomerAnalytics();
  const processStatus = getApplicationProcessStatus(application);
  const { fullName, mobilePhone } = application.person;
  const statusTime =
    processStatus.status === 'finalized' &&
    isToday(new Date(processStatus.dateUTC))
      ? `at ${getReleaseTime(processStatus.dateUTC)}`
      : '';
  const handleApplicationClick = () => {
    analytics.trackApplicationFromListShown({ applicationId: application.id });
    navigate(`/applications/${application.id}`);
  };
  return (
    <Row
      className={styles['application-item']}
      data-qa-type="application-item"
      alignMain="between"
      onClick={handleApplicationClick}
    >
      <Col alignMain="center" height="24px" margin="0 8px 0 0">
        <StatusIndicator processStatus={processStatus} />
      </Col>
      <Col style={{ flex: 1, overflow: 'hidden' }}>
        <Text size={15} color="rgba(0, 0, 0, 0.8)" overflowEllipsis>
          {fullName || mobilePhone}
        </Text>
        <Text size={13} color="rgba(0, 0, 0, 0.5)">
          {`${processStatus.message} ${statusTime}`}
        </Text>
      </Col>
      {showArrow && (
        <Col height="100%" alignMain="center">
          <Int24ArrowRight theme={{ color: 'rgba(0, 0, 0, 0.16)' }} />
        </Col>
      )}
    </Row>
  );
};
