import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';

import { isIncentiveProgramAvailableAtom } from '~entities/agent-incentive';

import { ScreenTitle } from '~shared/ui/screen-title';

import Action from '@breeze-platform-ui/action/esm';
import { Int24Call } from '@breeze-platform-ui/iconsPack';
import { Int24PinFilled } from '@breeze-platform-ui/iconsPack';
import '@breeze-platform-ui/iconsPack';
import { Col } from '@breeze-platform-ui/layout';
import Text from '@breeze-platform-ui/text';
import { useAtomValue } from 'jotai';

import rewardSrc from './images/reward.svg';

type Props = {
  currentPos: string;
  customerServicePhone: string;
};
export const AgentHelp: React.FC<Props> = ({
  currentPos,
  customerServicePhone,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isIncentiveProgramAvailable = useAtomValue(
    isIncentiveProgramAvailableAtom
  );
  return (
    <>
      <ScreenTitle title="More" />
      {isIncentiveProgramAvailable && (
        <Action
          size="m"
          icon={<img src={rewardSrc} alt="reward" width={40} height={40} />}
          text="Earn more with each customer"
          onClick={() => {
            navigate('/agent-incentive');
          }}
        >
          <Text size={15} bold>
            My bonuses
          </Text>
        </Action>
      )}
      <Col alignCross="stretch" gaps={20} margin="24px 0 32px 0">
        <Action
          size="m"
          icon={
            <Int24Call
              theme={{ color: '#4D77FF', background: '#428BF91F' }}
              withContainer
              containerSize={40}
            />
          }
          text="Available from 9 AM to 6 PM"
          href={`tel:${customerServicePhone}`}
        >
          <Text size={15} bold>
            Call customer care
          </Text>
        </Action>
        <Action
          size="m"
          icon={
            <Int24PinFilled
              theme={{ color: '#4D77FF', background: '#428BF91F' }}
              withContainer
              containerSize={40}
            />
          }
          text="Tap to change your store"
          href="/pos-select"
          onClick={(e) => {
            e.preventDefault();
            navigate(
              {
                pathname: '/pos-select',
                search: createSearchParams({
                  redirectUrl: pathname,
                  backUrl: pathname,
                }).toString(),
              },
              {
                state: {
                  openedFrom: 'more',
                },
              }
            );
          }}
        >
          <Text size={15} bold>
            {currentPos}
          </Text>
        </Action>
      </Col>
    </>
  );
};
