export const applicationStepNames = {
  clientPhoneAssessment: 'clientPhoneAssessment',
  clientAssessment: 'clientAssessment',
} as const;

export const personDetailsStepNames = {
  personalDetails: 'personalDetails',
  residentialAddress: 'residentialAddress',
  permanentAddress: 'permanentAddress',
  employmentDetails: 'employmentDetails',
  additionalEmploymentDetails: 'additionalEmploymentDetails',
  finances: 'finances',
  alternativeContacts: 'alternativeContacts',
  idDocumentType: 'idDocumentType',
  idDocument: 'idDocument',
  additionalIdDocument: 'additionalIdDocument',
  clientPhoto: 'clientPhoto',
  gCashDetails: 'gCashDetails',
} as const;

export const stepNames = {
  ...applicationStepNames,
  ...personDetailsStepNames,
} as const;

export type ApplicationStepName =
  typeof applicationStepNames[keyof typeof applicationStepNames];

export type PersonDetailsStepName =
  typeof personDetailsStepNames[keyof typeof personDetailsStepNames];

export type StepName = typeof stepNames[keyof typeof stepNames];
