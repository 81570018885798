import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAnalytics } from '~app/providers/analytics-provider';

import { AgentIncentiveScreen } from '~widgets/agent-incentive';

import { useGetAgentIncentiveQuery } from '~entities/agent-incentive';
import { isAttendanceLogOnAtom } from '~entities/attendance';

import { BasicHeader } from '~shared/ui/basic-header';
import { Loader } from '~shared/ui/loader';
import { NavBackButton } from '~shared/ui/nav-back-button/nav-back-button';
import { Screen } from '~shared/ui/screen';

import { useAtomValue } from 'jotai';

export const AgentIncentivePage = () => {
  const analytics = useAnalytics();
  const { data, isLoading, isError } = useGetAgentIncentiveQuery();
  const navigate = useNavigate();
  const isAttendanceLogOn = useAtomValue(isAttendanceLogOnAtom);

  const handleBackClick = () => {
    navigate(isAttendanceLogOn ? '/account' : '/more');
  };

  useEffect(() => {
    analytics.trackIncentiveProgramOpened();
  }, [analytics]);

  if (isLoading || isError) {
    return (
      <Screen
        header={
          <BasicHeader
            actionButton={<NavBackButton onClick={handleBackClick} />}
            mainContent="My bonuses"
          />
        }
      >
        {isLoading ? <Loader /> : <></>}
      </Screen>
    );
  }

  if (data) {
    return (
      <AgentIncentiveScreen agentIncentive={data} onBack={handleBackClick} />
    );
  }
  return null;
};
