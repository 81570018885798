import { environment } from '~app/environment';

import { CmsClient } from '~shared/cms-client/cms-client';

import type {
  CmsAgentNewsApi,
  AgentNewsDetailsDto,
  AgentNewsInfoDto,
} from './agent-news-api';

export class CMSAgentNewsApi implements CmsAgentNewsApi {
  private client: CmsClient;

  constructor(host: string) {
    this.client = new CmsClient({
      host,
    });
  }

  async getNewsItem(id: string): Promise<AgentNewsDetailsDto> {
    return this.client.getOne<AgentNewsDetailsDto>({
      resource: 'agent-news-items',
      id,
    });
  }

  async getNewsList(): Promise<AgentNewsInfoDto[]> {
    return this.client
      .getList<AgentNewsInfoDto>({
        resource: 'agent-news-items',
      })
      .then((data) => data?.data || []);
  }
}

export const cmsAgentNewsApi = new CMSAgentNewsApi(environment.API_HOST);
