import { type ApprovedProductOption } from '~entities/product';

import { Hint } from '@breeze-platform-ui/tooltip';

export const TermsHint: React.FC<ApprovedProductOption> = ({
  monthlyInterestRate,
  originationFeeFlat,
  originationFeeRate,
}) => {
  return (
    <span data-qa-type="loan-terms-tooltip">
      All fees included&nbsp;
      <Hint
        direction="top"
        theme="dark"
        popoverContent={
          <>
            <p>{monthlyInterestRate}% &nbsp;interest rate</p>
            <p>+ ₱{originationFeeFlat}&nbsp;processing fee</p>
            <p>+ {originationFeeRate}%&nbsp;of the loan amount</p>
          </>
        }
      />
    </span>
  );
};
