import {
  useAnalytics,
  useCustomerAnalytics,
} from '~app/providers/analytics-provider';
import { useDi } from '~app/providers/di-provider';
import { useTracking } from '~app/providers/tracking-provider';

import { getDeviceMetadata } from '~entities/device-metadata';

import {
  type UseMutationOptions,
  type UseQueryOptions,
  useQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import { isSuccessResponse } from '../api';
import type {
  ValidateResponse,
  UpgradeInitiateParams,
  InitiateParams,
  AuthOtpResponse,
  AuthSuccessResponse,
} from '../api';
import type { UserInfo } from '../auth';

export const useInitiateMutation = (
  options?: UseMutationOptions<any, Error, Omit<InitiateParams, 'userMetadata'>>
) => {
  const { authApi } = useDi();
  const tracking = useTracking();
  return useMutation({
    mutationKey: ['initiate'],
    mutationFn: (params: Omit<InitiateParams, 'userMetadata'>) =>
      authApi.initiate({
        ...params,
        userMetadata: {
          webFingerprint: getDeviceMetadata(tracking?.state),
        },
      }),
    ...options,
  });
};

export const useUpgradeInitiateMutation = (
  options?: UseMutationOptions<
    AuthOtpResponse | AuthSuccessResponse,
    Error,
    Omit<UpgradeInitiateParams, 'userMetadata'>
  >
) => {
  const queryClient = useQueryClient();
  const analytics = useAnalytics();
  const { authApi } = useDi();
  const tracking = useTracking();
  return useMutation({
    mutationKey: ['upgradeInitiate'],
    mutationFn: async (params: Omit<UpgradeInitiateParams, 'userMetadata'>) => {
      const response = await authApi.upgradeInitiate({
        ...params,
        userMetadata: {
          webFingerprint: getDeviceMetadata(tracking?.state),
        },
      });
      if (isSuccessResponse(response)) {
        queryClient.resetQueries(['applications', 'authorized']);
        try {
          const { clientAnalyticsPersonId } = await authApi.getUserInfo();
          clientAnalyticsPersonId &&
            analytics.setClientAnalyticsId(clientAnalyticsPersonId);
        } catch (err) {}
      }

      return response;
    },
    ...options,
  });
};

export const useGetUserInfoQuery = (options?: UseQueryOptions<UserInfo>) => {
  const analytics = useAnalytics();
  const customerAnalytics = useCustomerAnalytics();
  const { authApi } = useDi();
  return useQuery({
    queryFn: async () => {
      const userInfo = await authApi.getUserInfo();
      analytics.setClientAnalyticsId(userInfo.clientAnalyticsPersonId);
      analytics.setUserId(userInfo.employeeId);
      customerAnalytics.setEmployeeId(userInfo.employeeId);
      window.clarity?.('set', 'employee_id', userInfo.employeeId);
      window.clarity?.('upgrade', 'Agent is logged in');
      return userInfo;
    },
    cacheTime: 0,
    ...options,
  });
};

export const useValidateQuery = (
  options?: UseQueryOptions<ValidateResponse>
) => {
  const { authApi } = useDi();
  const tracking = useTracking();
  return useQuery({
    queryKey: ['validate'],
    queryFn: async () => {
      return authApi.validate({
        webFingerprint: getDeviceMetadata(tracking?.state),
      });
    },
    cacheTime: 0,
    ...options,
  });
};
