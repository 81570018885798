import { environment } from '~app/environment';

import type { RequestParams } from '~shared/scp-client';
import { webRequestClient, type ScpClient } from '~shared/scp-client';

import type { AgentInfo } from '../agent';

type Options = { host: string };

class GatewayAgentApi {
  scpClient: ScpClient;
  host: string;

  constructor(scpClient: ScpClient, options: Options) {
    this.scpClient = scpClient;
    this.host = options.host;
  }

  async getAgentInfo(): Promise<AgentInfo> {
    return this.makeRequest<AgentInfo>({
      method: 'get-by-id',
    });
  }

  private makeRequest<T>(params: Partial<RequestParams> & { method: string }) {
    return this.scpClient
      .call<T>({
        host: this.host,
        version: 'v1',
        domain: 'companies',
        service: 'web/employee',
        withCredentials: true,
        withLeadingDomain: true,
        ...params,
      })
      .then((response) => response.payload);
  }
}

export const gatewayAgentApi = new GatewayAgentApi(webRequestClient, {
  host: environment.API_HOST,
});
