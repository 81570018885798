import { useDi } from '~app/providers/di-provider';
import { useTracking } from '~app/providers/tracking-provider';

import { ConfirmationState } from '~features/auth/otp';

import { isChallengeConfirmationError } from '~entities/auth';
import { getDeviceMetadata } from '~entities/device-metadata';

import { isAuthError } from '~shared/errors';
import { FormHeader } from '~shared/ui/form-header';
import { Screen } from '~shared/ui/screen';

interface Props {
  phoneNumber: string;
  onSuccess(): void;
}

export const AgentSmsConfirmation: React.FC<Props> = (props: Props) => {
  const { phoneNumber, onSuccess } = props;
  const { authApi } = useDi();
  const tracking = useTracking();

  return (
    <Screen header={<FormHeader href="/" text="Enter the&nbsp;code" />}>
      <ConfirmationState
        confirmationType="login"
        phoneNumber={phoneNumber}
        isAuthorizationError={isAuthError}
        isConfirmationError={isChallengeConfirmationError}
        isAttemptsLimitError={() => false}
        onCodeSubmit={async (code) => {
          await authApi.respond({
            answer: code,
            userMetadata: {
              webFingerprint: getDeviceMetadata(tracking?.state),
            },
          });
        }}
        onResend={async () => {
          await authApi.resendConfirmation();
        }}
        onSuccess={onSuccess}
      />
    </Screen>
  );
};
