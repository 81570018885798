import { useLocation, useNavigate } from 'react-router-dom';

import { selectedPosAtom, useGetPosListQuery } from '~entities/pos';

import { ButtonLink } from '@breeze-platform-ui/button';
import { Int16PinFilled } from '@breeze-platform-ui/iconsPack';
import { Row } from '@breeze-platform-ui/layout';
import Text from '@breeze-platform-ui/text';
import { useAtomValue } from 'jotai';

import styles from './current-pos.module.css';

export const CurrentPosButton: React.FC = () => {
  const navigate = useNavigate();
  const selectedPos = useAtomValue(selectedPosAtom);
  const { data: posList } = useGetPosListQuery({
    refetchOnMount: true,
  });

  const showSelectedPos = posList && posList.length > 1 && selectedPos;
  const { pathname } = useLocation();

  return showSelectedPos ? (
    <ButtonLink
      size="s"
      disableHorizontalPadding
      disableVerticalPadding
      onClick={() =>
        navigate(`/pos-select?redirectUrl=${pathname}`, {
          state: { openedFrom: 'main' },
        })
      }
    >
      <Row alignCross="center" gaps={4} className={styles.buttonContainer}>
        <div className={styles.icon_container}>
          <Int16PinFilled />
        </div>
        <Text size={15} overflowEllipsis>
          {selectedPos.name}
        </Text>
      </Row>
    </ButtonLink>
  ) : null;
};

export const CurrentPosInfo: React.FC = () => {
  const selectedPos = useAtomValue(selectedPosAtom);
  const { data: posList } = useGetPosListQuery({
    refetchOnMount: true,
  });

  const showSelectedPos = posList && posList.length > 1 && selectedPos;

  return showSelectedPos ? (
    <Row alignCross="center" gaps={4} className={styles.container}>
      <div className={styles.icon_container}>
        <Int16PinFilled />
      </div>
      <Text size={15} overflowEllipsis>
        {selectedPos.name}
      </Text>
    </Row>
  ) : null;
};
