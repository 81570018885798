export const getWaitingTimeInSeconds = (startUTCTime: string) => {
  const diffMilliseconds =
    new Date().getTime() - new Date(startUTCTime).getTime();
  return Math.floor(diffMilliseconds / 1000);
};

export const getWaitingInfo = (
  timeInSeconds: number
): { containerWidth: number; timeStr: string } => {
  const days = Math.floor(timeInSeconds / 3600 / 24);
  const hours = Math.floor(timeInSeconds / 3600) % 24;
  const minutes = Math.floor(timeInSeconds / 60) % 60;
  const seconds = Math.floor(timeInSeconds % 60);

  if (days > 0) {
    return {
      containerWidth: 90,
      timeStr: `> ${days} days`,
    };
  }

  const minutesStr = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const secondsStr = seconds < 10 ? `0${seconds}` : `${seconds}`;

  if (hours > 0) {
    return {
      containerWidth: 90,
      timeStr: `${hours}:${minutesStr}:${secondsStr}`,
    };
  }

  return {
    containerWidth: 70,
    timeStr: `${minutesStr}:${secondsStr}`,
  };
};
