import { useAgentInfo } from '~entities/agent';
import type { WorkingStatus } from '~entities/attendance';
import {
  isAttendanceLogOnAtom,
  getMatchingWorkingStatus,
} from '~entities/attendance';
import { isWorkingStatus, useAttendanceStatus } from '~entities/attendance';
import { useGetDailySchedule } from '~entities/shedule';

import { useAtomValue } from 'jotai';

type ShouldForceTimeInResponse = {
  shouldForce: boolean;
  isLoading?: boolean;
  nextStatus?: WorkingStatus;
};
export function useShouldForceAttendanceLog(): ShouldForceTimeInResponse {
  const isAttendanceLogOn = useAtomValue(isAttendanceLogOnAtom);
  const attendanceStatusQuery = useAttendanceStatus({
    enabled: isAttendanceLogOn,
  });
  const agentTypeQuery = useAgentInfo({
    enabled: isAttendanceLogOn,
    select: (info) => info.employmentType,
  });
  const dailyScheduleQuery = useGetDailySchedule(
    new Date().toISOString().split('T')[0],
    { enabled: isAttendanceLogOn && agentTypeQuery.data === 'FULL_TIME' }
  );

  const status = attendanceStatusQuery.data?.status;
  const isWorking = status && isWorkingStatus(status);
  const nextWorkingStatus =
    !isWorking && status ? getMatchingWorkingStatus(status) : undefined;

  return {
    shouldForce:
      isAttendanceLogOn &&
      attendanceStatusQuery.isSuccess &&
      !isWorking &&
      agentTypeQuery.isSuccess &&
      agentTypeQuery.data !== 'CONTRACTOR' &&
      dailyScheduleQuery.isSuccess &&
      dailyScheduleQuery.data?.type === 'WORK',
    isLoading:
      isAttendanceLogOn &&
      (attendanceStatusQuery.isLoading ||
        agentTypeQuery.isLoading ||
        (dailyScheduleQuery.fetchStatus !== 'idle' &&
          dailyScheduleQuery.isLoading)),
    nextStatus: nextWorkingStatus,
  };
}
