import { environment } from '~app/environment';

import {
  webRequestClient,
  type RequestParams,
  type ScpClient,
} from '~shared/scp-client';

import type {
  VasApi,
  GetAvailableVasListPayload,
  UpdateApplicationVasListPayload,
} from './vas-api';

import type { AvailableVasList, ApplicationVasList } from '../vas';

type Options = { host: string };

export class GatewayVasApi implements VasApi {
  scpClient: ScpClient;
  host: string;

  constructor(scpClient: ScpClient, options: Options) {
    this.scpClient = scpClient;
    this.host = options.host;
  }

  getAvailableVasList(payload: GetAvailableVasListPayload) {
    return this.makeRequest<AvailableVasList>({
      method: 'find-vas-options',
      payload,
    });
  }

  getApplicationVasList(applicationId: string) {
    return this.makeRequest<ApplicationVasList>({
      method: 'get-list',
      payload: {
        applicationId,
      },
    });
  }

  updateApplicationVasList(
    payload: UpdateApplicationVasListPayload
  ): Promise<any> {
    return this.makeRequest<any>({
      method: 'update-list',
      payload,
    });
  }

  private makeRequest<T>(
    params: Partial<RequestParams> & {
      method: string;
    }
  ) {
    return this.scpClient
      .call<T>({
        host: this.host,
        version: 'v1',
        domain: 'origination',
        service: 'vas',
        withCredentials: true,
        withLeadingDomain: true,
        ...params,
      })
      .then((response) => response.payload);
  }
}

export const gatewayVasApi = new GatewayVasApi(webRequestClient, {
  host: environment.API_HOST,
});
