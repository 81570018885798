import errorImage from '~shared/images/error.svg';

import Button from '@breeze-platform-ui/button';
import { Col } from '@breeze-platform-ui/layout';

import styles from './general-error.module.css';

export const GeneralError = ({ onReload }: { onReload: () => void }) => (
  <Col gaps="l" margin={72} alignCross="center">
    <img src={errorImage} width="180" height="180" alt="warning" />
    <div className={styles.title}>Something went wrong</div>
    <Button size="m" wide theme="secondary" onClick={onReload}>
      Try again
    </Button>
  </Col>
);
