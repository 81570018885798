import React from 'react';

import { Box } from '@breeze-platform-ui/layout';

import cartImg from './cart.webp';

export const EmptyList = React.memo(() => {
  return (
    <Box margin="0 auto 24px">
      <img src={cartImg} width="88px" height="90px" alt="" />
    </Box>
  );
});
