import type { QueryClient } from '@tanstack/react-query';

import type {
  ApplicationDetails,
  ApplicationListQueryResponse,
} from '../application';

// TODO: update logic after applications store normalization

export const updateFinishedApplicationQueryData = (
  queryClient: QueryClient,
  applicationUpdate: Partial<ApplicationDetails>,
  applicationId: string,
  posId?: string
) => {
  const applications = queryClient.getQueryData<ApplicationListQueryResponse>([
    'applications',
    'list',
    posId,
  ]);
  const details = queryClient.getQueryData<ApplicationDetails>([
    'applications',
    applicationId,
  ]);
  if (details) {
    const updatedDetails = {
      ...details,
      ...applicationUpdate,
    };
    queryClient.setQueryData(['applications', applicationId], updatedDetails);
    if (applications) {
      queryClient.setQueryData<ApplicationListQueryResponse>(
        ['applications', 'list', posId],
        {
          pending: applications.pending.filter(
            ({ id }) => id !== applicationId
          ),
          list: applications.list.map((application) =>
            application.id === applicationId ? updatedDetails : application
          ),
        }
      );
    }
  }
};
