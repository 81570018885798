import Button from '@breeze-platform-ui/button';

import styles from './form-footer.module.css';

interface Props {
  nextButtonDisabled?: boolean;
  onClickPrev?: () => void;
  isLastScreen?: boolean;
}
export const FormFooter = ({
  nextButtonDisabled,
  onClickPrev,
  isLastScreen,
}: Props) => (
  <div className={styles.wrapper}>
    {onClickPrev && (
      <Button
        wide
        size="m"
        type="button"
        theme="secondary"
        disableHorizontalPadding
        onClick={onClickPrev}
      >
        Back
      </Button>
    )}
    <Button wide size="m" type="submit" disabled={nextButtonDisabled}>
      {isLastScreen ? 'Submit' : 'Next'}
    </Button>
  </div>
);
