import { environment } from '~app/environment';

import {
  webRequestClient,
  type RequestParams,
  type ScpClient,
} from '~shared/scp-client';

import type {
  ApplicationApi,
  CancelApplicationPayload,
  UpdateManufacturerIdsPayload,
  ConfirmProductParams,
} from './application-api';

import type { Application, ApplicationDetails } from '../application';

type Options = { host: string };

export class GatewayApplicationApi implements ApplicationApi {
  scpClient: ScpClient;
  host: string;

  constructor(scpClient: ScpClient, options: Options) {
    this.scpClient = scpClient;
    this.host = options.host;
  }

  getApplication() {
    return this.makeRequest<ApplicationDetails>({
      method: 'get',
      payload: {},
    });
  }

  getApplicationById(applicationId: string) {
    return this.makeRequest<ApplicationDetails>({
      method: 'get-by-id',
      payload: {
        applicationId,
      },
    });
  }

  getApplicationList(posId: string) {
    return this.makeRequest<ApplicationDetails[]>({
      method: 'get-list',
      payload: {
        posId,
        completedApplicationsDaysOfRelevance: 2,
      },
    });
  }

  updateApplication(
    payload: Partial<Application>
  ): Promise<ApplicationDetails> {
    return this.makeRequest<ApplicationDetails>({
      method: 'update',
      payload,
    });
  }

  cancelApplication(payload: CancelApplicationPayload) {
    return this.makeRequest<ApplicationDetails>({
      method: 'reject-by-loan-agent',
      payload,
    });
  }

  confirmDownpayment(): Promise<any> {
    return this.makeRequest<any>({
      method: 'down-payment/verify',
    });
  }

  updateManufacturerIds(payload: UpdateManufacturerIdsPayload): Promise<any> {
    return this.makeRequest<any>({
      method: 'zero-rate/order-items/update-imei',
      payload,
    });
  }

  updateMetadata(payload: Pick<Application, 'metaData'>): Promise<any> {
    return this.makeRequest<any>({
      method: 'update-metadata',
      payload,
    });
  }

  confirmProduct(params: ConfirmProductParams): Promise<any> {
    return this.makeRequest({
      method: 'confirm-product',
      payload: params,
    });
  }

  private makeRequest<T>(params: Partial<RequestParams> & { method: string }) {
    return this.scpClient
      .call<T>({
        host: this.host,
        version: 'v2',
        domain: 'origination',
        service: 'applications/pos-loan',
        withCredentials: true,
        withLeadingDomain: true,
        ...params,
      })
      .then((response) => response.payload);
  }
}

export const gatewayApplicationApi = new GatewayApplicationApi(
  webRequestClient,
  {
    host: environment.API_HOST,
  }
);
