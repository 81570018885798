import { useDi } from '~app/providers/di-provider';

import { useMutation, type UseMutationOptions } from '@tanstack/react-query';

import {
  type FileUploadResponse,
  type CheckImageResponse,
  type OcrResponse,
  isPendingError,
} from '../api/file-upload-api';
import type { FileItem } from '../file-item';

type UploadFilePayload = {
  applicationId: string;
  fileItem: FileItem;
};
export const useUploadFileMutation = (
  options?: UseMutationOptions<FileUploadResponse, Error, UploadFilePayload>
) => {
  const { fileUploadApi } = useDi();

  return useMutation({
    mutationKey: ['upload-file'],
    mutationFn: (payload: UploadFilePayload) =>
      fileUploadApi.uploadFile(payload),
    ...options,
  });
};

export const useCheckImageMutation = (
  options?: UseMutationOptions<CheckImageResponse, Error, string>
) => {
  const { fileUploadApi } = useDi();

  return useMutation({
    mutationKey: ['check-image'],
    mutationFn: (transactionId: string) =>
      fileUploadApi.checkImage(transactionId),
    retryDelay: 1000,
    retry: (count, error) => {
      return count < 15 && isPendingError(error);
    },
    ...options,
  });
};

export const useGetFullOcrMutation = (
  options?: UseMutationOptions<OcrResponse, Error, string>
) => {
  const { fileUploadApi } = useDi();

  return useMutation({
    mutationKey: ['get-ocr'],
    mutationFn: (transactionId: string) =>
      fileUploadApi.getFullOcrResult(transactionId),
    retry: (count, error) => {
      return count < 8 && isPendingError(error);
    },
    ...options,
  });
};
