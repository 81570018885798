import { type PropsWithChildren, useEffect } from 'react';

import { AppLoadingPage } from '~widgets/app-loading-page';

import {
  isAuthorizedAtom,
  userInfoAtom,
  isSessionRestoredAtom,
  useValidateQuery,
  useGetUserInfoQuery,
} from '~entities/auth';

import { useAtom, useSetAtom } from 'jotai';

export const SessionInitializerWrapper = ({
  children,
}: PropsWithChildren<{}>) => {
  const [isSessionRestored, setSessionRestored] = useAtom(
    isSessionRestoredAtom
  );
  const validateStatus = useValidateQuery({ enabled: !isSessionRestored });
  const userInfoStatus = useGetUserInfoQuery({
    queryKey: ['restored-user-info'],
    enabled: !isSessionRestored,
  });

  const setIsAuthorized = useSetAtom(isAuthorizedAtom);
  const setUserInfo = useSetAtom(userInfoAtom);

  useEffect(() => {
    if (
      !isSessionRestored &&
      !validateStatus.isLoading &&
      !userInfoStatus.isLoading
    ) {
      setIsAuthorized(validateStatus.isSuccess);
      setUserInfo(userInfoStatus.data || null);
      setSessionRestored(true);
    }
  }, [
    validateStatus,
    userInfoStatus,
    setIsAuthorized,
    setUserInfo,
    isSessionRestored,
    setSessionRestored,
  ]);

  if (!isSessionRestored) {
    return <AppLoadingPage />;
  }
  return <>{children}</>;
};
