import { useNavigate } from 'react-router-dom';

import Clickable from '@breeze-platform-ui/clickable';
import { Int24Close } from '@breeze-platform-ui/iconsPack';

interface Props {
  href?: string;
  onClick?: () => void;
}

export const NavCloseButton: React.FC<Props> = ({ href, onClick }) => {
  const navigate = useNavigate();

  const handleClick: React.MouseEventHandler = (event) => {
    event.preventDefault();
    if (href) {
      navigate(href);
    }
    if (onClick) {
      onClick();
    }
  };

  return (
    <Clickable
      style={{
        backgroundColor: 'transparent',
        cursor: 'pointer',
        border: 'none',
      }}
      href={href}
      onClick={handleClick}
    >
      <Int24Close theme={{ color: '#4d77ff' }} />
    </Clickable>
  );
};
