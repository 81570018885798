import {
  BlocksRenderer,
  type BlocksContent,
} from '@strapi/blocks-react-renderer';

import styles from './block-content.module.css';

type Props = {
  content: BlocksContent;
};
export const BlockContent = ({ content }: Props) => {
  return (
    <BlocksRenderer
      content={content}
      blocks={{
        heading: ({ children, level }) => {
          switch (level) {
            case 1:
              return <h1 className={styles.heading}>{children}</h1>;
            case 2:
              return <h2 className={styles.heading}>{children}</h2>;
            case 3:
              return <h3 className={styles.heading}>{children}</h3>;
            case 4:
              return <h4 className={styles.heading}>{children}</h4>;
            case 5:
              return <h5 className={styles.heading}>{children}</h5>;
            default:
              return <h6 className={styles.heading}>{children}</h6>;
          }
        },
        paragraph: ({ children }) => (
          <p className={styles.paragraph}>{children}</p>
        ),
        list: ({ children, format }) => {
          if (format === 'ordered') {
            return <ol className={styles.list}>{children}</ol>;
          }

          return <ul className={styles.list}>{children}</ul>;
        },
        image: ({ image }) => (
          <div>
            <img
              src={image.url}
              alt={image.alternativeText || ''}
              width={image.width}
              height={image.height}
              className={styles.image}
            />
          </div>
        ),
        link: ({ url, children }) => (
          <a
            href={url}
            target="_blank"
            rel="noreferrer"
            className={styles.link}
          >
            {children}
          </a>
        ),
      }}
    />
  );
};
