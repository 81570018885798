import { PopupCloseButton } from '~shared/ui/popup-close-button';
import { PopupTitle } from '~shared/ui/popup-title';

import Button from '@breeze-platform-ui/button';
import { Row, Col } from '@breeze-platform-ui/layout';
import Sheet from '@breeze-platform-ui/sheet';

type Props = {
  isOpened: boolean;
  onClose: () => void;
  onConfirm: () => void;
};
export const ConfirmationPopup = ({ onClose, onConfirm, isOpened }: Props) => {
  return (
    <Sheet
      mode="normal"
      modes={{
        collapsed: false,
        expanded: false,
        normal: {
          minHeight: 180,
          maxHeight: 180,
        },
      }}
      opened={isOpened}
      onChange={() => {
        // do nothing
      }}
      onClose={onClose}
    >
      <Col gaps={28} alignCross="stretch" dataQaType="close-confirmation-popup">
        <Row alignMain="between" alignCross="start">
          <PopupTitle
            title="Are you sure you want to exit?
            The&nbsp;calculations&nbsp;will&nbsp;be&nbsp;erased"
          />
          <PopupCloseButton onClick={() => onClose()} />
        </Row>
        <Row alignMain="between" gaps={12}>
          <Button size="m" wide theme="secondary" onClick={onClose}>
            No
          </Button>
          <Button size="m" wide theme="primary" onClick={onConfirm}>
            Yes, exit
          </Button>
        </Row>
      </Col>
    </Sheet>
  );
};
