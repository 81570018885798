import { useNavigate } from 'react-router-dom';

import { UpdateAttendanceStatus } from '~features/update-attendance-status';

import { useAgentInfo } from '~entities/agent';
import { isIncentiveProgramAvailableAtom } from '~entities/agent-incentive';
import {
  isAttendanceLogOnAtom,
  useAttendanceStatus,
} from '~entities/attendance';
import { useGetDailySchedule, WorkDayDetails } from '~entities/shedule';

import { HeaderWithLogo } from '~shared/ui/header-with-logo';
import { NavigationFooter } from '~shared/ui/navigation-footer';
import { Screen } from '~shared/ui/screen';
import { ScreenTitle } from '~shared/ui/screen-title';

import Action from '@breeze-platform-ui/action/esm';
import { Int24Call } from '@breeze-platform-ui/iconsPack';
import '@breeze-platform-ui/iconsPack';
import { Col } from '@breeze-platform-ui/layout';
import Text from '@breeze-platform-ui/text';
import { useAtomValue } from 'jotai';

import styles from './agent-account.module.css';

import rewardSrc from '../../images/reward.svg';

type Props = {
  customerServicePhone: string;
};
export const AgentAccount: React.FC<Props> = ({ customerServicePhone }) => {
  const navigate = useNavigate();
  const isAttendanceLogOn = useAtomValue(isAttendanceLogOnAtom);
  const agentTypeQuery = useAgentInfo({
    enabled: isAttendanceLogOn,
    select: (info) => info.employmentType,
  });

  const attendanceStatus = useAttendanceStatus({
    enabled: agentTypeQuery.data === 'FULL_TIME',
  });
  const dailyScheduleQuery = useGetDailySchedule(
    new Date().toISOString().split('T')[0],
    { enabled: agentTypeQuery.data === 'FULL_TIME' }
  );

  const isIncentiveProgramAvailable = useAtomValue(
    isIncentiveProgramAvailableAtom
  );

  const isDataLoading =
    isAttendanceLogOn &&
    (attendanceStatus.isLoading ||
      agentTypeQuery.isLoading ||
      dailyScheduleQuery.isLoading);
  const isContractor = agentTypeQuery.data === 'CONTRACTOR';
  const isRestDay = dailyScheduleQuery.data?.type === 'LEAVE';

  const shouldLogAttendance = !isDataLoading && !isContractor && !isRestDay;

  return (
    <Screen header={<HeaderWithLogo />} footer={<NavigationFooter />}>
      {!isContractor ? (
        <div className={styles.container} data-qa-type="work-day-details">
          <WorkDayDetails
            isLoading={dailyScheduleQuery.isLoading}
            schedule={dailyScheduleQuery.data ?? null}
          />

          {shouldLogAttendance && <UpdateAttendanceStatus />}
        </div>
      ) : (
        <ScreenTitle title="My account" margin="0 0 16px 8px" />
      )}
      {isIncentiveProgramAvailable && (
        <Action
          size="m"
          icon={<img src={rewardSrc} alt="reward" width={40} height={40} />}
          text="Earn more with each customer"
          onClick={() => {
            navigate('/agent-incentive');
          }}
        >
          <Text size={15} bold>
            My bonuses
          </Text>
        </Action>
      )}
      <Col alignCross="stretch" gaps={20} margin="24px 0 32px 0">
        <Action
          size="m"
          icon={
            <Int24Call
              theme={{ color: '#4D77FF', background: '#428BF91F' }}
              withContainer
              containerSize={40}
            />
          }
          text="Available from 9 AM to 6 PM"
          href={`tel:${customerServicePhone}`}
        >
          <Text size={15} bold>
            Call customer care
          </Text>
        </Action>
      </Col>
    </Screen>
  );
};
