import type { IncentiveProgramStep } from '~entities/agent-incentive';

import { ButtonLink } from '@breeze-platform-ui/button';
import { Row, Col } from '@breeze-platform-ui/layout';
import Text from '@breeze-platform-ui/text';

import styles from './bonus-levels.module.css';
import { BonusStepper } from './bonus-stepper';

import bonusIconSrc from '../../images/excited.webp';
import { getCurrentBonusMessage, getBonusNextLevelMessage } from '../../lib';

type Props = {
  isAldiAgent: boolean;
  bonusSteps: IncentiveProgramStep[];
  currentCount: number;
  onOpenDetails: () => void;
};

export const BonusLevels = ({
  isAldiAgent,
  bonusSteps,
  currentCount,
  onOpenDetails,
}: Props) => {
  const isLevelUpgradeAvailable = bonusSteps.length > 1;

  const bonusMessage = isLevelUpgradeAvailable
    ? getBonusNextLevelMessage(currentCount, bonusSteps)
    : getCurrentBonusMessage(bonusSteps[0].bonusAmountPerApplication);

  return (
    <Col alignCross="stretch">
      <Row alignCross="center" alignMain="between" margin="24px 0 16px">
        <h2 className={styles.title}>Your bonus level</h2>
        {!isAldiAgent && (
          <ButtonLink
            size="s"
            disableHorizontalPadding
            disableVerticalPadding
            onClick={onOpenDetails}
          >
            How&nbsp;it’s&nbsp;calculated
          </ButtonLink>
        )}
      </Row>
      {isLevelUpgradeAvailable && (
        <BonusStepper bonusSteps={bonusSteps} currentCount={currentCount} />
      )}
      {bonusMessage && (
        <Row className={styles.notification} alignCross="center" gaps={12}>
          <img src={bonusIconSrc} height={24} width={24} alt="" />
          <Text>{bonusMessage}</Text>
        </Row>
      )}
    </Col>
  );
};
