import type { BonusInfo } from '~entities/agent-incentive';

import { formatPrice } from '~shared/lib/format-price';

import { Col } from '@breeze-platform-ui/layout';

import styles from './current-bonus.module.css';

import { ApplicationsInfo } from '../applications-info/applications-info';

type Props = {
  bonusInfo: BonusInfo;
  isAldiAgent: boolean;
};

export const CurrentBonus = ({ bonusInfo, isAldiAgent }: Props) => {
  const nonSuspicious = bonusInfo.applications.find(
    ({ type }) => type === 'NON_SUSPICIOUS'
  );
  const suspicious = bonusInfo.applications.find(
    ({ type }) => type === 'SUSPICIOUS'
  );
  const hasApplications =
    (nonSuspicious && nonSuspicious.count > 0) ||
    (suspicious && suspicious.count > 0);

  return (
    <Col className={styles.card} alignCross="stretch">
      <h2 className={styles.title}> {formatPrice(bonusInfo.totalAmount)}</h2>
      <p className={styles.description}> Current bonus</p>
      {hasApplications && (
        <div className={styles.applicationsInfo}>
          {nonSuspicious && (
            <ApplicationsInfo
              applicationInfo={nonSuspicious}
              applicationTypeText={!isAldiAgent ? 'non-suspicious' : undefined}
            />
          )}
          {suspicious && (
            <ApplicationsInfo
              applicationInfo={suspicious}
              applicationTypeText="suspicious"
            />
          )}
        </div>
      )}
    </Col>
  );
};
