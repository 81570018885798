import type { ApplicationProcessStatus } from '~entities/application';

import Text from '@breeze-platform-ui/text';

import styles from './process-status-title.module.css';

import { StatusIndicator } from '../status-indicator/status-indicator';

type Props = {
  processStatus: ApplicationProcessStatus;
};

export const ProcessStatusTitle = ({ processStatus }: Props) => {
  return (
    <Text bold size={17}>
      <StatusIndicator processStatus={processStatus} />
      <span className={styles.message}>{processStatus.message}</span>
    </Text>
  );
};
