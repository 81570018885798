import type { HttpError } from '~shared/errors';
import { isHttpError } from '~shared/errors';
import type { RequestError } from '~shared/scp-client';

import type { OrderParams } from '../product';

const productValidationErrors = ['SUM_TOO_LOW', 'SUM_TOO_HIGH'] as const;
export type ProductValidationErrorCode = typeof productValidationErrors[number];

export type ProductValidationError = HttpError & {
  code: ProductValidationErrorCode;
  body: OrderParams;
};

export function isProductValidationError(
  error: RequestError
): error is ProductValidationError {
  return (
    isHttpError(error) &&
    !!error.code &&
    typeof error.code === 'string' &&
    productValidationErrors.includes(error.code as any)
  );
}
