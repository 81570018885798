import { useDi } from '~app/providers/di-provider';

import { useQueuedMutation } from '~shared/react-query-helpers';

import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery, useMutation, useQueries } from '@tanstack/react-query';

import {
  getItemDetailsFromDto,
  getItemInfoFromDto,
} from './get-agent-news-item-from-dto';

import type {
  AgentNewsInfo,
  AgentNewsDetails,
  AgentNewsData,
  AgentSurveyData,
} from '../agent-news';
import { type SurveyDataPayload } from '../api';
import { shouldMarkUnread } from '../lib';

const NEWS_REFETCH_INTERVAL = 10 * 60 * 1000;

export type ReadNewsItemsSet = Set<string>;

export type NewsItemsQueryData = {
  items: AgentNewsInfo[];
  unreadCount: number;
};

export const useGetNewsListQuery = (
  options?: UseQueryOptions<NewsItemsQueryData, Error, NewsItemsQueryData>
) => {
  const { cmsAgentNewsApi } = useDi();
  return useQuery({
    queryKey: ['agent-news'],
    queryFn: async (): Promise<NewsItemsQueryData> => {
      const dtoItems = await cmsAgentNewsApi.getNewsList();
      let unreadCount = 0;

      const items = dtoItems.map((dtoItem) => {
        const item = getItemInfoFromDto(dtoItem);
        if (shouldMarkUnread(item)) {
          unreadCount++;
        }
        return item;
      });
      return { items, unreadCount };
    },
    retry: 2,
    refetchInterval: NEWS_REFETCH_INTERVAL,
    refetchOnWindowFocus: true,
    ...options,
  });
};

export const useGetNewsDetailsQuery = (
  id: string,
  options?: UseQueryOptions<AgentNewsDetails, Error, AgentNewsDetails>
) => {
  const { cmsAgentNewsApi } = useDi();
  return useQuery({
    queryKey: ['agent-news-details', id],
    queryFn: async (): Promise<AgentNewsDetails> => {
      const dtoItem = await cmsAgentNewsApi.getNewsItem(id);
      return getItemDetailsFromDto(dtoItem);
    },
    retry: 2,
    ...options,
  });
};

export const useUpdateReadItemsMutation = () => {
  const { agentNewsApi } = useDi();

  return useMutation({
    mutationKey: ['update-read-agent-news-items'],
    mutationFn: async (ids: string[]) => {
      return agentNewsApi.markReadNewsItems(ids);
    },
    retry: 4,
  });
};

export const useGetBlockingNewsDetails = (ids: string[]) => {
  const { cmsAgentNewsApi, agentNewsApi } = useDi();
  return useQueries({
    queries: ids.map((id) => {
      return {
        queryKey: ['agent-news-details-blocking', id],
        queryFn: async (): Promise<AgentNewsData> => {
          const dtoItem = await cmsAgentNewsApi.getNewsItem(id);

          if (dtoItem.attributes.surveyQuestions.length > 0) {
            const surveyData = await agentNewsApi.getSurveyData(id);
            return {
              details: getItemDetailsFromDto(dtoItem),
              surveyData,
            };
          }
          return { details: getItemDetailsFromDto(dtoItem) };
        },
        retry: 1,
      };
    }),
  });
};

export const useGetSurveyData = (
  id: string,
  options?: UseQueryOptions<AgentSurveyData, Error, AgentSurveyData>
) => {
  const { agentNewsApi } = useDi();
  return useQuery({
    queryKey: ['agent-survey-data', id],
    queryFn: async (): Promise<AgentSurveyData> => {
      return agentNewsApi.getSurveyData(id);
    },
    retry: 2,
    cacheTime: 0,
    ...options,
  });
};

export const useUpdateSurveyData = () => {
  const { agentNewsApi } = useDi();

  return useQueuedMutation({
    mutationKey: ['update-agent-survey-data'],
    mutationFn: async (payload: SurveyDataPayload) => {
      return agentNewsApi.updateSurveyData(payload);
    },
    retry: 4,
  });
};
