import {
  type SurveyQuestionConfig,
  isCheckboxSurveyQuestion,
  isRadioSurveyQuestion,
  isTextAreaSurveyQuestion,
} from '~entities/agent-news';

import { type SurveyFormValues } from '../types';

export const recoverStringValue = (answer?: unknown) => {
  if (typeof answer === 'string') {
    return answer;
  }
  return '';
};

export const recoverArrayValue = (answer?: unknown) => {
  if (Array.isArray(answer)) {
    return answer.filter((v) => typeof v === 'string');
  }
  return [];
};

export const recoverInitialFormValues = (
  initialValues: {
    [question: string]: unknown;
  },
  questions: SurveyQuestionConfig[]
): SurveyFormValues => {
  const initialFormValues: SurveyFormValues = {};
  questions.forEach((config) => {
    if (isCheckboxSurveyQuestion(config)) {
      initialFormValues[config.question] = recoverArrayValue(
        initialValues[config.question]
      );
      return;
    }
    if (isRadioSurveyQuestion(config)) {
      initialFormValues[config.question] = recoverStringValue(
        initialValues[config.question]
      );
      return;
    }
    if (isTextAreaSurveyQuestion(config)) {
      initialFormValues[config.question] = recoverStringValue(
        initialValues[config.question]
      );
    }
  });
  return initialFormValues;
};
