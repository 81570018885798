import { isAttendanceLogOn } from '~app/feature-configs/attendance-log';

import { employeeIdAtom } from '~entities/auth';

import { atom } from 'jotai';

export const isAttendanceLogOnAtom = atom((get) => {
  const id = get(employeeIdAtom);

  return !!id && isAttendanceLogOn(id);
});
