import { environment } from '~app/environment';

import { isHttpError, HttpError } from '~shared/errors';
import {
  type RequestParams,
  type RequestError,
  type ScpClient,
  webRequestClient,
} from '~shared/scp-client';

import type {
  FileUploadApi,
  FileUploadPayload,
  FileUploadResponse,
  CheckImageResponse,
  OcrResponse,
} from './file-upload-api';
import { PendingError } from './file-upload-api';

import { isFileValidationCode, fileValidationErrorMessageMap } from '../lib';

interface Params {
  client: ScpClient;
  host: string;
}

export class GatewayFileUploadApi implements FileUploadApi {
  client: ScpClient;
  host: string;

  constructor({ client, host }: Params) {
    this.client = client;
    this.host = host;
  }

  uploadFile({ applicationId, fileItem }: FileUploadPayload) {
    const formData = new FormData();
    formData.append(
      'frontImage',
      fileItem.frontImage,
      fileItem.frontImage.name
    );
    formData.append(
      'data',
      JSON.stringify({
        applicationId,
        docType: fileItem.docType,
      })
    );

    return this.makeRequest<FileUploadResponse>({
      type: 'multipart/form-data',
      service: 'kyc/images',
      method: 'upload_ocr_image',
      payload: formData,
    }).catch((err: RequestError) => {
      if (isHttpError(err) && isFileValidationCode(err.code)) {
        throw new HttpError({
          status: err.status,
          code: err.code,
          message: fileValidationErrorMessageMap[err.code],
        });
      }
      throw err;
    });
  }

  async checkImage(transactionId: string) {
    try {
      const response = await this.makeRequest<CheckImageResponse>({
        service: 'kyc/images',
        method: 'check_portrait_status',
        payload: { transactionId },
      });
      if (response.status === 'PENDING') {
        throw new PendingError();
      }
      return response;
    } catch (error: any) {
      if (isHttpError(error) && error.code === 'TRANSACTION_NOT_FOUND') {
        throw new PendingError();
      }
      throw error;
    }
  }

  async getFullOcrResult(transactionId: string) {
    const response = await this.makeRequest<OcrResponse>({
      method: 'get_full_ocr_result',
      payload: { transactionId },
    });
    if (response.status === 'PENDING') {
      throw new PendingError();
    }
    return response;
  }

  private makeRequest<T = unknown>(
    params: Partial<RequestParams> & {
      method?: string;
      type?: string;
      service?: string;
    }
  ) {
    return this.client
      .call<T>({
        host: this.host,
        version: 'v1',
        domain: 'kyc',
        withCredentials: true,
        withLeadingDomain: true,
        timeout: 60 * 1000,
        cache: false,
        ...params,
      })
      .then((response) => response.payload)
      .catch((error) => {
        throw error;
      });
  }
}

export const gatewayFileUploadApi = new GatewayFileUploadApi({
  client: webRequestClient,
  host: environment.API_HOST,
});
