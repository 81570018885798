import { useMemo } from 'react';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';

import {
  calculateMonthlyPayment,
  type ApprovedProduct,
} from '~entities/product';
import type {
  AvailableLoanInsurance,
  AvailableLoanVasBundle,
} from '~entities/vas';

import { FormHeader } from '~shared/ui/form-header';
import { Screen } from '~shared/ui/screen';
import { ScreenTitle } from '~shared/ui/screen-title';

import { Col, Row } from '@breeze-platform-ui/layout';
import { FILE_STATUS, type FileItem } from '@breeze/rhf-adapters';
import { useNotifications } from '@pfa/front-notifications';

import { ItemDetails } from './item-details';
import styles from './loan-details-form.module.css';
import { LoanPayments } from './loan-payments';
import { LoanVasList } from './loan-vas-list';
import { Button } from './see-agreement-button';

import type { LoanDetailsFormValues, LoanItemInfo } from '../types';

type Props = {
  applicationId: string;
  hasVases: boolean;
  loanVasList: (AvailableLoanInsurance | AvailableLoanVasBundle)[];
  loanItemsDetails: LoanItemInfo[];
  approvedLoan: ApprovedProduct;
  initialValues: LoanDetailsFormValues;
  onSubmit: (values: LoanDetailsFormValues) => void;
  onClose: () => void;
  isLoading: boolean;
};

export const LoanDetailsForm = ({
  applicationId,
  hasVases,
  loanVasList,
  loanItemsDetails,
  approvedLoan,
  initialValues,
  onSubmit,
  onClose,
  isLoading,
}: Props) => {
  const notifications = useNotifications();
  const initialMonthlyPayment = useMemo(
    () =>
      calculateMonthlyPayment(
        +approvedLoan.approvedLoanAmount,
        approvedLoan.approvedLoanTerm,
        approvedLoan.monthlyInterestRate,
        approvedLoan.originationFeeRate,
        approvedLoan.originationFeeFlat
      ),
    [approvedLoan]
  );

  const methods = useForm<LoanDetailsFormValues>({
    mode: 'all',
    defaultValues: initialValues,
  });
  const { fields } = useFieldArray({
    control: methods.control,
    name: 'itemsDetails',
  });

  const handleFormSubmit = (values: LoanDetailsFormValues) => {
    const imagesInProgress = fields.some((_field, index) => {
      const file = methods.getValues(`itemsDetails.${index}.image`);
      const image = Array.isArray(file) ? (file[0] as FileItem) : file;
      return image && image.status === FILE_STATUS.LOADING;
    });

    if (imagesInProgress) {
      notifications.warning('Some files are still loading', {
        showClose: true,
        timer: 3_000,
      });
      return;
    }

    onSubmit(values);
  };
  return (
    <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
      <FormProvider {...methods}>
        <Screen
          header={<FormHeader onClick={onClose} />}
          footer={
            <Row width="100%" alignMain="center" alignCross="center">
              <Button
                initialMonthlyPayment={initialMonthlyPayment}
                isLoading={isLoading}
              />
            </Row>
          }
        >
          <ScreenTitle title="Loan Details" />
          <Col gaps="s" alignCross="stretch" className={styles.form}>
            {fields.map((field, index) => (
              <ItemDetails
                key={field.id}
                applicationId={applicationId}
                index={index}
                {...loanItemsDetails[index]}
              />
            ))}
            {loanVasList?.length > 0 && <LoanVasList vasList={loanVasList} />}

            <LoanPayments
              hasVases={hasVases}
              initialMonthlyPayment={initialMonthlyPayment}
              loanTerm={approvedLoan.approvedLoanTerm}
              interestRate={approvedLoan.monthlyInterestRate}
            />
          </Col>
        </Screen>
      </FormProvider>
    </form>
  );
};
