import { useNavigate } from 'react-router-dom';

import { useDi } from '~app/providers/di-provider';

import isEqual from '@tinkoff/utils/is/equal';

import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { useAtom } from 'jotai';

import { selectedPosAtom } from './pos-atoms';

import type { Pos } from '../pos';

export const useGetPosListQuery = (
  options?: UseQueryOptions<unknown, Error, Pos[]>
) => {
  const { posApi } = useDi();
  const [selectedPos, setSelectedPos] = useAtom(selectedPosAtom);
  const navigate = useNavigate();

  return useQuery({
    queryKey: ['pos-list'],
    queryFn: async () => {
      const posList = await posApi.getPos();

      if (selectedPos) {
        const selectedPosInList = posList.find(
          ({ id }) => id === selectedPos.id
        );

        if (!selectedPosInList) {
          setSelectedPos(null);
          navigate('/pos-select', { replace: true });
        }

        if (selectedPosInList && !isEqual(selectedPos, selectedPosInList)) {
          setSelectedPos(selectedPosInList);
        }
      }

      return posList;
    },
    ...options,
  });
};
