import { environment } from '~app/environment';

import {
  webRequestClient,
  type RequestParams,
  type ScpClient,
} from '~shared/scp-client';

import type { AgentNewsApi, SurveyDataPayload } from './agent-news-api';

import type { AgentSurveyData } from '../agent-news';

type Options = { host: string };

class GatewayAgentNewsApi implements AgentNewsApi {
  scpClient: ScpClient;
  host: string;

  constructor(scpClient: ScpClient, options: Options) {
    this.scpClient = scpClient;
    this.host = options.host;
  }

  async markReadNewsItems(payload: string[]): Promise<void> {
    return this.makeRequest({
      method: 'news/mark-read',
      payload,
    });
  }

  async getSurveyData(newsId: string): Promise<AgentSurveyData> {
    return this.makeRequest<AgentSurveyData>({
      method: 'survey/result/get-by-id',
      payload: {
        newsId,
      },
    });
  }

  async updateSurveyData(payload: SurveyDataPayload): Promise<void> {
    return this.makeRequest({
      method: 'survey/result/update',
      payload,
    });
  }

  private makeRequest<T>(params: Partial<RequestParams> & { method: string }) {
    return this.scpClient
      .call<T>({
        host: this.host,
        version: 'v1',
        domain: 'agent-portal',
        service: 'public',
        withCredentials: true,
        withLeadingDomain: true,
        ...params,
      })
      .then((response) => response.payload);
  }
}

export const gatewayAgentNewsApi = new GatewayAgentNewsApi(webRequestClient, {
  host: environment.API_HOST,
});
