import { generateIdempotencyKey } from './generate-idempotency-key';

const SIGNING_SESSION_KEY = 'merchant_portal_signing_session';

type SigningSession = {
  applicationId: string;
  idempotencyKey: string;
  expiresAt: string | null;
  sessionId: string | null;
};

export const initiateOrder = (applicationId: string) => {
  const idempotencyKey = generateIdempotencyKey();
  localStorage.setItem(
    SIGNING_SESSION_KEY,
    JSON.stringify({ applicationId, idempotencyKey })
  );
};

export const getSession = (applicationId: string): SigningSession => {
  const session = localStorage.getItem(SIGNING_SESSION_KEY);
  if (!session) {
    throw new Error('Signing session not found');
  }
  const signingSession = JSON.parse(session);

  if (signingSession.applicationId !== applicationId) {
    throw new Error('No order is created for another application');
  }
  return signingSession;
};

export const setSession = ({
  applicationId,
  sessionId,
}: {
  applicationId: string;
  sessionId: string | null;
}) => {
  const signingSession = getSession(applicationId);
  localStorage.setItem(
    SIGNING_SESSION_KEY,
    JSON.stringify({
      ...signingSession,
      sessionId,
      expiresAt: sessionId ? new Date().getTime() + 300000 : null,
    })
  );
};
