import { useState } from 'react';

import { LogAttendance } from '~features/log-attendance';

import type { AttendanceStatus } from '~entities/attendance';
import {
  isAttendanceLogOnAtom,
  useAttendanceStatus,
} from '~entities/attendance';

import { nonNullableValue } from '~shared/types/non-nullable-value';

import { useAtomValue } from 'jotai';

import { AttendanceActions } from './ui/attendance-actions/attendance-actions';
import { ActionsSkeleton } from './ui/skeleton/skeleton';

export const UpdateAttendanceStatus = () => {
  const isAttendanceLogOn = useAtomValue(isAttendanceLogOnAtom);
  const attendanceStatusQuery = useAttendanceStatus({
    enabled: isAttendanceLogOn,
  });

  const isAttendanceStatusLoading =
    isAttendanceLogOn && attendanceStatusQuery.isLoading;
  const [nextAttendanceStatus, setNextAttendanceStatus] =
    useState<AttendanceStatus | null>(null);

  const handleCloseAttendanceLog = () => {
    setNextAttendanceStatus(null);
  };

  if (nextAttendanceStatus) {
    return (
      <LogAttendance
        status={nonNullableValue(nextAttendanceStatus)}
        onSuccess={handleCloseAttendanceLog}
        onCancel={handleCloseAttendanceLog}
      />
    );
  }

  if (attendanceStatusQuery.isSuccess) {
    return (
      <AttendanceActions
        status={attendanceStatusQuery.data.status}
        onLogAttendance={setNextAttendanceStatus}
      />
    );
  }

  if (isAttendanceStatusLoading) {
    return <ActionsSkeleton />;
  }

  return null;
};
