import { PopupCloseButton } from '~shared/ui/popup-close-button';

import Button from '@breeze-platform-ui/button';
import { Col, Row } from '@breeze-platform-ui/layout';
import Sheet from '@breeze-platform-ui/sheet';

import styles from './pre-approved-popup.module.css';
import imgSrc from './pre-approved.webp';

type Props = {
  isOpened: boolean;
  onClose: () => void;
};
export const HasPreApprovedPopup = ({ isOpened, onClose }: Props) => (
  <Sheet
    mode="normal"
    modes={{
      collapsed: false,
      expanded: false,
      normal: {
        minHeight: 320,
        maxHeight: 340,
      },
    }}
    opened={isOpened}
    onChange={() => {
      // do nothing
    }}
    onClose={onClose}
  >
    <Col gaps={12} alignCross="stretch" dataQaType="has-pre-approved-popup">
      <Row alignMain="end" alignCross="start">
        <PopupCloseButton onClick={() => onClose()} />
      </Row>

      <img
        className={styles.image}
        src={imgSrc}
        alt="pre-approved"
        width="109px"
        height="109px"
      />
      <Row alignMain="center">
        <h3 className={styles.title}>
          This customer has a&nbsp;pre&#8209;approved&nbsp;loan
        </h3>
      </Row>
      <Button size="m" wide theme="primary" onClick={onClose}>
        OK
      </Button>
    </Col>
  </Sheet>
);
