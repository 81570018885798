import { useState, type PropsWithChildren } from 'react';

import { LogAttendance } from '~features/log-attendance';

import { useAgentInfo } from '~entities/agent';
import {
  isAttendanceLogOnAtom,
  AttendanceWarning,
  useAttendanceStatus,
} from '~entities/attendance';

import { nonNullableValue } from '~shared/types/non-nullable-value';
import { HeaderWithLogo } from '~shared/ui/header-with-logo';
import { Loader } from '~shared/ui/loader';
import { Screen } from '~shared/ui/screen';

import { useAtomValue } from 'jotai';

import styles from './check-attendance-guard.module.css';
import { useShouldForceAttendanceLog } from './lib/use-should-force-attendance-log';

export const CheckAttendanceGuardHOC: React.FC<
  PropsWithChildren & { onCancel: () => void }
> = ({ children, onCancel }) => {
  const isAttendanceLogOn = useAtomValue(isAttendanceLogOnAtom);
  const attendanceStatusQuery = useAttendanceStatus({
    enabled: isAttendanceLogOn,
  });
  const agentTypeQuery = useAgentInfo({
    enabled: isAttendanceLogOn,
    select: (info) => info.employmentType,
  });

  const [isAttendanceLogInProgress, setAttendanceLogInProgress] =
    useState(false);
  const shouldCheckAttendance = useShouldForceAttendanceLog();

  if (isAttendanceLogInProgress) {
    return (
      <LogAttendance
        status={nonNullableValue(shouldCheckAttendance.nextStatus)}
        onSuccess={() => setAttendanceLogInProgress(false)}
        onCancel={onCancel}
      />
    );
  }

  const isDataLoading =
    isAttendanceLogOn &&
    (!attendanceStatusQuery.isSuccess ||
      !agentTypeQuery.isSuccess ||
      shouldCheckAttendance.isLoading);

  if (isDataLoading || shouldCheckAttendance.shouldForce) {
    return (
      <div className={styles.screenContainer}>
        <Screen header={<HeaderWithLogo />}>
          {isDataLoading ? (
            <Loader centered overlay />
          ) : (
            <AttendanceWarning
              onContinue={() => setAttendanceLogInProgress(true)}
              onCancel={onCancel}
            />
          )}
        </Screen>
      </div>
    );
  }

  return <>{children}</>;
};
