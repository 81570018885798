import {
  isAuthError,
  isUserNotActiveError,
  isUserNotFoundError,
  isTooManyRequestsError,
  isInvalidRoleError,
} from '~shared/errors';

import { Col } from '@breeze-platform-ui/layout';
import { NotificationInline } from '@breeze-platform-ui/notification';
import Text from '@breeze-platform-ui/text';

type Props = {
  error?: Error;
};

export const AuthorizationError: React.FC<Props> = ({ error }) => {
  let headingText = 'Something went wrong.';
  let actionText = 'Please, try again later.';

  if (error) {
    if (isUserNotActiveError(error)) {
      headingText = 'Your status is inactive';
      actionText = 'Please contact support';
    }

    if (isUserNotFoundError(error)) {
      headingText = 'Failed to verify your number';
      actionText = 'Please contact support';
    }

    if (isTooManyRequestsError(error)) {
      headingText = 'Too many requests';
      actionText = 'Try again later';
    }
    if (isAuthError(error)) {
      headingText = 'Failed to verify the number';
      actionText = 'Please contact support';
    }
    if (isInvalidRoleError(error)) {
      headingText = 'Invalid role selected';
      actionText = 'Please contact support';
    }
  }
  return (
    <Col gaps={16} alignCross="stretch">
      <NotificationInline
        showClose={false}
        type="warning"
        timer={false}
        animateFirstRender={false}
      >
        <Col gaps={10}>
          <Text size={13} bold>
            {headingText}
          </Text>
          <Text size="13_small">{actionText}</Text>
        </Col>
      </NotificationInline>
    </Col>
  );
};
