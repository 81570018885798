import type { EmploymentStatusValue } from './employment-status-options';

export const jobTitleLabels: Record<
  Exclude<EmploymentStatusValue, 'UNEMPLOYED'>,
  string
> = {
  FULL_TIME: 'Job title',
  PART_TIME: 'Job title',
  CONTRACTUAL_FREELANCER: 'Work activity or job title',
  DOMESTIC_WORKER: 'Work activity or job title',
  OWN_BUSINESS: 'Customer’s role',
  OFW: 'Job title',
};

export const jobTitleMessages: Record<
  Exclude<EmploymentStatusValue, 'UNEMPLOYED'>,
  string
> = {
  FULL_TIME: 'Enter customer’s job title',
  PART_TIME: 'Enter customer’s job title',
  CONTRACTUAL_FREELANCER: 'Enter customer’s work activity or job title',
  DOMESTIC_WORKER: 'Enter customer’s work activity or job title',
  OWN_BUSINESS: 'Enter customer’s role',
  OFW: 'Enter customer’s job title',
};
