import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useCustomerAnalytics } from '~app/providers/analytics-provider';

import type { ApplicationProcessStatus } from '~entities/application';
import { getReleaseDate } from '~entities/application';

import { NotificationInline } from '@breeze-platform-ui/notification';

type Props = {
  processStatus: ApplicationProcessStatus;
};

export const Finalized = ({ processStatus }: Props) => {
  const { id } = useParams();
  const analytics = useCustomerAnalytics();

  useEffect(() => {
    id && analytics.trackFinishedScreenShown({ applicationId: id });
  }, [analytics, id]);

  return (
    <NotificationInline
      type="success"
      animateFirstRender={false}
      timer={false}
      showClose={false}
      withIcon={false}
      title={`${processStatus.message} on ${getReleaseDate(
        processStatus.dateUTC
      )}`}
    />
  );
};
