import { RadioLabeled } from '@breeze-platform-ui/label';
import { Col } from '@breeze-platform-ui/layout';

import styles from './pos-item.module.css';

import type { Pos } from '../pos';

type Props = {
  pos: Pos;
  selected: boolean;
  onSelect: (id: string) => void;
};

export const PosItem: React.FC<Props> = ({ pos, selected, onSelect }) => {
  return (
    <RadioLabeled
      key={pos.id}
      value={pos.id}
      name="store"
      checked={selected}
      onChange={(_event, { value }) => {
        onSelect(value as string);
      }}
    >
      <Col gaps={4} alignCross="start">
        <h4 className={styles.title}>{pos.name}</h4>
        <p className={styles.description}>{pos.fullAddress}</p>
      </Col>
    </RadioLabeled>
  );
};
