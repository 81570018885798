import { YesAnswer } from '../ui/yes-answer/yes-answer';

const options = [
  {
    value: 'true',
    label: <YesAnswer />,
  },
  {
    value: 'false',
    label: 'No, I’ll use my mobile phone',
  },
] as const;

export type ClientPhoneAssessmentValue = typeof options[number]['value'];

export const clientPhoneAssessmentOptions = options as unknown as {
  value: ClientPhoneAssessmentValue;
  label: string;
}[];
