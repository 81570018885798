import { calculateMonthlyPayment } from './loan-math';

import type { ProductParams, PaymentPlan } from '../product';

export function getPaymentPlans(
  {
    minMonthlyInterestRate,
    maxMonthlyInterestRate,
    minOriginationFeeFlat,
    maxOriginationFeeFlat,
    minOriginationFeeRate,
    maxOriginationFeeRate,
    termsInMonths,
  }: ProductParams,
  loanAmount: number
): PaymentPlan[] {
  return termsInMonths.map((term) => {
    const minMonthlyPayment = calculateMonthlyPayment(
      loanAmount,
      +term,
      minMonthlyInterestRate,
      minOriginationFeeRate,
      minOriginationFeeFlat
    );
    const maxMonthlyPayment = calculateMonthlyPayment(
      loanAmount,
      +term,
      maxMonthlyInterestRate,
      maxOriginationFeeRate,
      maxOriginationFeeFlat
    );
    return {
      term,
      minMonthlyPayment,
      maxMonthlyPayment,
    };
  });
}
