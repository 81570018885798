export const getBonusLevelFillingPercent = (
  count: number,
  lowerBound: number,
  upperBound: number
) => {
  // when the first interval starts with 0 - calculations are not correct as 0 counts as application
  const lower = lowerBound === 0 ? 1 : lowerBound;

  if (count >= upperBound) {
    return '100%';
  }
  if (count < lower) {
    return '0%';
  }
  return `${Math.floor(
    ((count - lower + 1) / (upperBound - lower + 1)) * 100
  )}%`;
};
