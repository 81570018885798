import { useEffect, useState, useCallback } from 'react';

import { environment } from '~app/environment';

import { AgentNewsItemScreen } from '~widgets/agent-news-item';

import {
  useGetBlockingNewsDetails,
  useGetNewsListQuery,
  blockingNewsViewTimeAtom,
  shouldMarkUnread,
  type AgentNewsInfo,
} from '~entities/agent-news';

import { HeaderWithLogo } from '~shared/ui/header-with-logo';
import { Loader } from '~shared/ui/loader';
import { Screen } from '~shared/ui/screen';

import { isToday } from 'date-fns';
import { useSetAtom, useAtomValue } from 'jotai';

import styles from './agent-news-blocking-screen.module.css';

// Show news on blocking screen that are not older than 2 weeks
const BLOCKING_NEWS_SHOW_AGE = 14 * 24 * 60 * 60 * 1000;

const shouldNewsBlockScreen = (item: AgentNewsInfo) => {
  if (item.importance === 'blocking' && shouldMarkUnread(item)) {
    return (
      new Date().getTime() - new Date(item.publishedAt).getTime() <
      BLOCKING_NEWS_SHOW_AGE
    );
  }
  return false;
};
export const NewsBlockingScreen = () => {
  const setBlockingNewsShownTime = useSetAtom(blockingNewsViewTimeAtom);
  const blockingNewsShowTime = useAtomValue(blockingNewsViewTimeAtom);

  const [showBlockingScreen, setShowBlockingScreen] = useState<boolean>();
  const [blockingNewsIds, setBlockingNewsIds] = useState<string[]>([]);
  const [activeNewsIndex, setActiveNewsIndex] = useState(0);

  const { data: newsList, isError: isNewsListError } = useGetNewsListQuery();
  const queries = useGetBlockingNewsDetails(blockingNewsIds);

  useEffect(() => {
    if (environment.AGENT_NEWS_FEATURE === 'ON') {
      if (blockingNewsShowTime && isToday(blockingNewsShowTime)) {
        setShowBlockingScreen(false);
      } else {
        setShowBlockingScreen(true);
      }
    } else {
      setShowBlockingScreen(false);
    }
  }, [blockingNewsShowTime]);

  useEffect(() => {
    // Get initial ids list of unread blocking news
    if (newsList && blockingNewsIds.length === 0) {
      const ids: string[] = [];
      newsList.items.forEach((item) => {
        if (shouldNewsBlockScreen(item)) {
          ids.push(item.id);
        }
      });
      ids.length === 0
        ? setBlockingNewsShownTime(new Date().getTime())
        : setBlockingNewsIds(ids);
    }
  }, [newsList, blockingNewsIds, setBlockingNewsShownTime]);

  useEffect(() => {
    if (isNewsListError) {
      // Skip showing blocking news if agent news list request is failed
      setBlockingNewsShownTime(new Date().getTime());
    }
  }, [isNewsListError, setBlockingNewsShownTime]);

  const currentQuery = queries[activeNewsIndex];

  useEffect(() => {
    // Skip failed news details to avoid blocking screen with error
    if (currentQuery?.isError) {
      setActiveNewsIndex((prev) => prev + 1);
    }
  }, [currentQuery?.isError]);

  useEffect(() => {
    if (
      blockingNewsIds.length > 0 &&
      activeNewsIndex === blockingNewsIds.length
    ) {
      setBlockingNewsShownTime(new Date().getTime());
    }
  }, [blockingNewsIds, activeNewsIndex, setBlockingNewsShownTime]);

  const handleMarkRead = useCallback(() => {
    setActiveNewsIndex((prev) => prev + 1);
  }, []);

  if (showBlockingScreen === undefined) {
    <div className={styles.container}>
      <Screen header={<HeaderWithLogo />}>
        <Loader />
      </Screen>
    </div>;
  }

  if (!showBlockingScreen) {
    return <></>;
  }

  return (
    <div className={styles.container}>
      <Screen header={<HeaderWithLogo />}>
        {currentQuery?.data ? (
          <AgentNewsItemScreen
            key={currentQuery.data.details.id}
            item={currentQuery.data.details}
            surveyData={currentQuery.data.surveyData}
            onMarkRead={handleMarkRead}
            isBlocking
          />
        ) : (
          <Loader />
        )}
      </Screen>
    </div>
  );
};
