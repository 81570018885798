import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { ApplicationDetails } from '~widgets/application-details';

import { useGetApplicationByIdQuery } from '~entities/application';

import { Loader } from '~shared/ui/loader';
import { NavBackButton } from '~shared/ui/nav-back-button/nav-back-button';
import { Screen } from '~shared/ui/screen';

import Text from '@breeze-platform-ui/text';

export const ApplicationDetailsPage = () => {
  const { id = '' } = useParams();

  const { isSuccess, isLoading, isError, data } =
    useGetApplicationByIdQuery(id);

  useEffect(() => {
    if (id) {
      window.clarity?.('set', 'application_id', id);
    }
  }, [id]);
  return (
    <Screen header={<NavBackButton href="/applications" />}>
      {isLoading && <Loader overlay alignTop="100px" />}
      {isSuccess && <ApplicationDetails application={data} />}
      {isError && (
        <Text size={15} align="center">
          Couldn’t load application details
        </Text>
      )}
    </Screen>
  );
};
