import { useState } from 'react';
import { useForm, type FieldErrors, Controller } from 'react-hook-form';

import { useUploadFileMutation } from '~entities/file';

import { useRevalidate } from '~shared/hooks';
import { getFieldsErrors } from '~shared/lib/get-fields-errors';
import { BasicHeader } from '~shared/ui/basic-header';
import { FormFooter } from '~shared/ui/form-footer';
import { FormHeader } from '~shared/ui/form-header';
import { NavCloseButton } from '~shared/ui/nav-close-button';
import { Screen } from '~shared/ui/screen';
import { ScreenTitle } from '~shared/ui/screen-title';
import { validators } from '~shared/validators';

import isEmpty from '@tinkoff/utils/is/empty';

import { ButtonLink } from '@breeze-platform-ui/button';
import {
  AttachFile,
  FILE_STATUS,
  type FileItem,
  Input,
} from '@breeze/rhf-adapters';
import { useNotifications } from '@pfa/front-notifications';

import { GCashHint } from './gcash-hint/gcash-hint';

import type { GCashDetailsFormValues } from '../types';

type Props = Readonly<{
  applicationId: string;
  name: string;
  initialValue?: GCashDetailsFormValues;
  onSubmit: (values: GCashDetailsFormValues) => void;
  onPrev: (
    values: GCashDetailsFormValues,
    errors: FieldErrors<GCashDetailsFormValues>
  ) => void;
  onClose: () => void;
}>;

export const GCashDetails: React.FC<Props> = ({
  name,
  applicationId,
  initialValue = {},
  onSubmit,
  onPrev,
  onClose,
}) => {
  const notifications = useNotifications();
  const { mutateAsync: upload } = useUploadFileMutation();
  const [showHint, setShowHint] = useState(false);

  const methods = useForm<GCashDetailsFormValues>({
    defaultValues: initialValue,
    mode: 'all',
  });
  const { control, getValues, handleSubmit, formState, watch } = methods;

  useRevalidate(methods);

  const handleClickButtonPrev = () => {
    const actualErrors = getFieldsErrors<GCashDetailsFormValues>(methods);
    onPrev(getValues(), actualErrors);
  };

  const handleFormSubmit = (value: GCashDetailsFormValues) => {
    const gCashFiles = getValues(['gCashProfilePhoto', 'gScorePhoto']);
    const images = gCashFiles.map((file) =>
      Array.isArray(file) ? (file[0] as FileItem) : file
    );

    if (images.some((image) => image?.status === FILE_STATUS.LOADING)) {
      notifications.warning('The files are still loading', {
        showClose: true,
        timer: 3_000,
      });
      return;
    }
    onSubmit(value);
  };

  const gScore = watch('gScore');

  if (showHint) {
    return (
      <Screen
        header={
          <BasicHeader
            actionButton={<NavCloseButton onClick={() => setShowHint(false)} />}
          />
        }
      >
        <GCashHint />
      </Screen>
    );
  }

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Screen
        header={<FormHeader onClick={onClose} text={name} />}
        footer={<FormFooter onClickPrev={handleClickButtonPrev} />}
      >
        <ScreenTitle title="GCash details" />
        <Controller
          control={control}
          name="gScore"
          rules={{
            min: { value: 1, message: 'GScore should be more than 0' },
            max: { value: 1000, message: 'GScore should be less than 1000' },
          }}
          render={(fp) => (
            <Input
              {...fp}
              field={{
                ...fp.field,
                onChange: (event, params) => {
                  const parsedValue = parseFloat(params.value);
                  const value = Number.isNaN(parsedValue) ? '' : parsedValue;

                  if (event?.target) {
                    // eslint-disable-next-line no-param-reassign
                    event.target.value = value;
                  }
                  fp.field.onChange(event, { ...params, value });
                },
              }}
              label="GScore"
            />
          )}
        />
        {!isEmpty(gScore) && !formState.errors.gScore && (
          <>
            <Controller
              control={control}
              name="gScorePhoto"
              rules={{
                validate: validators.fileRequired({
                  message:
                    "Please, take a photo of the customer's GScore screen",
                }),
              }}
              render={(fieldProps) => (
                <AttachFile
                  {...fieldProps}
                  uploadFile={(file: FileItem) =>
                    upload({
                      applicationId,
                      fileItem: {
                        docType: 'GCASH_SCORE',
                        frontImage: file.file,
                      },
                    })
                  }
                  single
                  preview
                  accept="image/*"
                  capture="environment"
                  labels={{
                    common: 'Take a photo of a GScore screen',
                    desktop: ' ',
                    dragging: ' ',
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="gCashProfilePhoto"
              rules={{
                validate: validators.fileRequired({
                  message:
                    "Please, take a photo of the customer's GCash profile screen",
                }),
              }}
              render={(fieldProps) => (
                <AttachFile
                  {...fieldProps}
                  uploadFile={(file: FileItem) =>
                    upload({
                      applicationId,
                      fileItem: {
                        docType: 'GCASH_PROFILE',
                        frontImage: file.file,
                      },
                    })
                  }
                  single
                  preview
                  accept="image/*"
                  capture="environment"
                  labels={{
                    common: 'Take a photo of a GCash profile',
                    desktop: ' ',
                    dragging: ' ',
                  }}
                />
              )}
            />
            <ButtonLink wide size="l" onClick={() => setShowHint(true)}>
              How to take these photos
            </ButtonLink>
          </>
        )}
      </Screen>
    </form>
  );
};
