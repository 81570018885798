import type { IdDocumentType } from '../model';

export const idDocumentTypeNames: { [type in IdDocumentType]: string } = {
  UMID: 'UMID',
  SSS: 'SSS ID',
  PRC: 'PRC ID',
  PASSPORT: 'Philippine passport',
  DRIVING_LICENSE: "Driver's license",
  PHIL_ID: 'PhilSys ID',
  POSTAL_ID: 'Postal ID',
  TIN: 'TIN ID',
  GSIS: 'GSIS ID',
};
