import { logger } from '~entities/logger';

import request from '@tinkoff/request-core';
import type { Request } from '@tinkoff/request-core';
import type { Plugin } from '@tinkoff/request-core';
import log from '@tinkoff/request-plugin-log';
import http from '@tinkoff/request-plugin-protocol-http';
import validate from '@tinkoff/request-plugin-validate';

import { ResponseFormatError, errorFormatter } from '../errors';
import type { ErrorFormatter } from '../errors';
import type { RequestParams } from '../request-params';
import { getUrlFromParams } from '../request-params';
import { isScpResponse } from '../response';
import type { ResponseSuccess } from '../response';

export type ScpClient = {
  call<T>(params: RequestParams): Promise<ResponseSuccess<T>>;
};

function formatErrorPlugin(formatter: ErrorFormatter): Plugin {
  return {
    error: (context, next) => {
      const state = context.getState();
      const newError = formatter(state);

      if (newError) {
        return next({ error: newError });
      }

      return next();
    },
  };
}

const makeRequest = request([
  log({ name: 'client-api', logger }),
  formatErrorPlugin(errorFormatter),
  validate({
    validator({ response }) {
      if (!isScpResponse(response)) {
        return new ResponseFormatError(response);
      }
    },
  }),
  http(),
]);

export const webRequestClient: ScpClient = {
  call<T>(params: RequestParams): Promise<ResponseSuccess<T>> {
    const url = params.url || getUrlFromParams(params);
    const requestParams: Request = {
      httpMethod: params.httpMethod || 'POST',
      url,
      type: params.type ?? 'json',
      timeout: params.timeout ?? 20 * 1000,
    };

    if ('payload' in params) {
      requestParams.payload = params.payload;
    }

    if ('headers' in params) {
      requestParams.headers = params.headers;
    }

    if ('withCredentials' in params) {
      requestParams.withCredentials = params.withCredentials;
    }
    if ('cache' in params) {
      requestParams.cache = params.cache;
    }

    return makeRequest(requestParams).then((response) => ({
      status: 'OK',
      payload: response.payload,
    })) as Promise<ResponseSuccess<T>>;
  },
};
