import { useEffect, useState } from 'react';

import { useCustomerAnalytics } from '~app/providers/analytics-provider';

import { useOnMountEffect } from '~shared/hooks';
import errorImage from '~shared/images/doc-error.svg';

import Button from '@breeze-platform-ui/button';
import { Col } from '@breeze-platform-ui/layout';

import styles from './agreement-fallback.module.css';

type Props = {
  applicationId: string;
  agreement: File;
};

export const AgreementFallback = (props: Props) => {
  const { agreement, applicationId } = props;
  const [link, setLink] = useState<string>();

  const customerAnalytics = useCustomerAnalytics();

  useOnMountEffect(() => {
    customerAnalytics.trackAgreementFallbackShown({ applicationId });
  });

  useEffect(() => {
    const pdfObjectUrl = window.URL.createObjectURL(agreement);
    setLink(pdfObjectUrl);

    return () => {
      window.URL.revokeObjectURL(pdfObjectUrl);
    };
  }, [agreement]);

  const handleButtonClick = () => {
    customerAnalytics.trackAgreementDownloadClicked({ applicationId });
  };

  return (
    <Col gaps="l" margin={72} alignCross="center">
      <img src={errorImage} width="180" height="180" alt="warning" />
      <p className={styles.errorTitle}>
        Couldn’t open the file. <br />
        Download the agreement to show it to the customer
      </p>
      <Button
        wide
        size="m"
        theme="secondary"
        href={link}
        target="_blank"
        disabled={!link}
        download={`agreement-${applicationId}.pdf`}
        onClick={handleButtonClick}
      >
        Download the agreement
      </Button>
    </Col>
  );
};
