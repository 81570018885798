import React, { useEffect, useMemo, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import {
  getMinDownPayment,
  loanMath,
  getMaxDownPayment,
  getRangeMessage,
  getPaymentPlans,
  validateDownpaymentRange,
  type ProductParams,
  type PaymentPlan,
} from '~entities/product';
import { getDefaultDownPayment } from '~entities/product';

import { phMoneyProps } from '~shared/constants/ph-money-props';
import { useOnMountEffect } from '~shared/hooks';
import { SectionTitle } from '~shared/ui/section-title';
import { validators } from '~shared/validators';

import { Box, Col } from '@breeze-platform-ui/layout';
import Money from '@breeze-platform-ui/money/Money';
import { RadioGroup, InputMoney } from '@breeze/rhf-adapters';

import styles from './setup-loan.module.css';
import { TermsHint } from './terms-hint';

import {
  DOWNPAYMENT_LABEL,
  DOWNPAYMENT_REQUIRED_ERROR,
  PERIOD_REQUIRED_ERROR,
} from '../constants';
import { getPaymentPlanOptions } from '../lib';

export type FormValues = {
  downPayment: string;
  period: string;
};

export type LoanSettings = FormValues & {
  paymentPlans: PaymentPlan[];
};
const loanMoneyProps = { ...phMoneyProps, precision: 2 };

type Props = {
  totalPrice: number;
  loanParams: ProductParams;
  onPaymentPlansChanged?: (values: PaymentPlan[]) => void;
};

export const SetupLoan = React.memo(
  ({ totalPrice, loanParams, onPaymentPlansChanged }: Props) => {
    const { control, watch, formState, trigger, setValue, getValues } =
      useFormContext<FormValues>();
    const {
      minLoanAmount,
      maxLoanAmount,
      minDownPaymentPercent,
      maxDownPaymentPercent,
    } = loanParams;

    const isInitial = useRef<boolean>(true);
    const downPayment = watch('downPayment');

    const minDownpayment = getMinDownPayment(totalPrice, {
      maxLoanAmount,
      minDownPaymentPercent,
    });

    const maxDownPayment = getMaxDownPayment(totalPrice, {
      minLoanAmount,
      maxDownPaymentPercent,
    });

    const defaultDp = getDefaultDownPayment(totalPrice, {
      maxLoanAmount,
      minDownPaymentPercent,
      maxDownPaymentPercent,
    });

    useOnMountEffect(() => {
      const currentDownPayment = getValues('downPayment');
      let dp = +currentDownPayment;

      if (!currentDownPayment || +currentDownPayment < minDownpayment) {
        dp = defaultDp;
      }
      if (+currentDownPayment > maxDownPayment) {
        dp = maxDownPayment;
      }
      setValue('downPayment', `${dp}`);
      const loanAmount = loanMath.calculateLoanAmount(totalPrice, dp);
      onPaymentPlansChanged?.(getPaymentPlans(loanParams, loanAmount));
    });

    // we need to revalidate downPayment when totalPrice changes
    useEffect(() => {
      trigger('downPayment');
    }, [totalPrice, loanParams, trigger]);

    const downPaymentValid = !formState.errors.downPayment;

    const loanAmount = downPaymentValid
      ? loanMath.calculateLoanAmount(totalPrice, +downPayment)
      : 0;

    const paymentPlans = useMemo(() => {
      if (loanAmount > 0) {
        return getPaymentPlans(loanParams, loanAmount);
      }
      return [];
    }, [loanParams, loanAmount]);

    useEffect(() => {
      if (isInitial.current) {
        isInitial.current = false;
      } else {
        onPaymentPlansChanged?.(paymentPlans);
      }
    }, [paymentPlans, onPaymentPlansChanged]);

    const downPaymentMessage = getRangeMessage(minDownpayment, maxDownPayment);

    const isZeroDownpayment =
      minDownPaymentPercent === 0 && maxDownPaymentPercent === 0;

    return (
      <>
        <Box margin="0 0 16px 0">
          <SectionTitle title="Loan details" />
        </Box>
        {!isZeroDownpayment && (
          <Controller
            name="downPayment"
            control={control}
            rules={{
              required: DOWNPAYMENT_REQUIRED_ERROR,
              validate: (value) =>
                // react-hook-form doesn't accept ReactNode as error, but FormRow does -> have to use any
                validateDownpaymentRange(+value, totalPrice, {
                  maxLoanAmount,
                  minLoanAmount,
                  minDownPaymentPercent,
                  maxDownPaymentPercent,
                }) as any,
            }}
            render={(props) => (
              <InputMoney
                {...props}
                {...phMoneyProps}
                cleanable
                currencyInValue
                withValidityMark={false}
                label={DOWNPAYMENT_LABEL}
                emptyValue={minDownpayment}
                message={!props.fieldState.error && downPaymentMessage}
              />
            )}
          />
        )}

        {paymentPlans.length > 0 && (
          <>
            <Controller
              name="period"
              control={control}
              rules={{
                validate: validators.required({ text: PERIOD_REQUIRED_ERROR }),
              }}
              render={(props) => (
                <RadioGroup
                  {...props}
                  vertical
                  wide
                  options={getPaymentPlanOptions(paymentPlans) as any}
                />
              )}
            />
          </>
        )}

        <Col className={styles.footnote} margin="0 0 0 16px" gaps={10}>
          <TermsHint {...loanParams} />
          {loanAmount > 0 && (
            <span>
              Loan amount:&nbsp;
              <Money {...loanMoneyProps} value={loanAmount} />
            </span>
          )}
        </Col>
      </>
    );
  }
);
