import type {
  AgentIncentive,
  IncentiveProgramStep,
} from '~entities/agent-incentive';

export const getBonusNextLevelMessage = (
  currentCount: number,
  steps: IncentiveProgramStep[]
) => {
  const currentLevelInterval = steps.find(
    (level) =>
      currentCount >= level.lowerBound &&
      (!level.upperBound || currentCount <= level.upperBound)
  );

  if (currentLevelInterval) {
    if (!currentLevelInterval.upperBound) {
      return undefined;
    }

    const nextLevelCount = currentLevelInterval.upperBound + 1 - currentCount;

    return currentLevelInterval.bonusAmountPerApplication === 0 ? (
      <>
        {nextLevelCount}&nbsp;more loans this month to&nbsp;start earning
        bonuses!
      </>
    ) : (
      <>
        {nextLevelCount}&nbsp;more loans this&nbsp;month to&nbsp;level&nbsp;up!
      </>
    );
  }
};

export const getCurrentBonusMessage = (bonus: number) => <>₱{bonus} per loan</>;

export const getBonusMessage = (
  isAldi: boolean,
  { bonus, steps }: AgentIncentive
) => {
  if (isAldi) {
    return <>₱{steps[0].bonusAmountPerApplication} per loan</>;
  }
  return getBonusNextLevelMessage(bonus.totalApplicationsCount, steps);
};
