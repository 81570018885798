import type {
  PersonFormatter,
  PersonRecovery,
  PersonScreensMapping,
} from '~entities/person/lib';
import {
  formatPersonValuesByMapping,
  recoverPersonValuesByMapping,
} from '~entities/person/lib';

import { formatDate, recoverDate } from '~shared/formatters';

import type { FormValues } from '../types';

const mapping: PersonScreensMapping<Omit<FormValues, 'idPhoto'>> = {
  firstName: { path: ['name', 'firstName'] },
  lastName: { path: ['name', 'lastName'] },
  middleName: { path: ['name', 'middleName'] },
  birthDate: {
    path: ['birthDate'],
    format: formatDate,
    recover: recoverDate,
  },
  idNumber: {
    path: ['document', 'idNumber'],
  },
  idType: {
    path: ['document', 'type'],
  },
};

export const formatIdDocument: PersonFormatter<FormValues> = (
  person,
  screenValues
) => {
  if (
    // the user hasn't filled id number or id type yet
    !screenValues.idNumber ||
    !screenValues.idType ||
    // the user has changed document type
    person.document?.type !== screenValues.idType
  ) {
    return {
      ...formatPersonValuesByMapping(person, screenValues, mapping),
      document: undefined,
    };
  }

  return formatPersonValuesByMapping(person, screenValues, mapping);
};

export const recoverIdDocument: PersonRecovery<FormValues> = (person) => {
  return recoverPersonValuesByMapping(person, mapping);
};
