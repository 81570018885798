import type { createMachine } from 'xstate';

export class StepFlowStrategy<Steps extends string> {
  private history: Steps[] = [];
  private machine: ReturnType<typeof createMachine>;

  constructor(machine: ReturnType<typeof createMachine>) {
    this.machine = machine;
    this.current = this.machine.initial as Steps;
  }

  public get current(): Steps {
    return this.history[this.history.length - 1];
  }

  public set current(step) {
    if (this.current !== step) {
      this.history.push(step);
    }
  }

  private getNextState(
    currentStep: Steps,
    state?: Partial<Record<Steps, any>>
  ) {
    return this.machine.transition(currentStep, { type: 'next' }, state);
  }

  next(state?: Partial<Record<Steps, any>>): Steps {
    this.current = this.getNextState(this.current, state).value as Steps;

    return this.current;
  }

  prev(): Steps {
    this.history.pop();
    return this.current;
  }

  getHistory(): Steps[] {
    return this.history;
  }

  goTo(step: Steps) {
    const stepIndex = this.history.indexOf(step);

    if (stepIndex !== -1) {
      this.history = this.history.slice(0, stepIndex);
    }

    this.current = step;
  }

  getFullFlow(state: Partial<Record<Steps, any>>): Steps[] {
    const pastSteps = this.getHistory();
    let nextState = null;
    const futureSteps = [];

    while (!nextState?.done) {
      const previousValue = (nextState?.value || this.current) as Steps;

      nextState = this.getNextState(previousValue, state);

      if (nextState.value === previousValue) {
        // we've reached dead-end state(final or blocked by guards)
        break;
      }

      futureSteps.push(nextState.value as Steps);
    }

    return [...pastSteps, ...futureSteps];
  }
}
