import { useEffect, useRef } from 'react';

import Button from '@breeze-platform-ui/button';
import { Row, Col } from '@breeze-platform-ui/layout';

import styles from './barcode-detection.module.css';

import { drawCanvas } from '../lib';

export type DetectedBarcodeInfo = {
  value: string;
  coordinates: { top: number; right: number; bottom: number; left: number };
};

export type DetectedData = {
  value: string;
  file: File;
};

type Props = {
  screenshot?: ImageBitmap;
  detectedBarcodeInfo?: DetectedBarcodeInfo;
  onRescan: () => void;
  onDone: (info: DetectedData) => void;
};

export const DetectedBarcodeScreen = ({
  screenshot,
  detectedBarcodeInfo,
  onRescan,
  onDone,
}: Props) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (canvasRef.current && screenshot)
      drawCanvas(canvasRef.current, screenshot);
  }, [screenshot]);

  if (screenshot && detectedBarcodeInfo) {
    return (
      <div className={styles.container}>
        <canvas style={{ height: '100%' }} ref={canvasRef} />
        <div
          className={styles.detectedFrame}
          style={{
            top: `${Math.ceil(detectedBarcodeInfo.coordinates.top) - 5}px`,
            left: `${Math.ceil(detectedBarcodeInfo.coordinates.left) - 10}px`,
            width: `${
              Math.ceil(detectedBarcodeInfo.coordinates.right) -
              Math.ceil(detectedBarcodeInfo.coordinates.left) +
              20
            }px`,
            height: `${
              Math.ceil(detectedBarcodeInfo.coordinates.bottom) -
              Math.ceil(detectedBarcodeInfo.coordinates.top) +
              10
            }px`,
          }}
        />

        <Col className={styles.screenshotFooter} alignCross="center" gaps="m">
          <div className={styles.detectedValue}>
            {detectedBarcodeInfo.value}
          </div>
          <Row width="100%" gaps="m">
            <Button
              wide
              color="white"
              theme={{ border: 'none', background: 'rgba(0, 0, 0, 0.40)' }}
              onClick={onRescan}
            >
              Rescan
            </Button>
            <Button
              wide
              theme="primary"
              onClick={() => {
                if (canvasRef.current) {
                  canvasRef.current.toBlob((blob) => {
                    if (blob) {
                      const file = new File([blob], 'item-photo.jpg', {
                        type: 'image/jpeg',
                      });
                      onDone({
                        value: detectedBarcodeInfo.value,
                        file,
                      });
                    }
                  }, 'image/jpeg');
                }
              }}
            >
              Done
            </Button>
          </Row>
        </Col>
      </div>
    );
  }
  return <></>;
};
