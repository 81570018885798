import type { PersonFormatter, PersonRecovery } from '~entities/person/lib';
import {
  formatPersonValuesByMapping,
  recoverPersonValuesByMapping,
} from '~entities/person/lib';
import type { PersonScreensMapping } from '~entities/person/lib';

import type { FormValues } from '../ui/alternative-contacts';

const mapping: PersonScreensMapping<FormValues> = {
  additionalPhone: { path: ['additionalPhone'] },
  name: { path: ['additionalContacts', 0, 'name'] },
  type: { path: ['additionalContacts', 0, 'type'] },
  familyRelation: {
    path: ['additionalContacts', 0, 'familyRelation'],
  },
  number: { path: ['additionalContacts', 0, 'number'] },
};

export const formatAlternativeContacts: PersonFormatter<FormValues> = (
  application,
  screenValues
) => {
  return formatPersonValuesByMapping(application, screenValues, mapping);
};

export const recoverAlternativeContactNumber: PersonRecovery<FormValues> = (
  person
) => {
  return recoverPersonValuesByMapping(person, mapping);
};
