/* eslint-disable react/no-unused-prop-types */
import type { PropsWithChildren } from 'react';
import React from 'react';

interface Props extends PropsWithChildren {
  name: string;
}

export const Step: React.FC<Props> = ({ children }) => {
  return <>{children}</>;
};
/* eslint-enable react/no-unused-prop-types */
