import {
  phoneMasksWithMetaPh,
  PhoneNumberType,
} from './phone-masks-with-meta-ph';
import type { MaskWithMeta } from './phone-masks-with-meta-ph';

export const prefix = '+63';

const regExpPrefix = new RegExp(`^(\\${prefix})`);

const isLandlinePhone = (value: string) => value.startsWith(`${prefix}2`);
const isCombinedPhone = (value: string) =>
  RegExp(`^(\\${prefix}[1-7])`).test(value) ||
  RegExp(`^(\\${prefix}8[1-8])`).test(value);

const getPhoneNumberType = (value: string): PhoneNumberType => {
  if (isLandlinePhone(value)) {
    return PhoneNumberType.landline;
  }

  if (isCombinedPhone(value)) {
    return PhoneNumberType.combined;
  }

  return PhoneNumberType.mobile;
};

export const getPhoneMask = (value: string) => {
  const type = getPhoneNumberType(value);
  return phoneMasksWithMetaPh[type];
};

export const getMaskedPhoneNumber = (mask: MaskWithMeta, value: string) =>
  mask.instance.mask(value.replace(regExpPrefix, ''));
