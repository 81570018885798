import type { ComponentProps } from 'react';
import React from 'react';

import { Box } from '@breeze-platform-ui/layout';

import styles from './section-title.module.css';

type Props = {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
} & Omit<ComponentProps<typeof Box>, 'title' | 'subtitle'>;

export const SectionTitle: React.FC<Props> = ({
  title,
  subtitle,
  ...boxProps
}) => {
  return (
    <Box {...boxProps}>
      {title && <h2 className={styles.title}>{title}</h2>}
      {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
    </Box>
  );
};
