import type { ValueWithCurrency } from '~entities/vas';

export function getPriceFromValueWithCurrency(
  value?: ValueWithCurrency
): number {
  if (!value || Number.isNaN(+value.value)) {
    return 0;
  }

  return +value.value;
}
