import noop from '@tinkoff/utils/function/noop';

import type { Geolocation } from './types';

const GEOLOCATION_KEY = 'device_location';

type DeviceLocation = {
  initialize: () => void;
  getLocation: () => Geolocation;
};

const getDeviceLocation = (): DeviceLocation => {
  const parseLocation = (storageLocation: string | null) => {
    if (!storageLocation) {
      return {};
    }
    try {
      const location = JSON.parse(storageLocation);
      return location;
    } catch (error) {
      return {};
    }
  };
  const initialize = () => {
    const location: Geolocation = {};
    localStorage.setItem(GEOLOCATION_KEY, JSON.stringify(location));

    const onSuccess = (position: GeolocationPosition) => {
      location.deviceLatitude = position.coords.latitude;
      location.deviceLongitude = position.coords.longitude;
      localStorage.setItem(GEOLOCATION_KEY, JSON.stringify(location));
    };

    navigator.geolocation.watchPosition(onSuccess, noop, {
      enableHighAccuracy: true,
      timeout: 10000,
    });
  };

  initialize();
  return {
    initialize,
    getLocation: () => parseLocation(localStorage.getItem(GEOLOCATION_KEY)),
  };
};

export const deviceLocation = getDeviceLocation();
