import { environment } from '~app/environment';

const attendanceLogEmployeeIdsTest = new Set([
  '5e5facde-7d90-465b-b80e-2ddc981beb08',
]);
const attendanceLogEmployeeIdsProd = new Set([
  // batch 1
  'ebbee39d-76b9-4f72-ab4e-4d416700f646', // Testov Test
  'a9c4edbb-aecd-4c6e-a946-aa2e5ea14ffe', // Georgiy Korobchinskiy
  '09cf4b87-0ee4-4992-90e4-f5dd895fc6af', // Kevin garth Benamer
  '47e4464d-a865-48b1-9046-e1414f8dd8f5', // Ramon Ramento
  '2d4f39ea-696d-4365-8c9c-f42035df9a7a', // Janzen Brian Beltran
  '1b40ed1d-0a0e-4aa1-bbf2-2e3d4460ccf9', // Jomel Arriola
  'd452193a-517c-4877-841d-80f70b6d67f8', // Kim Claire Jotie
  '10959779-f1bf-4d13-a2b2-088c17d6718a', // Marites Yatop
  '12a55c46-2386-4fb6-957a-144672df2ef1', // Antonette Abac
  '7d104de8-8420-47b1-ad61-bcc4ec51987d', // Justin Crisostomo Sancio
  '3c6169f6-b22f-4bf0-8b12-a556db7303ae', // Victor Jr. Duterte
  '9197f7e0-474f-44ff-bb2f-6b7fd54e48b1', // Ma. Luisa Navacilla
  'ffa83d16-f1c1-4af4-a48f-90f67f8c2418', // Danilo Jr Mendoza
  '52d8bef5-4012-4ceb-a9f5-6029fc8e20e6', // Evgeniy Filkin

  // batch 2
  '1aa009ec-f897-46eb-bcac-e7994dfec499', // Aldrin Ayende
  '3a0fae7f-33fe-4e55-b985-dd16a18c7ef7', // Amy Arguelles
  '0a123ee8-4c47-4a97-b661-4f579dac4454', // Anabel Rico
  '343106af-97af-484c-95c7-6cf7fae97a72', // Abegail Dulfo
  '9666f9ee-810b-4ec8-a8eb-569a1e64a573', // Aime Anchovas
  '84f4f0c2-d8e7-427a-94d8-69630c617055', // Aleway Flores
  'a3209d5b-9af8-4d35-83d9-569c1ec4e627', // Aluna Cuyacot
  '5f222413-3c7e-47e6-a5c1-bc52c9b230d5', // Aira Ariza Alcantara
  'cf4db38e-4c4a-4a3a-853b-dff5923b4cfa', // Aivie Bugagao
  'e0b3ecc6-d8e8-4e95-b712-b5a7dc29e594', // Al Lawrence Alcantara
  '566aa8e7-1855-43cf-8b77-8ee192272d9a', // Allyza De Las Alas
  '59994d02-7ff5-42d8-a920-5d638c292b33', // Alwyne Dipalac
  'e25a2b86-5fba-482f-94e8-de0343bf30d4', // Arcee Jane Nieto
  'f9dc9748-ecda-4609-964c-7d11acf003b6', // Germel Cruz
  '519d11eb-b687-4bb1-a3f6-2065a3765d28', // Ace Anthony Dacoliat
  '3d92e853-945e-4bdf-9b6a-651abed702e6', // Abigail Arevalo
  'd95beeb7-a4b6-4b7d-9180-21c273a4b384', // Aldrich Loterte
  '5a3ff8c6-a5d6-4cd7-9988-79696b1e176f', // Ana Jean Gumonit
  '86b884b2-1d61-480a-a8a9-f0171ca09bd3', // Ahmed Khan Paladio
  'daf1c4e1-91e6-4665-a3ad-5101a8d9b5bc', // Aibe Diamante
  'ad3f0bce-e0a4-4c0b-baac-a4bd9f8c00ef', // Alma Dumaboc
  '0fc57585-3d99-461b-b07c-4bf07ce1a6bc', // Amanda Godiz
  '236c79c7-c01a-4851-b899-c3d06488702a', // Jessajane Mandreza
  '88f96e43-1bd3-472b-b64d-e0aa0d0b8975', // Abeguel Ragundiaz
  '396f95dc-31a3-4245-9257-9a00ff1503f7', // Mary grace '' Lupango
  '6718e723-bd5a-4561-8d26-d75796c67317', // Addara Humam
  '95786356-7d77-4cb4-b94a-e7275e1fd4d1', // Allen Valiente
  '78cbe8f3-e482-4e92-9766-aadf47442e20', // Ana Rose Cuevas
  '0241835c-9ded-4cc2-a799-36169038796b', // Al Aries Mangaliman
  '72f2f53a-cb79-429c-baca-462cbfa0d899', // Alsidar Adzhar
  '52ca33c0-64b3-4101-838f-52b042d23cd1', // Adrian Sapno
  '11aff352-06c3-4390-bce4-d48758df1666', // Ana Sabulao
  '03b67d06-de27-41d1-afb5-db9621c3fe99', // Christian Velches
  'ba6f5912-e0ce-4c06-9d5a-539a6390c050', // Mark Anthony Manzano
  'd9170712-ad86-4601-bab6-201acfeed993', // Abbejoy Huyong
  'caeb3077-8495-42c1-b25f-552cbbae17da', // Aispearl Jean Basilio
  '474825ef-003f-46ac-be0d-464e558bd94f', // Albert Yant Arrobang
  '14eebf57-d9dd-4c57-a765-d74f7e1e4282', // Edgardo Jr Lomillo
  '55b4e1a6-431e-4341-a340-415efa1e8da3', // Alfredo Jr Estorco
  '8910dcdd-e8e9-49ed-b5c5-18fe1d75024c', // Regie Bayuhan
]);

export const isAttendanceLogOn = (employeeId: string) => {
  if (environment.ENV === 'prod') {
    return attendanceLogEmployeeIdsProd.has(employeeId);
  }
  return attendanceLogEmployeeIdsTest.has(employeeId);
};
