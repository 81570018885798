import { type ApprovedProductOption } from '~entities/product';

type ProductOptionsMap = Record<string, ApprovedProductOption>;

export const getProductOptionsMap = (
  options: ApprovedProductOption[]
): ProductOptionsMap => {
  return options.reduce((optionsMap, option) => {
    // eslint-disable-next-line no-param-reassign
    optionsMap[option.id] = option;
    return optionsMap;
  }, {} as ProductOptionsMap);
};
