import { useDi } from '~app/providers/di-provider';
import { useTracking } from '~app/providers/tracking-provider';

import { ConfirmationState } from '~features/auth/otp';

import type { FindOrCreateApplicationError } from '~entities/auth';
import {
  isChallengeConfirmationError,
  isFindOrCreateApplicationError,
  useGetUserInfoQuery,
} from '~entities/auth';
import { getDeviceMetadata } from '~entities/device-metadata';

import { isAuthError } from '~shared/errors';

interface Props {
  phoneNumber: string;
  onSuccess(): void;
  onError(error: Error): void;
}

export function ClientSmsConfirmation(props: Props) {
  const { phoneNumber, onSuccess, onError } = props;
  const { authApi } = useDi();
  const tracking = useTracking();
  const { refetch: getUserInfo } = useGetUserInfoQuery({
    queryKey: ['client-user-info'],
    enabled: false,
  });

  return (
    <ConfirmationState
      phoneNumber={phoneNumber}
      confirmationType="login"
      isConfirmationError={isChallengeConfirmationError}
      isAuthorizationError={isAuthError}
      isAttemptsLimitError={() => false}
      onCodeSubmit={async (code) => {
        try {
          await authApi.upgradeRespond({
            answer: code,
            userMetadata: {
              webFingerprint: getDeviceMetadata(tracking?.state),
            },
          });
          getUserInfo();
        } catch (err) {
          if (isFindOrCreateApplicationError(err as Error)) {
            onError(err as FindOrCreateApplicationError);
          }
          throw err;
        }
      }}
      onResend={async () => {
        await authApi.upgradeResendConfirmation();
      }}
      onSuccess={onSuccess}
    />
  );
}
