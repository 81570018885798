import Cookies from 'js-cookie';

interface SetCookiePayload {
  name: string;
  value: string;
  expires?: number;
}

export const setCookie = (payload: SetCookiePayload): void => {
  Cookies.set(payload.name, payload.value, { expires: payload.expires });
};

export const getCookie = (name: string): string | undefined =>
  Cookies.get(name);

export const deleteCookie = (name: string): void => Cookies.remove(name);
