import type { AbstractOption } from '~shared/types/abstract-option';

const options = [
  {
    title: 'Mobile number',
    value: 'MOBILE',
  },
  {
    title: 'Promo code',
    value: 'CODE',
  },
] as const;

export type OfferSource = typeof options[number]['value'];
export const offerSourceOptions =
  options as unknown as AbstractOption<OfferSource>[];
