import type { AbstractOption } from '~shared/types/abstract-option';

const employeeOptions = [
  {
    value: 'MANAGER',
    title: 'Manager, lead, or supervisor',
  },
  {
    value: 'COWORKER',
    title: 'Co-worker',
  },
  {
    value: 'HR',
    title: 'HR department',
  },
  {
    value: 'FRONTDESK',
    title: 'Front desk',
  },
] as const;

export type ReferenceEmployeeValue = typeof employeeOptions[number]['value'];
export const referenceOptionsEmployee =
  employeeOptions as unknown as AbstractOption[];
