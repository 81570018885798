import {
  getPhoneMask,
  getMaskedPhoneNumber,
} from '~shared/lib/format-phone-number';

import { Col } from '@breeze-platform-ui/layout';
import Text from '@breeze-platform-ui/text';

import { formatAgreementId } from '../lib/format-agreement-id';

type Props = {
  name: string;
  phoneNumber: string;
  agreementId?: string;
};
export const UserInfo = ({ name, phoneNumber, agreementId }: Props) => {
  const mask = getPhoneMask(phoneNumber);
  return (
    <Col gaps={4}>
      <Text size={24} dataQaType="user-name">
        {name}
      </Text>
      <div>
        <Text size={15} dataQaType="user-phone" color="rgba(0, 0, 0, 0.8)">
          {getMaskedPhoneNumber(mask, phoneNumber)}
        </Text>
        {agreementId && (
          <Text size={15} dataQaType="user-phone" color="rgba(0, 0, 0, 0.8)">
            Loan ID: {formatAgreementId(agreementId)}
          </Text>
        )}
      </div>
    </Col>
  );
};
