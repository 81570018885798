import { type AgentNewsInfo, type AgentNewsDetails } from '../agent-news';

export const isItemImportant = (item: AgentNewsInfo) =>
  item.importance === 'blocking' ||
  item.importance === 'important-non-blocking';

export const isSurvey = (item: AgentNewsDetails) =>
  item.surveyQuestions.length > 0;

export const isSurveyExpired = (item: AgentNewsInfo) =>
  item.surveyDueDate && new Date(item.surveyDueDate) < new Date();

export const shouldMarkUnread = (item: AgentNewsInfo) =>
  !item.isRead && !isSurveyExpired(item);
