import { Navigate } from 'react-router-dom';

import { usePortalConfig } from '~app/providers/portal-config-provider';

import { AgentAccount } from '~widgets/agent-account';

import { isAttendanceLogOnAtom } from '~entities/attendance';

import { useAtomValue } from 'jotai';

export const MyAccountPage = () => {
  const { customerServicePhone } = usePortalConfig();
  const isAttendanceLogOn = useAtomValue(isAttendanceLogOnAtom);

  if (!isAttendanceLogOn) {
    return <Navigate to="account" replace />;
  }

  return <AgentAccount customerServicePhone={customerServicePhone} />;
};
