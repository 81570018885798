import Clickable from '@breeze-platform-ui/clickable';
import Text from '@breeze-platform-ui/text';

import styles from './action-button.module.css';

type Props = {
  Icon: React.FC<React.SVGAttributes<SVGSVGElement>>;
  label: string;
  dataQaType?: string;
  onClick: () => void;
};

export const ActionButton: React.FC<Props> = ({
  Icon,
  label,
  onClick,
  dataQaType,
}) => (
  <Clickable
    dataQaType={dataQaType}
    className={styles.clickableContainer}
    onClick={onClick}
  >
    <div className={styles.buttonContainer}>
      <Icon color="#4D77FF" width={24} height={24} />
    </div>
    <Text size={13} align="center" whiteSpace="nowrap" color="#4D77FF">
      {label}
    </Text>
  </Clickable>
);
