import {
  isAbortError,
  isHttpError,
  isTimeoutError,
} from '@tinkoff/request-plugin-protocol-http';

import { AbortError, HttpError, TimeoutError } from '../errors';

export function adaptTramvaiHttpClientErrors(error: Error) {
  if (isAbortError(error)) {
    return new AbortError(error);
  }
  if (isTimeoutError(error)) {
    return new TimeoutError(error);
  }
  if (isHttpError(error)) {
    return new HttpError(error);
  }

  return error;
}
