import { useNavigate } from 'react-router-dom';

import { LoanDetailsPage } from '~pages/loan-details';

import {
  ClientConfirmationGuardHOC,
  ActiveApplicationGuardHOC,
} from '~features/auth/guard';
import { CheckAttendanceGuardHOC } from '~features/check-attendance';
import { SelectedPosGuardHOC } from '~features/select-pos';

export default () => {
  const navigate = useNavigate();

  return (
    <CheckAttendanceGuardHOC onCancel={() => navigate('/applications')}>
      <SelectedPosGuardHOC>
        <ClientConfirmationGuardHOC>
          <ActiveApplicationGuardHOC
            onAccessDenied={() => navigate('/applications')}
          >
            <LoanDetailsPage />
          </ActiveApplicationGuardHOC>
        </ClientConfirmationGuardHOC>
      </SelectedPosGuardHOC>
    </CheckAttendanceGuardHOC>
  );
};
