import { type LoanCalculatorFormValues } from '~widgets/loan-calculator';

import type { ActiveOffer } from '~features/manage-offer';

import { type AmplitudeCalculatorInfoPayload } from '~entities/amplitude-analytics';
import { type UserInfo } from '~entities/auth/auth';
import { type Pos } from '~entities/pos';
import { type ProductType } from '~entities/product';

type Params = {
  userInfo: UserInfo | null;
  selectedPos: Pos | null;
  loanCalcValues: Partial<LoanCalculatorFormValues>;
  productType: ProductType;
  forcedOffer?: ActiveOffer;
};
export const getCalculatorAnalyticsPayload = ({
  userInfo,
  selectedPos,
  loanCalcValues,
  productType,
  forcedOffer,
}: Params): AmplitudeCalculatorInfoPayload => {
  return {
    employeeId: userInfo?.employeeId,
    posId: selectedPos?.id,
    merchantId: selectedPos?.merchantId,
    productType,
    paymentPlans: loanCalcValues.paymentPlans,
    items: loanCalcValues.items,
    offerId: loanCalcValues.offer?.id || forcedOffer?.offer.id,
    totalPrice: loanCalcValues.totalPrice,
    productId: loanCalcValues.productId,
    campaignId: loanCalcValues.campaignId,
    downpayment: loanCalcValues.downPayment,
    loanTerm: loanCalcValues.period,
    invalidLoanMessage: loanCalcValues.invalidLoanMessage,
    isSearchOfferError: loanCalcValues.isSearchOfferError,
  };
};
