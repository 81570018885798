import type { ProductType } from '~entities/product';
import { isZeroRate } from '~entities/product';

export function getMaxItemsAmount(
  productType: ProductType,
  hasMultiChoice?: boolean
): number {
  if (isZeroRate(productType)) {
    return hasMultiChoice ? 3 : 1;
  }

  return 15;
}
