import type { AbstractOption } from '~shared/types/abstract-option';

export const options = [
  {
    value: '0.25',
    title: 'Less than 3 months',
  },
  {
    value: '1',
    title: 'From 3 months to a year',
  },
  {
    value: '1_3',
    title: '1\u20133 years',
  },
  {
    value: '4_5',
    title: '4\u20135 years',
  },
  {
    value: 'over_6',
    title: 'Over 6 years',
  },
];

export type WorkExperienceValue = typeof options[number]['value'];
export const workExperienceOptions =
  options as unknown as AbstractOption<WorkExperienceValue>[];
