import { useWatch, useFormContext } from 'react-hook-form';

import { LoadingButton } from '@breeze-platform-ui/button';
import { Col } from '@breeze-platform-ui/layout';

import styles from './see-agreement-button.module.css';

import { formatMoney } from '../../lib';
import type { LoanDetailsFormValues } from '../../types';

type Props = {
  isLoading: boolean;
  initialMonthlyPayment: number;
};

export const Button = ({ isLoading, initialMonthlyPayment }: Props) => {
  const { control } = useFormContext<LoanDetailsFormValues>();
  const monthlyVasPayment = useWatch({
    name: `totalVasMonthlyPayment`,
    control,
  });

  return (
    <LoadingButton
      wide
      disableVerticalPadding
      type="submit"
      loading={isLoading}
      disabled={isLoading}
    >
      <Col alignCross="center" margin={10}>
        <span className={styles.title}>See the agreement</span>
        <span>
          <span className={styles.money}>
            {formatMoney(monthlyVasPayment + initialMonthlyPayment)}
          </span>
          <span className={styles.text}>/month</span>
        </span>
      </Col>
    </LoadingButton>
  );
};
