import { environment } from '~app/environment';

import { getCookie, setCookie, deleteCookie } from '~shared/lib/cookies';

import * as amplitude from '@amplitude/analytics-browser';

import type { AmplitudeAnalytics, AmplitudeAnalyticsPayload } from '../types';

export const CLIENT_ANALYTICS_ID_COOKIE = 'client_analytics_id';

const getAnalytics = (): AmplitudeAnalytics => {
  amplitude?.init(environment.AMPLITUDE_ANALYTICS_KEY, {
    defaultTracking: false,
    appVersion: environment.VERSION,
  });

  let stringifiedLastTrackedEvent: string | undefined;

  function setUserId(id?: string) {
    amplitude?.setUserId(id);
  }

  function track(event: string, payload?: Record<string, any>) {
    amplitude?.track(event, {
      ...payload,
      client_analytics_id: getCookie(CLIENT_ANALYTICS_ID_COOKIE),
    });
  }

  // this method is to prevent events duplication that are fired on component mount (if some component is mounted more than one time)
  function trackOnce(event: string, payload?: Record<string, any>) {
    const stringifiedEvent = JSON.stringify({ event, payload: payload || {} });
    if (stringifiedLastTrackedEvent !== stringifiedEvent) {
      stringifiedLastTrackedEvent = stringifiedEvent;
      track(event, payload);
    }
  }

  function trackPosChanged(payload: AmplitudeAnalyticsPayload) {
    track('pos_changed', payload);
  }
  function trackFormBackClick() {
    track('back_was_used', {});
  }
  function trackLoanTermsHintClick() {
    track('loan_terms_hint_clicked', {});
  }

  function trackIncentiveProgramOpened() {
    trackOnce('incentive_program_screen_opened', {});
  }

  function setClientAnalyticsId(analyticsId?: string) {
    analyticsId
      ? setCookie({
          name: CLIENT_ANALYTICS_ID_COOKIE,
          value: analyticsId,
        })
      : deleteCookie(CLIENT_ANALYTICS_ID_COOKIE);
  }

  return {
    setUserId,
    track,
    trackOnce,
    setClientAnalyticsId,
    trackPosChanged,
    trackFormBackClick,
    trackLoanTermsHintClick,
    trackIncentiveProgramOpened,
  };
};

export const amplitudeAnalytics = getAnalytics();
