import { useState } from 'react';

import type { WorkDay } from '~entities/shedule/schedule';

import Clickable from '@breeze-platform-ui/clickable';
import { Int16ArrowDown } from '@breeze-platform-ui/iconsPack';
import Text from '@breeze-platform-ui/text';
import cn from 'classnames';

import styles from './shift-poses.module.css';

export const ShiftPoses: React.FC<Pick<WorkDay, 'posList'>> = ({ posList }) => {
  const [isOpened, setIsOpened] = useState(false);
  const isListOpened = isOpened || posList.length < 2;

  return (
    <div
      className={cn(styles.container, {
        [styles.opened]: isListOpened,
      })}
    >
      <Text size={15} overflowEllipsis={!isListOpened}>
        <span>{posList.map((pos) => pos.name).join(', ')}</span>
        {isOpened && (
          <Clickable
            className={styles.toggleButton}
            onClick={() => setIsOpened((opened) => !opened)}
          >
            <Int16ArrowDown
              theme="blue"
              cursor="pointer"
              rotate={isOpened ? 180 : 0}
            />
          </Clickable>
        )}
      </Text>
      {!isListOpened && (
        <Clickable
          className={styles.toggleButton}
          onClick={() => setIsOpened((opened) => !opened)}
        >
          <Int16ArrowDown
            theme="blue"
            cursor="pointer"
            rotate={isOpened ? 180 : 0}
          />
        </Clickable>
      )}
    </div>
  );
};
