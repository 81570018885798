import { useEffect, useMemo } from 'react';

import { useMachine } from '@xstate/react';

import { AuthorizationError } from './error';
import { ConfirmationState } from './otp-confirmation';
import { OtpSending } from './otp-sending/otp-sending';

import {
  getMaskedPhone,
  getOtpRequestDelaySeconds,
  setOtpRequestTime,
  type OTP_TYPE,
} from '../lib';
import { otpAuthStatesMachine } from '../model/otp-auth-states';

type Props = {
  phone: string;
  type: OTP_TYPE;
  isSuccessResult: (result: unknown) => boolean;
  isConfirmationError: (error: Error) => boolean;
  isAuthorizationError: (error: Error) => boolean;
  isAttemptsLimitError: (error: Error) => boolean;
  onSend: (resend?: boolean) => Promise<unknown>;
  onConfirm: (code: string) => Promise<void>;
  onResend: () => Promise<void>;
  onSuccess: () => void;
};

export function AuthByOtp(props: Props) {
  const {
    phone,
    type,
    isSuccessResult,
    isConfirmationError,
    isAuthorizationError,
    isAttemptsLimitError,
    onSend,
    onConfirm,
    onSuccess,
    onResend,
  } = props;
  const [state] = useMachine(otpAuthStatesMachine, {
    services: {
      initialize: () => {
        const delay = getOtpRequestDelaySeconds(type, phone);
        if (!delay) {
          return onSend().then((response) => {
            setOtpRequestTime(type, phone, new Date().getTime());
            return response;
          });
        }
        return Promise.resolve();
      },
    },
    guards: {
      isSuccessResult: ({ result }) => isSuccessResult(result),
    },
  });

  const maskedPhone = useMemo(() => getMaskedPhone(phone), [phone]);

  useEffect(() => {
    if (state.matches('success')) {
      onSuccess();
    }
  }, [onSuccess, state]);

  if (
    state.matches('initialization') ||
    state.matches('initialized') ||
    state.matches('success')
  ) {
    return <OtpSending phoneNumber={maskedPhone} />;
  }

  if (state.matches('confirmation')) {
    return (
      <ConfirmationState
        phoneNumber={phone}
        confirmationType={type}
        isConfirmationError={isConfirmationError}
        isAuthorizationError={isAuthorizationError}
        isAttemptsLimitError={isAttemptsLimitError}
        onCodeSubmit={onConfirm}
        onResend={onResend}
        onSuccess={onSuccess}
      />
    );
  }

  if (state.matches('error')) {
    return <AuthorizationError />;
  }

  return null;
}
