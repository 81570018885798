import { environment } from '~app/environment';

import {
  webRequestClient,
  type RequestParams,
  type ScpClient,
} from '~shared/scp-client';

import type { PersonApi } from './person-api';

import type { Person } from '../model';

type Options = { host: string };

export class GatewayPersonApi implements PersonApi {
  scpClient: ScpClient;
  host: string;

  constructor(scpClient: ScpClient, options: Options) {
    this.scpClient = scpClient;
    this.host = options.host;
  }

  getPerson() {
    return this.makeRequest<Person | undefined>({
      method: 'pos-loan/get',
    });
  }

  updatePerson(payload: Partial<Person>): Promise<Person> {
    return this.makeRequest<Person>({
      method: 'pos-loan/update',
      payload,
    });
  }

  private makeRequest<T>(params: Partial<RequestParams> & { method: string }) {
    return this.scpClient
      .call<T>({
        host: this.host,
        version: 'v2',
        domain: 'origination',
        service: 'person',
        withCredentials: true,
        withLeadingDomain: true,
        ...params,
      })
      .then((response) => response.payload);
  }
}

export const gatewayPersonApi = new GatewayPersonApi(webRequestClient, {
  host: environment.API_HOST,
});
