import { useState } from 'react';

import type { ApplicationProcessStatus } from '~entities/application';

import Button from '@breeze-platform-ui/button';
import Island from '@breeze-platform-ui/island';
import { Col } from '@breeze-platform-ui/layout';
import Sheet from '@breeze-platform-ui/sheet';
import type {
  RequireAtLeastOne,
  Modes,
  Mode,
} from '@breeze-platform-ui/sheet/types';
import Text from '@breeze-platform-ui/text';

import qrCodeImage from './qr-code.svg';

import { ProcessStatusTitle } from '../../process-status-title/process-status-title';

type Props = {
  processStatus: ApplicationProcessStatus;
};

const sheetModes: RequireAtLeastOne<Modes> = {
  normal: {
    minHeight: 408,
    maxHeight: 500,
  },
  collapsed: false,
  expanded: false,
};

export const MobileAppRequired = ({ processStatus }: Props) => {
  const [sheetOpened, setSheetOpened] = useState<boolean>(false);
  const [sheetMode, setSheetMode] = useState<Mode>('normal');

  return (
    <>
      <Island
        theme="shadow"
        text={
          <span>
            Make sure the&nbsp;customer signs&nbsp;up using the&nbsp;same mobile
            number as mentioned&nbsp;in&nbsp;the online form
          </span>
        }
        title={<ProcessStatusTitle processStatus={processStatus} />}
        footerBody={
          <Button wide size="m" onClick={() => setSheetOpened(true)}>
            Show the QR
          </Button>
        }
      />
      <Sheet
        mode={sheetMode}
        modes={sheetModes}
        opened={sheetOpened}
        onClose={() => setSheetOpened(false)}
        onChange={(_event, params) => setSheetMode(params.next)}
      >
        <Col
          alignCross="center"
          alignMain="center"
          gaps={24}
          margin="70px 36px"
        >
          <img
            src={qrCodeImage}
            height="230"
            width="230"
            alt="skyro app link"
          />
          <Text color="#0000008A" size="13_small">
            Show the customer this QR code to install the app
          </Text>
        </Col>
      </Sheet>
    </>
  );
};
