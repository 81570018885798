import { useState } from 'react';

import type { AgentIncentiveMonthHistory } from '~entities/agent-incentive';

import { phMoneyProps } from '~shared/constants/ph-money-props';
import { plural } from '~shared/lib/plural';

import Button from '@breeze-platform-ui/button';
import { Int16Close } from '@breeze-platform-ui/iconsPack';
import { Col, Row } from '@breeze-platform-ui/layout';
import Money from '@breeze-platform-ui/money';
import Sheet from '@breeze-platform-ui/sheet';
import type {
  Mode,
  Modes,
  RequireAtLeastOne,
} from '@breeze-platform-ui/sheet/types';
import Text from '@breeze-platform-ui/text';
import cn from 'classnames';
import { format } from 'date-fns';

import styles from './month-bonus-details.module.css';

import { getBonusHistoryDate } from '../../lib';
import { ApplicationsInfo } from '../applications-info/applications-info';

const sheetModes: RequireAtLeastOne<Modes> = {
  normal: {
    minHeight: 160,
  },
  collapsed: false,
  expanded: false,
};

export const MonthBonusDetails: React.FC<{
  history: AgentIncentiveMonthHistory;
  isAldiAgent?: boolean;
}> = ({ history, isAldiAgent = false }) => {
  const { date, totalApplicationsCount, totalAmount, applications } = history;
  const [sheetOpened, setSheetOpened] = useState<boolean>(false);
  const [sheetMode, setSheetMode] = useState<Mode>('normal');
  const currentMonth = format(new Date(date), 'LLLL');
  const suspicious = applications.find(
    (application) => application.type === 'SUSPICIOUS'
  );
  const nonSuspicious = applications.find(
    (application) => application.type === 'NON_SUSPICIOUS'
  );
  const hasApplications = !!suspicious?.count || !!nonSuspicious?.count;

  return (
    <>
      <Row
        alignCross="stretch"
        alignMain="between"
        padding="12px 0"
        onClick={() => {
          if (hasApplications) {
            setSheetOpened(true);
          }
        }}
        className={cn(styles.historyItem, {
          [styles.clickableItem]: hasApplications,
        })}
      >
        <Col alignCross="stretch">
          <Text size={15} capitalize>
            {getBonusHistoryDate(date)}
          </Text>
          <Text size={13} color="#0000008A">
            {totalApplicationsCount}&nbsp;
            {plural(totalApplicationsCount, 'loan', 'loans')}
          </Text>
        </Col>
        <Money {...phMoneyProps} value={totalAmount} />
      </Row>
      <Sheet
        mode={sheetMode}
        modes={sheetModes}
        opened={sheetOpened}
        onClose={() => setSheetOpened(false)}
        onChange={(_event, params) => setSheetMode(params.next)}
      >
        <Row
          alignCross="center"
          alignMain="between"
          padding="12px 0"
          margin="0 0 16px 0"
        >
          <h4 className={styles.header}>{currentMonth}&nbsp;bonus</h4>
          <Button
            icon={<Int16Close theme="gray" />}
            theme="secondaryLight"
            size="s"
            round
            onClick={() => setSheetOpened(false)}
          />
        </Row>
        <Col alignCross="stretch" gaps={24}>
          {nonSuspicious && (
            <ApplicationsInfo
              applicationInfo={nonSuspicious}
              applicationTypeText={!isAldiAgent ? 'non-suspicious' : undefined}
            />
          )}
          {suspicious && (
            <ApplicationsInfo
              applicationInfo={suspicious}
              applicationTypeText="suspicious"
            />
          )}
          <Row
            className={styles.totalApplicationsInfo}
            alignMain="between"
            padding="0 0 24px 0"
          >
            <span>Total bonus</span>
            <Money value={totalAmount} {...phMoneyProps} />
          </Row>
        </Col>
      </Sheet>
    </>
  );
};
