import { PopupCloseButton } from '~shared/ui/popup-close-button';
import { PopupTitle } from '~shared/ui/popup-title';

import Button from '@breeze-platform-ui/button';
import { Row, Col } from '@breeze-platform-ui/layout';
import Sheet from '@breeze-platform-ui/sheet';

type Props = {
  isOpened: boolean;
  onClose: () => void;
  onYesClick: () => void;
  onNoClick: () => void;
};
export const HasQRPopup = ({
  onClose,
  onYesClick,
  onNoClick,
  isOpened,
}: Props) => {
  return (
    <>
      <Sheet
        mode="normal"
        modes={{
          collapsed: false,
          expanded: false,
          normal: {
            minHeight: 180,
            maxHeight: 180,
          },
        }}
        opened={isOpened}
        onChange={() => {
          // do nothing
        }}
        onClose={onClose}
      >
        <Col
          gaps={28}
          alignCross="stretch"
          dataQaType="close-confirmation-popup"
        >
          <Row alignMain="between" alignCross="start">
            <PopupTitle
              title="Does the&nbsp;customer have a&nbsp;QR&nbsp;code for
            a&nbsp;pre&#8209;approved&nbsp;loan?"
            />
            <PopupCloseButton onClick={() => onClose()} />
          </Row>
          <Row alignMain="between" gaps={12}>
            <Button size="m" wide theme="secondary" onClick={onNoClick}>
              No
            </Button>
            <Button size="m" wide theme="primary" onClick={onYesClick}>
              Yes
            </Button>
          </Row>
        </Col>
      </Sheet>
    </>
  );
};
