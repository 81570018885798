import type { EmploymentStatusValue } from './employment-status-options';

export function isEmployeeFn(status: EmploymentStatusValue): boolean {
  return status === 'FULL_TIME' || status === 'PART_TIME';
}

export function hasOwnBusinessFn(status: EmploymentStatusValue): boolean {
  return status === 'OWN_BUSINESS';
}

export function isSelfEmployedFn(status: EmploymentStatusValue): boolean {
  return ['DOMESTIC_WORKER', 'CONTRACTUAL_FREELANCER'].includes(status);
}
