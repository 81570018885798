import { useQuery } from '@tanstack/react-query';

import { gatewayAgentApi } from './gateway-agent-api';

import type { AgentInfo } from '../agent';

export function useAgentInfo<TSelect = AgentInfo>(options?: {
  enabled?: boolean;
  select?: (info: AgentInfo) => TSelect;
}) {
  return useQuery({
    queryKey: ['agent-info'],
    queryFn: async () => gatewayAgentApi.getAgentInfo(),
    cacheTime: 60 * 24,
    ...options,
  });
}
